import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Checkbox} from '@material-ui/core';
import {Columns, Button, Section} from 'react-bulma-components';
import styles from '../styles';
import translation from '../translations';

@observer
class Section8 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.submitOrder = props.parent.submitOrder.bind(this);
    }

    render() {
        const {loading, adminAccess, selected, form, language} = this.localstate;
        const tl = translation[language.value];
        const selectedQuestionnaire = selected.questionnaire?.DynamicsSupplier;

        return (
            <Section style={styles.section}>
                { !adminAccess && <h1 style={styles.headline}>8. {tl.section8.headline}</h1> }
                <Columns>
                    <Columns.Column>
                        {
                        !adminAccess &&
                            <div style={styles.switchBox}>
                                <Checkbox
                                    disabled={loading}
                                    checked={form.confirmed}
                                    onChange={e => form.confirmed = e.target.checked}
                                />
                                <h2 style={styles.switchBoxText}>{tl.section8.checkboxText}</h2>
                            </div>
                        }
                        <div style={styles.row}>
                            {
                            (!adminAccess || (adminAccess && selected.questionnaire)) &&
                                <Button
                                    fullwidth={true}
                                    color='primary'
                                    loading={loading}
                                    disabled={!adminAccess && !form.confirmed}
                                    onClick={() => this.submitOrder()}
                                >
                                    { selectedQuestionnaire ? `${tl.section8.buttonTextUpdate}` : `${tl.section8.buttonText}` }
                                </Button>
                            }
                            {
                            adminAccess &&
                                <Button
                                    fullwidth={true}
                                    color='info'
                                    loading={loading}
                                    disabled={!adminAccess && !form.confirmed}
                                    onClick={() => this.submitOrder('creation')}
                                >
                                    {tl.section8.buttonTextAdminCreation}
                                </Button>
                            }
                        </div>
                    </Columns.Column>
                </Columns>
            </Section>
        );
    }
}

export default Section8;