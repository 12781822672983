import React, {Component}  from "react";
import FetchView from "../../dumb/FetchView/FetchView";
import RestClient from "../../../shared/Network/RestClient";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {
    Container,
    Button,
} from "react-bulma-components";

@observer
class LotteryRegisterScreen extends Component {
    @observable accessor localstate =  {
        error: false,
        loading: true,
        success: false,
        storefrontUrl: "https://www.korodrogerie.de/"
    }
    async register() {
        this.localstate.error = false;
        this.localstate.loading = true;
        const request = RestClient.prepareRequest('POST', 'lottery/register');
        const query = new URLSearchParams(this.props.location.search);
        this.localstate.storefrontUrl = query.get('storefrontUrl');
        request.setData({
            name: query.get('name'),
            email: query.get('email'),
            newsletterconsent: query.get('newsletterconsent'),
            lotteryconsent: query.get('lotteryconsent'),
            origin: query.get('origin'),
            answer: query.get('answer'),
            salesChannelId: query.get('salesChannelId'),
            languageId: query.get('languageId'),
            storefrontUrl: query.get('storefrontUrl')
        });
        try {
            const response = await request.send();
            if (response.success && response.data) {
                this.localstate.success = true;
            } else {
                this.localstate.error = response.error || "Es ist ein Fehler aufgetreten!";
            }
            this.localstate.loading = false;
        } catch (e) {
            this.localstate.error = "Es ist ein Fehler aufgetreten!";
            this.localstate.loading = false;
        }
    }

    render() {
        const {error, loading, success, storefrontUrl} = this.localstate;

        return (<Container>
            <div style={styles.container}>
                <FetchView call={this.register.bind(this)}>
                <div style={{textAlign: "center"}}><img style={{paddingBottom: 50}}  src="https://koro.imgix.net/media/66/f7/5b/1662581399/koro-logoPXS2yC9zKwHVo.svg?w=3000&auto=format,compress&fit=max" />
                    {error && <div><p>{error}</p></div>}
                    {!loading && !error && success && <p>Das hat geklappt. Du bist nun im Lostopf!</p>}
                </div>
                <a style={{paddingTop: "40px"}}href={storefrontUrl}><Button>Zurück zum Shop</Button></a>
                </FetchView>
            </div>
        </Container>)
    }
}

const styles = {
    container :{
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        padding: '100px'
    },
    image: {
        height: '200px'
    }
}

export default LotteryRegisterScreen;