import React, { Component } from "react";
import {
  Container,
  Columns,
  Heading,
} from "react-bulma-components";

class ChallengeBody extends Component {
  render() {
    return (
      <Container>
        <Columns>
          <Columns.Column>
            <Heading>Deine Aufgabe</Heading>
            <p>{this.props.description}</p>
          </Columns.Column>
          <Columns.Column>{this.props.children}</Columns.Column>
        </Columns>
      </Container>
    );
  }
}

export default ChallengeBody;
