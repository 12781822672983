import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Columns, Section, Form} from 'react-bulma-components';
import styles from '../styles';
import translation from '../translations';
const {Input, Label} = Form;

@observer
class Section6 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.handleInput = props.parent.handleInput.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {loading, form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>6. {tl.section6.headline}</h1>
                <div style={styles.note}>{tl.section6.headNote}</div>
                <Columns>
                    <Columns.Column>
                        <div style={styles.inputBox}>
                            <Label>{tl.section6.singleItem}</Label>
                            <Input
                                className={this.error(form.palette.singleItem)}
                                type='number'
                                placeholder='0'
                                disabled={loading}
                                value={form.palette.singleItem}
                                onChange={e => this.handleInput(form.palette, 'singleItem', e)}
                            />
                        </div>
                    </Columns.Column>
                    <Columns.Column>
                        <div style={styles.inputBox}>
                            <Label>{tl.section6.trayPerBox}</Label>
                            <Input
                                type='number'
                                placeholder='0'
                                disabled={loading}
                                value={form.palette.trayPerBox}
                                onChange={e => this.handleInput(form.palette, 'trayPerBox', e)}
                            />
                        </div>
                    </Columns.Column>
                    <Columns.Column>
                        <div style={styles.inputBox}>
                            <Label>{tl.section6.boxPerPalette}</Label>
                            <Input
                                className={this.error(form.palette.boxPerPalette)}
                                type='number'
                                placeholder='0'
                                disabled={loading}
                                value={form.palette.boxPerPalette}
                                onChange={e => this.handleInput(form.palette, 'boxPerPalette', e)}
                            />
                        </div>
                    </Columns.Column>
                </Columns>
            </Section>
        );
    }
}

export default Section6;