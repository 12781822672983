import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../../NavigationBar/NavigationBar";
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Container, Modal, Heading, Form} from "react-bulma-components";
import FetchView from "../../../dumb/FetchView/FetchView";
import RestClient from "../../../../shared/Network/RestClient";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PurchaseOrderStore} from '../../../../stores/Bottler/PurchaseOrderStore';
import moment from 'moment';
import {toast} from "react-toastify";
const {Control, Field, Input, Label} = Form;

@observer
class PurchaseOrders extends Component {
    @observable accessor localstate = {
        details: false,
        receivedBatchItem: false,
        confirm: false,
        loading: false,
        formData: {
            qty: '',
            lotNo: '',
            expirationDate: ''
        }
    }

    onDetails(item) {
        this.localstate.details = item;
    }

    onDetailsClose() {
        this.localstate.details = false;
    }

    onReceivedBatch(item, order) {
        this.localstate.receivedBatchItem = { item, order };
    }

    onReceivedBatchClose() {
        this.localstate.receivedBatchItem = false;
    }

    onConfirmClose() {
        this.localstate.confirm = false;
    }

    onFormChange(key, value) {
        this.localstate.formData[key] = value.replace(/\s{2,}/g,' ');
    }

    async receivedBatchSubmit(data) {
        const {item, order} = data;
        const form = this.localstate.formData;
        const {qty, lotNo, expirationDate} = form;

        if (lotNo.length == 0) { toast.error('Please enter a lot number.'); return }
        if (qty.length == 0 || qty.match(/\D/)) { toast.error('Please enter a valid quantity.'); return }
        if (!expirationDate.match(/^\d{4}\-\d{2}\-\d{2}$/)) { toast.error('Please enter a valid expiration date. (YYYY-MM-DD)'); return }
        if (!moment(expirationDate).isValid()) { toast.error('Please enter a valid expiration date. (YYYY-MM-DD)'); return }

        this.localstate.loading = true;

        const requestBody = {
            itemNo: item.lineObjectNumber,
            lineNo: item.sequence,
            qty: typeof qty === 'string' ? Number(qty) : qty,
            lotNo,
            expirationDate,
            location: item.location.code
        }

        const request = RestClient.prepareRequest('POST', `purchase/addreservationentry/${order.number}`);
        request.setData(requestBody);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                toast.success('Received batch successfully added');
                this.localstate.loading = false;
                this.localstate.receivedBatchItem = false;
                this.localstate.formData = {
                    qty: '',
                    lotNo: '',
                    expirationDate: ''
                }
                this.localstate.confirm = data;
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    async receivePurchaseOrder(order) {
        this.localstate.loading = true;

        const request = RestClient.prepareRequest('POST', `purchase/receive/${order.id}`);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                toast.success('Purchase order received successfully');
                this.localstate.loading = false;
                this.localstate.receivedBatchItem = false;
                this.localstate.confirm = false;
                this.localstate.formData = {
                    qty: '',
                    lotNo: '',
                    expirationDate: ''
                }
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    confirmSubmit(completeBatch, data) {
        if (completeBatch) {
            this.receivePurchaseOrder(data.order);
        } else {
            this.localstate.confirm = false;
            this.localstate.receivedBatchItem = data;
        }
    }

    render() {
        const {details, receivedBatchItem, confirm, formData, loading} = this.localstate;
        return  (<div>
            <NavigationBar title='My purchase orders'></NavigationBar>

            <Container style={styles.container}>
                <FetchView store={PurchaseOrderStore}>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader className='table-hover'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Number</TableCell>
                                <TableCell colSpan={2}>Purchaser</TableCell>
                                <TableCell colSpan={3}>Vendor</TableCell>
                                <TableCell colSpan={2}>Ship to</TableCell>
                                <TableCell colSpan={2}>Status</TableCell>
                                <TableCell colSpan={2}>Order Date</TableCell>
                                <TableCell colSpan={2}>Last modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            PurchaseOrderStore.list && PurchaseOrderStore.list.filter(x => x.purchaseOrderLines.filter(y => y.receiveQuantity > 0).length > 0).map((item, i) => {
                                return (
                                <TableRow key={i} style={styles.tableRow} onClick={() => this.onDetails(item)}>
                                    <TableCell colSpan={2}>{item.number}</TableCell>
                                    <TableCell colSpan={2}>{item.purchaser}</TableCell>
                                    <TableCell colSpan={3}>{item.vendorName}</TableCell>
                                    <TableCell colSpan={2}>{item.shipToName}</TableCell>
                                    <TableCell colSpan={2}>{item.status}</TableCell>
                                    <TableCell colSpan={2}>{moment(item.orderDate).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell colSpan={2}>{moment(item.lastModifiedDateTime).format('DD/MM/YYYY LT')}</TableCell>
                                </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </FetchView>
            </Container>

            <Modal show={details} onClose={this.onDetailsClose.bind(this)}>
                <Container style={styles.overlay}>
                <NavigationBar title={details ? 'Details for ' + details.number : 'Details'} style={styles.overlay}></NavigationBar>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader className='table-hover'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Number</TableCell>
                                <TableCell colSpan={4}>Name</TableCell>
                                <TableCell colSpan={2}>Quantity to be received</TableCell>
                                <TableCell colSpan={2}>Quantity received</TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            details && details.purchaseOrderLines && details.purchaseOrderLines.map((item, i) => {
                                let qtyUnit = typeof item.qtyPerUnitOfMeasure === 'string' ? Number(item.qtyPerUnitOfMeasure) : item.qtyPerUnitOfMeasure;
                                if (qtyUnit <= 0) qtyUnit = 1;
                                return (
                                <TableRow key={i}>
                                    <TableCell colSpan={2}>{item.lineObjectNumber}</TableCell>
                                    <TableCell colSpan={4}>{item.description}</TableCell>
                                    <TableCell colSpan={2}>{item.receiveQuantity * qtyUnit}</TableCell>
                                    <TableCell colSpan={2}>{item.receivedQuantity * qtyUnit}</TableCell>
                                    <TableCell colSpan={2}>
                                        <Button onClick={() => this.onReceivedBatch(item, details)}>Add received batch</Button> 
                                    </TableCell>
                                </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </Container>
            </Modal>

            <Modal show={receivedBatchItem ? true : false} onClose={this.onReceivedBatchClose.bind(this)}>
                <Container style={styles.formContainer}>
                    <Heading style={styles.formHeading}>Received batch</Heading>
                    <p style={styles.formParagraph}>{receivedBatchItem.item?.description}</p>
                    {PurchaseOrderStore && PurchaseOrderStore.structure.map((x, i) => {
                        return (<div key={i}>
                            <Field style={styles.field}>
                                <Control>
                                    <Label>{x.label} {x.key === 'expirationDate' ? '(YYYY-MM-DD)' : ''}</Label>
                                    <Input type="text" placeholder={x.key === 'expirationDate' ? moment().format('YYYY-MM-DD') : x.label} value={formData[x.key]} onChange={(e) => this.onFormChange(x.key, e.target.value)}/>
                                </Control>
                            </Field>
                        </div>);
                    })}
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.receivedBatchSubmit(receivedBatchItem)}>Add received batch</Button>
                </Container>
            </Modal>

            <Modal show={confirm} onClose={this.onConfirmClose.bind(this)}>
                <Container style={styles.formContainer}>
                    <Heading style={styles.formHeading}>Please confirm</Heading>
                    <p style={styles.formParagraph}>Do you want to add another received batch?</p>
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.confirmSubmit(false, confirm)}>Add another batch</Button>
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.confirmSubmit(true, confirm)}>Complete this purchase order</Button>
                </Container>
            </Modal>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
        padding: 20
    },
    formContainer: {
        borderRadius: 5,
        padding: 20,
        background: 'white',
        minWidth: '400px',
        flexGrow: 0
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    },
    field: {
      marginBottom: '10px'
    },
    tableRow: {
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'red'
        }
    },
    overlay: {
        backgroundColor: 'white'
    },
    button: {
        marginTop: 15
    },
    formHeading: {
        marginBottom: 10
    },
    formParagraph: {
        marginBottom: 20
    }
}

export default PurchaseOrders;