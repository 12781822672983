import RestClient from "./Network/RestClient";

class FileDownload {
  downloadFile(file) {
    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);

    const el = document.createElement("a");
    el.setAttribute("download", file.name);
    el.setAttribute("href", url);
    el.click();

    URL.revokeObjectURL(url);
  }

  async parseFileDownloadResponse(request) {
    const { headers, data } = await RestClient.sendRequest(request, {
      responseType: "arraybuffer",
      getResponseObject: true,
    });

    const filename = headers["content-type"].match(/filename=([\s\S]+)/)[1];

    return { data, filename };
  }
}

export default new FileDownload();
