import React, {Component, createRef} from 'react';
import {Box, Button, Icon, Heading, Form} from 'react-bulma-components';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import Authentication from '../../../shared/Authentication';
import {PageStructureNotLoggedIn} from '../../dumb';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FaEnvelope, FaKey} from 'react-icons/fa';
const {Control, Field, Label} = Form;

@observer
class Login extends Component {
    constructor() {
        super();
        this.emailinput = createRef();
        this.passwordinput = createRef();
    }

    @observable accessor localstate = {
        loading: false,
        email: '',
        password: '',
    };

    async login() {
        const {email, password} = this.localstate;

        if (!email) { toast.error('Please enter your email address'); this.emailinput.current?.focus(); return; }
        if (!email.match(/^\S+@\S+\.\S+$/)) { toast.error('Your email address is invalid'); this.emailinput.current?.focus(); return; }
        if (!password) { toast.error('Please enter your password'); this.passwordinput.current?.focus(); return; }

        try {
            this.localstate.loading = true;
            const response = await Authentication.login(email, password);
    
            if (!response.success) throw response.error?.message || response.error || response.message || JSON.stringify(response) || 'An error occurred';

            this.localstate.loading = false;
        } catch (error) {
            console.error(error);
            toast.error(error);
            this.localstate.loading = false;
        }
    }

    onPasswordForgotten() {
        const {email} = this.localstate;

        localStorage.setItem('passwordRecoveryEmail', email);
        this.props.history.push('/passwordforgotten');
    }

    componentDidMount() {
        this.emailinput.current?.focus();
    }

    render() {
        const {loading, email, password} = this.localstate;

        return (
            <PageStructureNotLoggedIn>
                <Heading>KoRo Helper Login</Heading>
                <Box>
                    <Field>
                        <Label>Email address</Label>
                        <Control>
                            <input
                                ref={this.emailinput}
                                className='input'
                                type='email'
                                value={email}
                                placeholder='name@company.de'
                                disabled={loading}
                                onChange={(e) => this.localstate.email = e.target.value}
                                onKeyDown={(e) => e.key === 'Enter' && this.login()}
                            />
                            <Icon align='left'>
                                <FaEnvelope color='#363636'>Logout</FaEnvelope>
                            </Icon>
                        </Control>
                    </Field>
                    <Field>
                        <Label>Password</Label>
                        <Control>
                            <input
                                ref={this.passwordinput}
                                className='input'
                                type='password'
                                value={password}
                                placeholder='***********'
                                disabled={loading}
                                onChange={(e) => this.localstate.password = e.target.value}
                                onKeyDown={(e) => e.key === 'Enter' && this.login()}
                            />
                            <Icon align='left'>
                                <FaKey color='#363636'>Logout</FaKey>
                            </Icon>
                        </Control>
                    </Field>
                    <Button
                        color='primary'
                        fullwidth={true}
                        loading={loading}
                        style={{ marginBottom: 10 }}
                        onClick={() => this.login()}
                    >
                        Login
                    </Button>
                    <a onClick={() => this.onPasswordForgotten()}>Forgot password?</a>
                </Box>
            </PageStructureNotLoggedIn>
        );
    }
}

export default withRouter(Login);