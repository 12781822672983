import React, {Component} from 'react';
import Navigation from "../../../shared/Navigation";
import {TabScreen} from "../../dumb";
import B2BOrderList from "./B2BOrderList";
import B2BOrderCreation from "./B2BOrderCreation";

const BASE_URL = '/b2bOrders';

const subpages = [{
    "label": "Create New Order",
    "url": "/",
    "component": <B2BOrderCreation/>
},{
    "label": "B2B Orders",
    "url": "/list",
    "component": <B2BOrderList/>
}];

class B2BOrders extends Component {
    render() {
       return <TabScreen subpages={subpages} BASE_URL={BASE_URL} Navigation={Navigation}></TabScreen>
    }
}

export default B2BOrders;
