import React, {Component} from 'react';
import FetchView from "../../dumb/FetchView/FetchView";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Modal, Button, Container} from 'react-bulma-components'
import NavigationBar from "../NavigationBar/NavigationBar";
import {toast} from "react-toastify";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {UserStore} from "../../../stores/UserStore";
import Authentication from "../../../shared/Authentication";
import UserEdit from "../GenericForm/GenericForm";

// default imports until here

@observer
class UserManagement extends Component {
    @observable accessor localstate = {
        edit: null,
    };

    async deleteUser(item) {
        try {
            const reply = window.confirm('Do you really want to delete this user?');
            if (reply) {
                await UserStore.deleteItem(item);
                toast.success('The user has been deleted successfully');
            }
        } catch (e) {
            toast.error(e);
        }
    }

    async editUser(item) {
        this.localstate.edit = item;
    }

    onSave() {
        this.localstate.edit = null;
    }

    render() {
        const {edit} = this.localstate;
        return (<>
            <NavigationBar title='Usermanagement'>
                <Button color='primary' onClick={this.editUser.bind(this)}>Add User</Button>
            </NavigationBar>
            <Container style={styles.container}>
                <FetchView store={UserStore}>
                    <Table aria-label="simple table" style={{overflow:'scroll'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Mail</TableCell>
                                <TableCell>Groups</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            UserStore.list.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell>{item.firstName}</TableCell>
                                    <TableCell>{item.lastName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                        {item.isAdmin && <TableCell>ADMIN / EVERYTHING</TableCell>}
                                        {!item.isAdmin && <TableCell>{item.usergroups && item.usergroups.join(',')}</TableCell>}
                                    <TableCell>
                                        <Button onClick={() => this.editUser(item)}>Edit</Button>
                                        {item.id !== Authentication.user.id && <Button onClick={() => this.deleteUser(item)}>Del</Button>}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </FetchView>
            </Container>
            <Modal show={edit ? true : false} onClose={this.onSave.bind(this)}>
                <UserEdit store={UserStore} id={edit?.id} onSave={this.onSave.bind(this)}/>
            </Modal>
        </>);
    }
}

const styles = {
    container: {
        padding: 20,
        overflowX: 'scroll',
    }
}

export default UserManagement;