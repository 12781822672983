import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import FetchView from '../../dumb/FetchView/FetchView';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Button, Form} from 'react-bulma-components';
import NavigationBar from '../NavigationBar/NavigationBar';
import {Container, Columns} from 'react-bulma-components';
import Select from 'react-select';
import {toast} from 'react-toastify';
import RestClient from 'shared/Network/RestClient';
import B2BStore from 'stores/B2BOrders/B2BStore';
import B2BOrderStore from 'stores/B2BOrders/B2BOrderStore';
const {Input, Label} = Form;

@observer
class B2BOrderCreation extends Component {
    @observable accessor localstate = {
        allowedPaymentMethods: ['ERSATZ', 'B2B-RECH', 'RETAIL', 'RETAILMUST'],
        loading: false,
        total: 0,
        discountTotal: 0,
        options: {
            skus: [],
            shippingAddresses: [],
            discountTypes: [
                { label: 'Percentage', value: 'percentage' },
                { label: 'Absolute', value: 'absolute' },
            ],
        },
        form: {
            referenceNumber: '',
            internalNote: '',
            shippingCosts: 0,
            discountValue: 0,
            customer: null,
            shippingAddress: null,
            shippingAgent: null,
            paymentMethod: null,
            location: null,
            documentSourceType: null,
            discountType: null,
            skus: [
                {
                    sku: null,
                    quantity: 1,
                    discount: 0,
                },
            ],
        },
    };

    @observable accessor priceFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    constructor() {
        super();
        this.defaultForm = JSON.parse(JSON.stringify(this.localstate.form));
    }

    updateItem(index, key, value) {
        const {form} = this.localstate;

        if (key === 'quantity' && value.match(/\d/)) {
            if (Number(value) <= 0) value = 1;
        }

        if (key === 'discount' && value.match(/\d/)) {
            if (Number(value) > 100) value = 100;
            if (Number(value) < 0) value = 0;
        }

        this.localstate.form.skus[index][key] = value;

        if (form.skus.length === index + 1) {
            this.localstate.form.skus.push({ sku: null, quantity: 1, discount: 0 });
        }
    }

    removeItem(index) {
        this.localstate.form.skus.splice(index, 1);
    }

    calculateTotal() {
        const {form} = this.localstate;
        let total = 0;
        let discount = 0;

        for (const item of form.skus) {
            if (!item.sku) continue;
            const sum = item.quantity * Number(item.sku?.price);
            total += sum - ((sum * item.discount) / 100);
        }

        total = Number(total) + Number(form.shippingCosts);

        if (Number(form.discountValue) > 0 && form.discountType) {
            if (form.discountType.value === 'percentage') {
                const percentageAmount = (total * Number(form.discountValue)) / 100;
                discount = Math.round(percentageAmount * 100) / 100;
            } else {
                discount = Math.round(Number(form.discountValue) * 100) / 100;
            }
        }

        this.localstate.total = Math.round((total - discount) * 100) / 100;
        this.localstate.discountTotal = discount;
    }
    
    reset() {
        this.localstate.options.skus = [];
        this.localstate.options.shippingAddresses = [];
        this.localstate.form = this.defaultForm;
        this.localstate.loading = false;
    }

    async selectCustomer(selected = null) {
        this.reset();

        try {
            this.localstate.loading = true;
            if (!selected?.value) throw 'No customer selected';

            const customer = selected?.value;
            if (!customer) throw 'The selected customer could not be found';

            const shippingAddresses = await B2BStore.fetchRoute(`b2b/shippingaddresses/${customer?.customer_number}`);

            const priceRoute = customer.customer_price_group !== '' ? `${customer.customer_price_group}?type=group` : customer.customer_number;
            const prices = await B2BStore.fetchRoute(`b2b/prices/${priceRoute}`);

            this.priceFormatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: prices?.length > 0 ? prices[0]?.currencyCode || 'EUR' : 'EUR',
            });
            
            this.localstate.options.shippingAddresses = shippingAddresses;
            this.localstate.options.skus = prices;
            this.localstate.form.customer = selected;
            this.localstate.loading = false;
            this.localstate.form.shippingAddress =
                shippingAddresses?.length > 0
                    ? { label: this.formatAddressLabel(shippingAddresses[0]), value: shippingAddresses[0] }
                    : null;
        } catch (error) {
            this.localstate.loading = false;
            this.localstate.form.customer = null;
            toast.warning(error);
        }
    }

    formatSelect(data = [], keys = []) {
        if (!data || !keys || keys?.length === 0) return [];

        return data.map(item => (
            {
                label: keys[0] === 'ADDRESS'
                    ? this.formatAddressLabel(item)
                    : keys.map(key => item[key]).join(' - ').replace(/(\s\-\s)+$/g, ''),
                value: item,
            }
        ));
    }

    formatAddressLabel(address) {
        return (address?.address && address?.postCode && address?.city)
            ? `${address.address}, ${address.postCode} ${address.city} ${address?.code ? `(Code: ${address?.code})` : ''}`
            : 'No address set';
    }

    async submitOrder() {
        const {form, options, discountTotal, total} = this.localstate;
        const customer = form.customer?.value;
        const shippingAddress = form.shippingAddress?.value;
        let billingAddress = options.shippingAddresses.find(x => x.isBillingAddress);

        try {
            if (!customer) throw `The selected customer could not be found`;
            if (!shippingAddress) throw 'Please select a shipping address';
            if (!billingAddress) throw 'No billing address could be found for the selected customer';
            if (!form.paymentMethod) throw 'Please select a payment method';
            if (!form.shippingAgent) throw 'Please select a shipping agent';
            if (!form.documentSourceType) throw 'Please select a document source type';
            if (!form.referenceNumber) throw 'Please type a reference number';
            if (!form.location) throw 'Please select a warehouse location';
            
            if (Number(form.discountValue) > 0) {
                if (!form.discountType) throw 'Please select a discount type or enter no invoice discount';
                if (form.discountType.value === 'percentage') {
                    if (Number(form.discountValue) < 0) throw 'Please enter a discount amount above zero';
                    if (Number(form.discountValue) > 100) throw 'The discount amount can not be greater than 100';
                }
                if (form.discountType.value === 'absolute') {
                    if (total < 0) throw 'Please enter a discount amount less than total';
                }
            }
        } catch (message) {
            toast.warning(message);
            return;
        }

        const orderLines = [];

        for (const item of form.skus) {
            if (!item.sku) continue;

            orderLines.push({
                lineObjectNumber: item.sku.sku,
                lineType: 'Item',
                quantity: Number(item.quantity) || 1,
                unitPrice: Number(item.sku?.price),
                discountPercent: Number(item.discount) || 0,
                locationId: form.location.value.id || '',
            });
        }

        if (orderLines.length <= 0) {
            toast.warning('Please select at least 1 item');
            return;
        }

        if (customer.billToCustomer) {
            try {
                const addressData = await B2BStore.fetchRoute(`b2b/shippingaddresses/${customer.billToCustomer}`);

                if (!addressData || addressData?.length === 0) {
                    console.error(`Address data for ${customer.billToCustomer}:`, addressData);
                    throw `There are no address details for ${customer.billToCustomer}! The order could not be submitted`;
                }

                billingAddress = addressData.find(x => x.isBillingAddress);
    
                if (!billingAddress) {
                    console.error(`Address data for ${customer.billToCustomer}:`, addressData);
                    throw `Customer ${customer.billToCustomer} has no billing address! The order could not be submitted`;
                }
            } catch (error) {
                console.error(error);
                toast.error(error);
                return;
            }
        }
        
        const requestBody = {
            customerNumber: customer.customer_number.toString(),
            referenceNumber: form.referenceNumber,
            shippingAgent: form.shippingAgent.value.code,
            paymentMethodCode: form.paymentMethod.value.code,
            documentSourceCode: form.documentSourceType.value.code,
            internalNote: form.internalNote,
            location: form.location.value.code,
            locationId: form.location.value.id,
            shippingCosts: Number(form.shippingCosts) || 0,
            salesOrderLines: orderLines,

            shipToName: shippingAddress.name,
            shipToContact: shippingAddress.contact || '',
            shipToAddressLine1: shippingAddress.address,
            shipToAddressLine2: shippingAddress.address2,
            shipToStreet: shippingAddress.street,
            shipToHouseNo: shippingAddress.houseNo,
            shipToCity: shippingAddress.city,
            shipToCountry: shippingAddress.country,
            shipToPostCode: shippingAddress.postCode,
            shipToCode: shippingAddress.code,

            sellToAddressLine1: billingAddress.address,
            sellToAddressLine2: billingAddress.address2,
            sellToStreet: billingAddress.street,
            sellToHouseNo: billingAddress.houseNo,
            sellToCity: billingAddress.city,
            sellToCountry: billingAddress.country,
            sellToPostCode: billingAddress.postCode,

            billToName: billingAddress.name,
        };

        if (discountTotal > 0) requestBody['discountAmount'] = discountTotal;

        this.localstate.loading = true;

        const request = RestClient.prepareRequest('POST', 'b2b/salesorder');
        request.setData(requestBody);

        try {
            const res = await request.send();

            if (res.success) {
                if (res.data.b2bOrderEntry) B2BOrderStore.addItem(res.data.b2bOrderEntry);
                toast.success('Order created successfully');
                this.reset();
            } else {
                throw res.error?.message || res.error || res.message || JSON.stringify(res) || 'An error occurred';
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
        }

        this.localstate.loading = false;
    }

    componentDidUpdate() {
        this.calculateTotal();
    }

    getFilteredPaymentMethods () {
        const paymentMethods = B2BStore.list.paymentMethods;
        let filteredPaymentmethods = [];
        if(paymentMethods) {
            filteredPaymentmethods = paymentMethods.filter(x => this.localstate.allowedPaymentMethods.includes(x.code));
        }
        return filteredPaymentmethods;
    }

    render() {
        const {priceFormatter} = this;
        const {form, options, loading, total, discountTotal} = this.localstate;
        const note = form.customer?.value?.notes || '';

        return (<>
            <NavigationBar title='Create B2B Order'></NavigationBar>
            <Container style={{ marginTop: 20, marginBottom: 20 }}>
                <FetchView store={B2BStore}>
                    <div style={styles.info}>Please note that the shipping agent must be set manually and the release of an order must also be done manually!</div>

                    <Columns style={styles.section}>
                        <Columns.Column>
                            <Label>Customer</Label>
                            <Select
                                placeholder='Customer'
                                isDisabled={loading}
                                options={this.formatSelect(B2BStore.list.customers, ['customer_number', 'customer'])}
                                value={form.customer}
                                onChange={selected => this.selectCustomer(selected)}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Shipping Address</Label>
                            <Select
                                placeholder='Shipping Address'
                                isDisabled={loading || !form.customer}
                                options={this.formatSelect(options.shippingAddresses, ['ADDRESS'])}
                                value={form.shippingAddress}
                                onChange={selected => form.shippingAddress = selected}
                            />
                        </Columns.Column>
                    </Columns>
                    {
                    note &&
                        <Columns style={styles.section}>
                            <Columns.Column><div style={styles.note}>{note}</div></Columns.Column>
                        </Columns>
                    }
                    <Table>
                        <TableHead>
                            <TableRow key='0' style={styles.table.row}>
                                <TableCell style={{...styles.table.cell, width: '25%'}}>SKU</TableCell>
                                <TableCell style={{...styles.table.cell, width: '31%'}}>Name</TableCell>
                                <TableCell style={{...styles.table.cell, width: '8%'}}>Quantity</TableCell>
                                <TableCell style={{...styles.table.cell, width: '10%'}}>Stock</TableCell>
                                <TableCell style={{...styles.table.cell, width: '10%'}}>Total</TableCell>
                                <TableCell style={{...styles.table.cell, width: '8%'}}>Discount %</TableCell>
                                <TableCell style={{...styles.table.cell, width: '8%'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            form.skus.map((item, i) => (
                                <TableRow key={i} style={styles.table.row}>
                                    <TableCell style={{...styles.table.cell, width: '25%'}}>
                                        <Select
                                            placeholder='SKU'
                                            isDisabled={loading || !form.customer}
                                            options={this.formatSelect(options.skus, ['sku', 'ean', 'name'])}
                                            value={item.sku ? { label: item.sku.sku, value: item } : null}
                                            onChange={e => this.updateItem(i, 'sku', e.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{...styles.table.cell, width: '31%'}}>{item.sku?.name}</TableCell>
                                    <TableCell style={{...styles.table.cell, width: '8%'}}>
                                    {
                                    item.sku &&
                                        <Input
                                            type='number'
                                            placeholder='Anzahl'
                                            disabled={!form.customer}
                                            value={item.quantity}
                                            onChange={e => this.updateItem(i, 'quantity', e.target.value)}
                                        />
                                    }
                                    </TableCell>
                                    <TableCell style={{...styles.table.cell, width: '10%'}}>{item.sku?.available_stock || '/'}</TableCell>
                                    <TableCell style={{...styles.table.cell, width: '10%'}}>
                                    {
                                    item.sku &&
                                        priceFormatter.format(
                                            (Number(item.sku?.price) * item.quantity) - ((Number(item.sku?.price) * item.quantity * item.discount) / 100)
                                        )
                                    }
                                    </TableCell>
                                    <TableCell style={{...styles.table.cell, width: '8%'}}>
                                    {
                                    item.sku &&
                                        <Input
                                            disabled={!form.customer}
                                            type='number'
                                            placeholder='Discount'
                                            value={item.discount}
                                            onChange={e => this.updateItem(i, 'discount', e.target.value)}
                                        />
                                    }
                                    </TableCell>
                                    <TableCell style={{...styles.table.cell, width: '8%'}}>
                                        {item.sku && <Button onClick={() => this.removeItem(i)}>Remove</Button>}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                    <Columns style={styles.sectionTop}>
                        <Columns.Column>
                            <Label>Payment Method</Label>
                            <Select
                                placeholder='Payment Method'
                                isDisabled={!form.customer}
                                options={this.formatSelect(this.getFilteredPaymentMethods(), ['displayName'])}
                                value={form.paymentMethod}
                                onChange={selected => form.paymentMethod = selected}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Shipping Agent</Label>
                            <Select
                                placeholder='Shipping Agent'
                                isDisabled={!form.customer}
                                options={this.formatSelect(B2BStore.list.shippingAgents, ['name'])}
                                value={form.shippingAgent}
                                onChange={selected => form.shippingAgent = selected}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Document Source Type</Label>
                            <Select
                                placeholder='Document Source Type'
                                isDisabled={!form.customer}
                                options={this.formatSelect(B2BStore.list.documentSourceTypes, ['code', 'description'])}
                                value={form.documentSourceType}
                                onChange={selected => form.documentSourceType = selected}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns style={styles.sectionTop}>
                        <Columns.Column>
                            <Label>Reference Number</Label>
                            <Input
                                placeholder='Reference Number'
                                disabled={!form.customer}
                                value={form.referenceNumber}
                                onChange={e => form.referenceNumber = e.target.value}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Internal Note (optional)</Label>
                            <Input
                                placeholder='Internal Note'
                                disabled={!form.customer}
                                value={form.internalNote}
                                onChange={e => form.internalNote = e.target.value}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Warehouse Location</Label>
                            <Select
                                placeholder='Warehouse Location'
                                isDisabled={!form.customer}
                                options={this.formatSelect(B2BStore.list.locations, ['displayName'])}
                                value={form.location}
                                onChange={selected => form.location = selected}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Label>Shipping Cost</Label>
                            <Input
                                type='number'
                                placeholder='Shipping Cost'
                                min={0}
                                disabled={!form.customer}
                                value={form.shippingCosts}
                                onChange={e => form.shippingCosts = e.target.value}
                            />
                        </Columns.Column>
                    </Columns>
                    <Container style={{...styles.section, ...styles.sectionTop, display: 'flex'}}>
                        <Columns.Column style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                        {
                        (discountTotal > 0 && form.discountType) &&
                            <p style={{ marginBottom: '0.5em' }}>
                                Invoice discount: { form.discountType?.value === 'absolute' ? priceFormatter.format(Number(form.discountValue)) : `${form.discountValue} %`}
                                <br/>
                                Discount amount: {priceFormatter.format(discountTotal)}
                            </p>
                        }
                            <p><b>Total excl. tax: {priceFormatter.format(total)}</b></p>
                        </Columns.Column>
                        <Columns.Column style={{ flexGrow: 0.5 }}>
                            <Label>Invoice discount {form.discountType ? form.discountType.value === 'percentage' ? '(%)' : `(Amount)` : ''}</Label>
                            <Input
                                type='number'
                                placeholder='Discount amount'
                                min={0}
                                max={form.discountType?.value === 'percentage' ? 100 : 100000000}
                                disabled={!form.customer}
                                value={form.discountValue}
                                onChange={e => form.discountValue = e.target.value}
                            />
                        </Columns.Column>
                        <Columns.Column style={{ flexGrow: 0.5 }}>
                            <Label>Discount type</Label>
                            <Select
                                placeholder='Discount type'
                                isDisabled={!form.customer}
                                options={options.discountTypes}
                                value={form.discountType}
                                onChange={selected => form.discountType = selected}
                            />
                        </Columns.Column>
                    </Container>
                    <Button
                        color='primary'
                        fullwidth={true}
                        loading={loading}
                        disabled={loading || !form.customer || !form.shippingAddress}
                        onClick={() => this.submitOrder()}
                    >
                        Create Order
                    </Button>
                    <Container style={{ overflowX: 'scroll' }}></Container>
                </FetchView>
            </Container>
        </>);
    }
}

const styles = {
    section: {
        borderBottom: '1px solid grey',
        marginBottom: 20,
        paddingBottom: 20,
    },
    sectionTop: {
        borderTop: '1px solid grey',
        marginTop: 20,
        paddingTop: 20,
    },
    note: {
        padding: 15,
        borderRadius: 10,
        background: '#eee',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: 18,
        backgroundColor: 'rgba(255, 224, 138, 0.2)',
        border: '1px solid #ffe08a',
        borderRadius: 5,
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    table: {
        row: {
            display: 'flex',
        },
        cell: {
            flexShrink: 0,
            width: '100%',
            borderBottom: 'none',
            padding: '5px 10px',
        },
    },
};

export default B2BOrderCreation;