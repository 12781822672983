import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../../NavigationBar/NavigationBar";
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Container, Modal} from "react-bulma-components";
import {MarketStore} from "../../../../stores";
import FetchView from "../../../dumb/FetchView/FetchView";
import GenericForm from "../../GenericForm/GenericForm";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {toast} from "react-toastify";

@observer
class MarketsOverview extends Component {
    @observable accessor localstate = {
        edit: false,
        inventoryEdit: false
    }

    onSave() {
        this.localstate.edit = null;
    }

    editMarket(market) {
        this.localstate.edit = market;
    }

    async deleteMarket(market) {
        if (window.confirm('Are you absolutely sure, that you want to delete this market and all its data?')) {
            await MarketStore.deleteItem(market);
            toast.success("Deleted the market '" + market.name + "'")
        }
    }

    render() {
        const {edit} = this.localstate;
        return  (<div>
            <NavigationBar title='Markets Overview'>
                <Button onClick={() => this.editMarket({})}>Add market</Button>
            </NavigationBar>
            <Container style={styles.container}>
                <FetchView store={MarketStore}>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    Name
                                </TableCell>
                                <TableCell colSpan={2}>
                                    eMail
                                </TableCell>
                                <TableCell colSpan={2}>
                                    City/Storage
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Standgebühr
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Taxikosten
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Arbeitszeit
                                </TableCell>
                                <TableCell colSpan={4}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {MarketStore.list.map((market, i) => {
                            return (<TableRow key={i}>
                                        <TableCell colSpan={2}>{market.name}</TableCell>
                                        <TableCell colSpan={2}>{market.email}</TableCell>
                                        <TableCell colSpan={2}>{market.city}</TableCell>
                                        <TableCell colSpan={2}>{market.standgebuehr} €</TableCell>
                                        <TableCell colSpan={2}>{market.taxikosten} €</TableCell>
                                        <TableCell colSpan={2}>{market.arbeitszeit}h</TableCell>
                                        <TableCell colSpan={4}>
                                            <Button onClick={() => this.editMarket(market)}>Edit</Button>
                                            <Button onClick={() => this.deleteMarket(market)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>);
                        })}
                        </TableBody>
                    </Table>
                </FetchView>
            </Container>
            <Modal show={edit ? true : false} onClose={this.onSave.bind(this)}>
                <GenericForm store={MarketStore} id={edit?.id} onSave={this.onSave.bind(this)}/>
            </Modal>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
        padding: 20
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    }
}

export default MarketsOverview;