import {observer} from "mobx-react";
import React, {Component} from "react";
import {observable} from "mobx";
import {Container, Button, Section, Form} from "react-bulma-components";
import NavigationBar from "../NavigationBar/NavigationBar";
import {pdf, Document} from "@react-pdf/renderer";
import {toast} from "react-toastify";
import RestClient from "../../../shared/Network/RestClient";
import translations from "./translations";
import PDFPage from "./PDFPage";
const {Input} = Form;

@observer
class LabelGenerator extends Component {
    @observable accessor localstate = {
        loading: false,
        output: 'blob', // download | blob
        orderNr: '',
        pdfData: {
            language: 'DE',
            articles: []
        }
    };

    async getOrder() {
        const {orderNr, pdfData, output} = this.localstate;

        this.localstate.loading = true;

        try {
            const request = RestClient.prepareRequest('GET', `labelgenerator/orders/${orderNr}`);
            const res = await request.send();

            if (res.success && 'data' in res) {
                if (!res.data.value || res.data?.value?.length === 0) throw 'No order information could be found';

                const order = res.data.value[0];
                pdfData.language = order.shipToCountry;
                
                if (!translations[pdfData.language]) {
                    toast.warning(`Warning: No translation could be found for „${pdfData.language}“. The default language is now German (DE)`);
                    pdfData.language = 'DE';
                }

                const articles = await this.getArticleData(order.salesOrderLines || order.salesInvoiceLines);
                pdfData.articles = articles;

                await this.downloadPDF();

                if (output === 'download') toast.success('PDF downloaded successfully');

                this.localstate.orderNr = '';
                this.localstate.loading = false;
            } else {
                throw res.error?.message || res.error || res.message || JSON.stringify(res) || 'An error occurred';
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
            this.localstate.loading = false;
        }
    }

    getArticleData(orderLines = []) {
        const {language} = this.localstate.pdfData;

        return new Promise(async (resolve, reject) => {
            try {
                const articleIDs = orderLines.filter(x => x.lineType === 'Item' && x.lineObjectNumber).map(x => `products=${x.lineObjectNumber}`);
                
                if (articleIDs.length === 0) throw 'There are no articles to generate the label';
        
                const request = RestClient.prepareRequest('GET', `labelgenerator/productdata/${language.toLowerCase()}?${articleIDs.join('&')}`);        
                const res = await request.send();

                if (res.success && 'data' in res) {
                    if (res.data?.length === 0) throw 'No valid articles could be found for this order number';
                
                    for (const article of res.data) {
                        const orderArticle = orderLines.find(x => x.lineObjectNumber === article.productNumber);

                        article.quantity = orderArticle?.quantity || 1;
                    }

                    resolve(res.data);
                } else {
                    throw res.error?.message || res.error || res.message || JSON.stringify(res) || 'An error occurred';
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    downloadPDF() {
        return new Promise(async (resolve, reject) => {
            try {
                const {orderNr, output} = this.localstate;
                const blob = await pdf(this.createPDF()).toBlob();
                const link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                link.download = `Label ${orderNr}.pdf`;

                if (output === 'blob') window.open(link.href, '_blank');
                if (output === 'download') link.click();

                resolve();
            } catch (error) {
                reject(`PDF could not be created: ${error}`);
            }
        });
    }

    createPDF() {
        const {pdfData} = this.localstate;
        const translation = translations[pdfData.language];
        const pages = [];

        if (!translation) {
            toast.error(`No translation could be found for „${pdfData.language}“`);
            return;
        }

        for (const article of pdfData.articles) {
            for (let i = 0; i < article.quantity; i++) {
                for (const key in article) {
                    if (typeof article[key] === 'string') {
                        article[key] = article[key]
                            .replace(/(\s?\\[a-z])|&nbsp;/gmi, '')
                            .replace(/\s{2,}|\n|[\u202F\u00A0]/gm, ' ')
                            .replace(/^\s+|\s+$/gm, '');
                    }
                }
                pages.push(<PDFPage key={Math.random()} article={article} translation={translation}/>);
            }
        }

        return (
            <Document>
                {pages.map(x => x)}
            </Document>
        );
    }

    render() {
        const {loading, orderNr} = this.localstate;
        return (<div>
            <NavigationBar title='Label Generator'></NavigationBar>
            <Container style={{marginTop: 20}}>
                <Container >
                    <Section style={{textAlign: 'center'}}>
                        <Input
                            style={{marginBottom: '15px', width: '300px'}}
                            value={this.localstate.orderNr}
                            type="text"
                            onChange={e => this.localstate.orderNr = e.target.value?.replace(/^\s+/, '')}
                            placeholder='Order Nr.'
                        />
                        <br/>
                        <Button 
                            onClick={() => this.getOrder()}
                            color='primary'
                            style={{width: '300px'}}
                            disabled={orderNr === ''}
                            loading={loading}
                        >
                            Generate PDF
                        </Button>
                    </Section>
                </Container>
            </Container>
        </div>);
    }
}

export default LabelGenerator;