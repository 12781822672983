import React, { Component } from "react";
import { observer } from "mobx-react";
import { configure, autorun } from "mobx";
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Login from "./components/screen/Login/Login";
import Authentication from "./shared/Authentication";
import "animate.css";
import "public.css";
import { Page } from "./components/dumb";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// HR Challenges Feature
import HRChallenges from "./components/screen/HRChallenges";
import ChallengeEditor from "components/screen/HRChallenges/ChallengeEditor";
import InviteCreation from "components/screen/HRChallenges/InviteCreation";
import Challenge from "components/screen/HRChallenges/Challenge";
import ChallengeResults from "components/screen/HRChallenges/ChallengeResults";
import ManageChallenges from "components/screen/HRChallenges/ManageChallenges";

// Markets Feature
import Markets from "./components/screen/Markets/Markets";
import StatementInput from "./components/screen/Markets/MarketDailyStatement/StatementInput"
import StatementsOverview from "./components/screen/Markets/StatementsOverview/StatementsOverview";

// Bottler Feature
import Bottler from "components/screen/Bottler/Bottler";

// B2B Feature
import B2BOrders from "./components/screen/B2BOrders/B2BOrders";

// Commercial Invoice Builder Feature
import CommercialInvoiceBuilderScreen from "./components/screen/CommercialInvoiceBuilder/CommercialInvoiceBuilderScreen";

// Label Generator
import LabelGenerator from "./components/screen/LabelGenerator/LabelGenerator";

// Supplier Questionnaire
import SupplierQuestionnaire from "./components/screen/SupplierQuestionnaire/SupplierQuestionnaire";

// General Pages
import NotFound from "./components/screen/NotFound/NotFound";
import PasswordForgotten from "./components/screen/Password/PasswordForgotten";
import PasswordReset from "./components/screen/Password/PasswordReset";
import Header from "./components/screen/Header/Header";
import HomeScreen from "./components/screen/HomeScreen/HomeScreen";
import UserManagement from "./components/screen/Usermanagement/UserManagement";
import tabs from "./config/tabs";

import ReplacementOrderScreen from "./components/screen/ReplacementOrderScreen/ReplacementOrderScreen";
import Lottery from "./components/screen/Lottery/Lottery";
import LotteryRegister from "./components/screen/Lottery/LotteryRegister";
import DelayedSupplierOrders from "components/screen/DelayedSupplierOrders/DelayedSupplierOrders";

configure({ enforceActions: 'never' });

@observer
class App extends Component {
  localstatus = {
    loggedIn: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>

          <Route exact path="/challenge/:token" component={Challenge} />
          <Route exact path="/public/markets/statements" component={StatementsOverview} />
          <Route exact path="/public/markets/:id/statement/:date" component={StatementInput} />
          <Route exact path="/public/markets/:id/statement/:date/:approve" component={StatementInput} />
          <Route exact path="/public/lottery" component={Lottery} />
          <Route exact path="/public/lotteryregister" component={LotteryRegister} />
          <Route exact path="/orderdelay/:urlCode" component={DelayedSupplierOrders} />

          <Route exact path="/replacementorders" component={ReplacementOrderScreen} />
          <Route exact path="/passwordforgotten"><PasswordForgotten /></Route>
          <Route exact path="/passwordreset"><PasswordReset /></Route>

          <PrivateRoute exact path="/" component={HomeScreen} />
          <PrivateRoute exact path="/hrchallenges" component={HRChallenges} />
          <PrivateRoute exact path="/hrchallenges/challenge/:id?" component={ChallengeEditor} />
          <PrivateRoute exact path="/hrchallenges/challenges" component={ManageChallenges} />
          <PrivateRoute exact path="/hrchallenges/invite" component={InviteCreation} />
          <PrivateRoute exact path="/challenge/results/:id" component={ChallengeResults} />
          <PrivateRoute exact path="/markets" component={Markets} />
          <PrivateRoute exact path="/markets/*:path" component={Markets} />
          <PrivateRoute exact path="/bottler/*:path" component={Bottler} />
          <PrivateRoute exact path="/b2bOrders" component={B2BOrders} />
          <PrivateRoute exact path="/b2bOrders/*:path" component={B2BOrders} />
          <PrivateRoute exact path="/commercialinvoice" component={CommercialInvoiceBuilderScreen} />
          <PrivateRoute exact path="/labelgenerator" component={LabelGenerator} />
          <PrivateRoute exact path="/supplier" component={SupplierQuestionnaire} />
          <PrivateRoute exact path="/settings/users" component={UserManagement} />

          <Route path="*">
            <NotFound loggedIn={Authentication.localstatus.loggedIn} />
          </Route>
        </Switch>
        <ToastContainer />
      </HashRouter>
    );
  }
}

function PrivateRoute({ component, children, ...rest }) {
  let history = useHistory();

  autorun(() => {
    if (!Authentication.localstatus.loggedIn) {
      history.push("/login");
    }
  }, "LogoutRouter");

  return (
    <div>
      <Header />
      <div>
        <Route
          {...rest}
          render={(props) => {
            if (!Authentication.localstatus.loggedIn) {
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              );
            } else if (
              props.location.pathname !== "/" &&
              !Authentication.hasAccess(
                tabs.find((x) => x.url === props.location.pathname)
              )
            ) {
              return (
                <Page location={props.location} history={history}>
                  Sorry, you are not authorized for this feature.
                </Page>
              );
            } else {
              return (
                <Page location={props.location} history={history}>
                  {React.createElement(
                    component,
                    props.match
                      ? { ...props.match.params, location: props.location }
                      : { location: props.location }
                  )}
                </Page>
              );
            }
          }}
        />
      </div>
    </div>
  );
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  autorun(() => {
    if (Authentication.localstatus.loggedIn) {
      history.replace(from);
    }
  }, "AuthenticationRouter");

  return <Login />;
}

export default App;
