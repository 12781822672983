import React, { Component } from "react";
import { Heading, Container } from "react-bulma-components";
import { toast } from "react-toastify";
import FileDownload from "shared/FileDownload";
import RestClient from "shared/Network/RestClient";

class ChallengeResults extends Component {
  componentDidMount() {
    this.fetchFiles();
  }

  async fetchFiles() {
    const request = RestClient.prepareRequest(
      "GET",
      "challenge-files/get-results/" + this.props.id
    );

    try {
      const { filename, data } = await FileDownload.parseFileDownloadResponse(
        request
      );

      FileDownload.downloadFile(new File([data], filename));
    } catch (error) {
      console.log(error);
      toast.error(
        "Etwas ist beim Herunterladen deiner Challenge-Dateien schiefgelaufen. Bitte versuche es erneut."
      );
    }
  }

  render() {
    return (
      <Container style={styles.container}>
        <Heading>Einen Moment, die Dateien werden geladen...</Heading>
      </Container>
    );
  }
}

const styles = {
  container: {
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ChallengeResults;
