import React from "react";
import {Page, Text, View, StyleSheet, Font} from "@react-pdf/renderer";
import font from "../../../assets/Roboto.ttf";
import fontBold from "../../../assets/Roboto-Bold.ttf";

Font.register({
    family: 'Roboto',
    fonts: [
        {src: font},
        {src: fontBold, fontWeight: 'bold'}
    ]
});

function PDFPage(props) {
    const {article, translation} = props;
    const {firstColumnWidth, text} = pdfStyles;
    const {row, cell1, cell2, fontSize} = pdfStyles.table;

    const organicOrigin = (article.koro_organic_origin_countries)
        ? translation.koro_organic_origin_countries[article.koro_organic_origin_countries]
        : article.koro_organic_origin || null;

    const textSizeSettings = {
        factor: 1,
        initalCharLength: 980,
        maxIncrease: 1.6
    }

    const textLength =
        (article.germanName?.length || 0) +
        (article.koro_trade_name?.length || 0) +
        (article.koro_ingredients?.length || 0) +
        (article.koro_allergens?.length || 0) +
        (article.koro_storage_advice?.length || 0) +
        (organicOrigin?.length || 0) +
        (translation.koro_ingredients?.length || 0) +
        (translation.koro_allergens?.length || 0) +
        (translation.koro_storage_advice?.length || 0) +
        (translation.koro_organic_origin?.length || 0);

    const charFactor = ((textLength * 100) / textSizeSettings.initalCharLength) / 100;
    textSizeSettings.factor = textSizeSettings.factor + ((textSizeSettings.maxIncrease - textSizeSettings.factor) * (1 - charFactor));

    return (
        <Page size={[297.64, 104.88]} style={pdfStyles.page}>
            <View style={{ width: `${firstColumnWidth}%`, marginRight: 7 }}>
                <Text style={{ fontSize, fontWeight: 'bold', marginBottom: 2 }}>{translation.koro_nutrition_values}</Text>
                <Text style={{ fontSize, fontWeight: 'bold', textAlign: 'right', marginBottom: 3 }}>{translation.koro_nutrition_values_unit} 100 g</Text>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_energy}</Text>
                    <Text style={cell2}>{article.koro_nutrition_energy || 0} kcal{'\n'}{article.koro_nutrition_energy_kj || 0} kj</Text>
                </View>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_fat}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_fat)} g</Text>
                </View>
                <View style={row}>
                    <Text style={{ ...cell1, paddingLeft: 4 }}>{translation.koro_nutrition_fatty_acids}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_fatty_acids)} g</Text>
                </View>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_carbohydrates}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_carbohydrates)} g</Text>
                </View>
                <View style={row}>
                    <Text style={{ ...cell1, paddingLeft: 4 }}>{translation.koro_nutrition_sugar}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_sugar)} g</Text>
                </View>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_fiber}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_fiber)} g</Text>
                </View>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_protein}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_protein)} g</Text>
                </View>
                <View style={row}>
                    <Text style={cell1}>{translation.koro_nutrition_salt}</Text>
                    <Text style={cell2}>{point2comma(article.koro_nutrition_salt)} g</Text>
                </View>
            </View>
            <View style={{ width: `${100 - firstColumnWidth}%` }}>
                <Text style={{ fontSize: text.l.fontSize * textSizeSettings.factor }}>{article.germanName}</Text>
                <Text style={{ fontSize: text.m.fontSize * textSizeSettings.factor, marginBottom: 4 }}>{article.koro_trade_name}</Text>
                {
                    (article.koro_ingredients) ? (
                        <Text style={{ fontSize: text.s.fontSize * textSizeSettings.factor, marginBottom: 2 }}>
                            <Text style={{ fontWeight: 'bold' }}>{translation.koro_ingredients}: </Text>
                            <Text>{article.koro_ingredients}</Text>
                        </Text>
                    ) : null
                }
                {
                    (article.koro_storage_advice) ? (
                        <Text style={{ fontSize: text.s.fontSize * textSizeSettings.factor, marginBottom: 2 }}>
                            <Text style={{ fontWeight: 'bold' }}>{translation.koro_storage_advice}: </Text>
                            <Text>{article.koro_storage_advice}</Text>
                        </Text>
                    ) : null
                }
                {
                    (article.koro_allergens) ? (
                        <Text style={{ fontSize: text.s.fontSize * textSizeSettings.factor, marginBottom: 2 }}>
                            <Text style={{ fontWeight: 'bold' }}>{translation.koro_allergens}: </Text>
                            <Text>{article.koro_allergens}</Text>
                        </Text>
                    ) : null
                }
                {
                    (organicOrigin) ? (
                        <Text style={{ fontSize: text.s.fontSize * textSizeSettings.factor }}>
                            <Text style={{ fontWeight: 'bold' }}>{translation.koro_organic_origin}: </Text>
                            <Text>{organicOrigin}</Text>
                        </Text>
                    ) : null
                }
            </View>
        </Page>
    );
}

function point2comma(number = 0) {
    return number.toString().replace('.', ',');
}

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        width: '100%',
        fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        padding: 7,
        paddingBottom: 2
    },
    firstColumnWidth: 27,
    text: {
        s: {
            fontSize: 4.5
        },
        m: {
            fontSize: 5.5
        },
        l: {
            fontSize: 6
        }
    },
    table: {
        paddingLeft: 10,
        fontSize: 4.8,
        row: {
            width: '100%',
            flexDirection: 'row',
            marginBottom: 1.5
        },
        cell1: {
            width: '68%',
            fontSize: 4.8,
            fontWeight: 'bold'
        },
        cell2: {
            width: '32%',
            fontSize: 4.8,
            textAlign: 'right'
        }
    }
  });

export default PDFPage;