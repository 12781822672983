import React, {Component, createRef} from 'react';
import {Box, Button, Icon, Heading, Form} from 'react-bulma-components'
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {PageStructureNotLoggedIn} from '../../dumb';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import RestClient from '../../../shared/Network/RestClient';
import {FaEnvelope} from 'react-icons/fa';
const {Control, Field, Label} = Form;

@observer
class PasswordForgotten extends Component {
    constructor() {
        super();
        this.emailinput = createRef();
    }

    @observable accessor localstate = {
        loading: false,
        email: localStorage.getItem('passwordRecoveryEmail') || '',
        submitted: false,
    };

    async resetPassword() {
        const {email} = this.localstate;

        if (!email) { toast.error('Please enter your email address'); this.emailinput.current?.focus(); return; }
        if (!email.match(/^\S+@\S+\.\S+$/)) { toast.error('Your email address is invalid'); this.emailinput.current?.focus(); return; }

        try {
            this.localstate.loading = true;

            const request = RestClient.prepareRequest('POST', 'auth/passwordresetrequest');
            request.setData({ email });
            const res = await request.send();
    
            if (!res.success) throw res;
            
            localStorage.removeItem('passwordRecoveryEmail');
            toast.success('Check your email inbox');
            this.localstate.submitted = true;
            this.localstate.loading = false;
        } catch (error) {
            console.error(error);
            toast.error(error.error?.message || error.error || error.message || JSON.stringify(error) || 'An error occurred');
            this.localstate.loading = false;
        }
    }

    componentDidMount() {
        this.emailinput.current?.focus();
    }

    componentWillUnmount() {
        localStorage.removeItem('passwordRecoveryEmail');
    }

    render() {
        const {loading, email, submitted} = this.localstate;

        return (
            <PageStructureNotLoggedIn>
                <Heading>Password forgotten</Heading>
                <Box>
                    {
                        (!submitted) ?
                            <>
                                <Field>
                                    <Label>Email address</Label>
                                    <Control>
                                        <input
                                            ref={this.emailinput}
                                            className='input'
                                            type='email'
                                            value={email}
                                            placeholder='name@company.de'
                                            disabled={loading}
                                            onChange={(e) => this.localstate.email = e.target.value}
                                            onKeyDown={(e) => e.key === 'Enter' && this.resetPassword()}
                                        />
                                        <Icon align='left'>
                                            <FaEnvelope color='#363636'>Logout</FaEnvelope>
                                        </Icon>
                                    </Control>
                                </Field>
                                <Button
                                    color='primary'
                                    fullwidth={true}
                                    loading={loading}
                                    style={{ marginBottom: 10 }}
                                    onClick={() => this.resetPassword()}
                                >
                                    Request a new password
                                </Button>
                            </>
                            :
                            <Heading size={5} style={{ color: 'inherit', marginBottom: 20 }}>
                                An email was sent to {email}<br/>
                                <br/>
                                Check your email inbox!
                            </Heading>
                    }
                    <Link to='/login'>Back to login</Link>
                </Box>
            </PageStructureNotLoggedIn>
        );
    }
}

export default PasswordForgotten;