import {observer} from "mobx-react";
import React, {Component} from "react";
import {observable} from "mobx";
import NavigationBar from "../../NavigationBar/NavigationBar";
import {Box, Button, Container, Modal} from "react-bulma-components";
import RestClient from "../../../../shared/Network/RestClient";
import FetchView from "../../../dumb/FetchView/FetchView";
import {Input, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import Header from "../../Header/Header";
import {Link} from "react-router-dom";

@observer
class MarketStatementOverview extends Component {

    @observable accessor localstate = {
        edit: null,
        editInventory: null,
        statementList:[]
    }

    async getMissingMarketStatements() {
        const request = RestClient.prepareRequest('GET', 'markets/statements/missing');
        const response = await request.send();
        this.localstate.statementList = response.data;
    }

    render() {
        const {statementList} = this.localstate;
        return (<div>
            <Header></Header>
            <NavigationBar title='Market Statements' />
            <Container style={styles.container}>
                <div style={styles.overflowContainer}>
                <FetchView call={this.getMissingMarketStatements.bind(this)}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Markt</TableCell>
                                <TableCell>Statement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {statementList && statementList.map(item => {
                                return (
                                    <TableRow>
                                        <TableCell>{moment(item.datum).format('DD.MM.Y') }</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.StatementID ? <div>
                                            <Link to={'/public/markets/' + item.id + '/statement/' + item.datum}>
                                                <Button>Statement bearbeiten</Button>
                                            </Link>
                                        </div> : <div>
                                            <Link to={'/public/markets/' + item.id + '/statement/' + item.datum}>
                                                <Button color="primary">Statement einreichen</Button>
                                            </Link>
                                        </div>}</TableCell>
                                    </TableRow>);
                            })}
                        </TableBody>
                    </Table>
                </FetchView>
                </div>
            </Container>

        </div>)
    }
}

const styles = {
    container: {
        padding: 20
    },
    overflowContainer:{
        overflowX: 'auto'
    }
}

export default MarketStatementOverview;