const styles = {
    section: {
        borderBottom: '1px solid grey',
        padding: '30px 0px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
        backgroundColor: 'rgba(0, 209, 178, 0.2)',
        border: '1px solid #00d1b2',
        borderRadius: 5,
        padding: 20,
        marginTop: 20,
    },
    infoYellow: {
        backgroundColor: 'rgba(255, 224, 138, 0.2)',
        border: '1px solid #ffe08a',
    },
    infoRed: {
        backgroundColor: 'rgba(241, 70, 104, 0.2)',
        border: '1px solid #f14668',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        gap: 15,
    },
    column: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
    },
    parting: {
        borderBottom: '1px solid grey',
        paddingBottom: 10,
        marginBottom: 15,
    },
    partingTop: {
        borderTop: '1px solid grey',
        paddingTop: 15,
        marginTop: 15,
    },
    switchBox: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    switchBoxText: {
        fontSize: 20,
    },
    switchBoxTextSmall: {
        fontSize: 16,
    },
    note: {
        fontStyle: 'italic',
        marginTop: 15,
        marginBottom: 30,
    },
    noteWarning: {
        backgroundColor: 'rgba(255, 224, 138, 0.2)',
        border: '1px solid #ffe08a',
        borderRadius: 5,
        padding: 10,
        marginTop: 15,
        marginBottom: 30,
    },
    headline: {
        fontSize: 24,
        marginBottom: 15,
    },
    subHeadline: {
        fontSize: 20,
        marginBottom: 25,
    },
    inputBox: {
        marginBottom: 15,
    },
    buttonBox: {
        marginBottom: 25,
    },
    icon: {
        cursor: 'pointer',
    },
    modalContainer: {
        minWidth: 700,
        maxHeight: '90vh',
        background: 'white',
        borderRadius: 5,
        padding: 20,
        overflowY: 'auto',
    },
    paginateContainer: {
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        marginTop: '20px',
        backgroundColor: '#fff',
        padding: '10px 0px',
    },
    table: {
        style: {
            minWidth: 500,
        },
        headline: {
            fontSize: 24,
            marginTop: 30,
            marginBottom: 10,
        },
    },
    modal: {
        backgroundColor: 'white',
    },
};

export default styles;