import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Columns, Section} from 'react-bulma-components';
import {FaDownload, FaUpload, FaTrash} from 'react-icons/fa';
import {toast} from 'react-toastify';
import styles from '../styles';
import translation from '../translations';
import PackagingQuestionnaireStore from 'stores/Supplier/PackagingQuestionnaireStore';

@observer
class Section7 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
    }

    downloadFile = async (file) => {
        try {
            this.localstate.loading = true;

            const data = await PackagingQuestionnaireStore.downloadFile(file);
            if (!data) throw 'Missing download data';

            const downloadFile = new File([data], file.name);
            const blob = new Blob([downloadFile], { type: file.type });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.setAttribute('download', file.name);
            link.setAttribute('href', url);
            link.click();
            link.remove();
            URL.revokeObjectURL(url);
            
            this.localstate.loading = false;
        } catch (error) {
            console.error(error);
            toast.error(error?.error?.message || error?.error || error?.message || JSON.stringify(error) || 'Error downloading file');
            this.localstate.loading = false;
        }
    }

    deleteFile = async (file) => {
        const {files, language} = this.localstate;
        const tl = translation[language.value];

        const confirmed = window.confirm(tl.section7.deleteConfirm);
        if (!confirmed) return;
        
        this.localstate.loading = true;
        const res = await PackagingQuestionnaireStore.deleteFile(file);

        if (!res.success) {
            toast.error(res.error?.message || res.error || res.message || JSON.stringify(res) || 'Error deleting file');
            this.localstate.loading = false;
            return;
        }

        const fileIndex = files.findIndex(x => x.id === file.id);
        if (fileIndex >= 0) files.splice(fileIndex, 1);

        this.localstate.loading = false;
        toast.success(tl.section7.deleteSuccess);
    }

    render() {
        const {loading, files, filesToUpload, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>7. {tl.section7.headline}</h1>
                <div style={styles.noteWarning}>{tl.section7.uploadText}</div>
                <Columns>
                    <Columns.Column className='is-one-quarter'>
                        <h2 style={{ ...styles.subHeadline, marginBottom: 10 }}>{tl.section7.uploadedFiles}</h2>
                    {
                        files.length > 0 ?
                            files.map((file, i) => (
                                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <p>{file.name}</p>
                                    <FaDownload style={styles.icon} onClick={() => this.downloadFile(file)}/>
                                    <FaTrash style={styles.icon} onClick={() => this.deleteFile(file)}/>
                                </div>
                            ))
                            :
                            <p style={{ fontStyle: 'italic' }}>{tl.none}</p>
                    }
                    </Columns.Column>
                    <Columns.Column>
                        <h2 style={{ ...styles.subHeadline, marginBottom: 10 }}>{tl.section7.choosenFiles}</h2>
                    {
                        filesToUpload.length > 0 ?
                            filesToUpload.map((file, i) => (
                                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <p>{file.name}</p>
                                    <FaTrash style={styles.icon} onClick={() => this.localstate.filesToUpload.splice(i, 1)}/>
                                </div>
                            ))
                            :
                            <p style={{ fontStyle: 'italic' }}>{tl.none}</p>
                    }
                        <label
                            className='is-primary button'
                            htmlFor='file-upload'
                            disabled={loading}
                            style={{ marginTop: 15 }}
                        >
                            <FaUpload style={{ marginRight: 10 }}/>{tl.section7.uploadButton}
                        </label>
                    </Columns.Column>
                </Columns>
            </Section>
        );
    }
}

export default Section7;