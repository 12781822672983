import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Switch} from '@material-ui/core';
import {Columns, Section, Form} from 'react-bulma-components';
import Select from 'react-select';
import styles from '../styles';
import translation from '../translations';
import SupplierItemsStore from 'stores/Supplier/SupplierItemsStore';

const {Label} = Form;

@observer
class Section1 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.error = props.parent.error.bind(this);
        this.handleInput = props.parent.handleInput.bind(this);
        this.setOutdatedValue = props.parent.setOutdatedValue.bind(this);
    }
    
    changeLanguage = (selected = this.localstate.language) => {
        this.localstate.language = selected;
        const {options, language} = this.localstate;
        const tl = translation[language.value];

        for (const item of options.plasticPack.materialTransparencies) {
            item.label = tl.section3[item.value] || item.label;
        }

        for (const item of options.plasticPack.materialTypes) {
            item.label = tl.section3[item.value] || item.label;
        }

        for (const item of options.plasticPack.materials) {
            item.label = tl.section3[item.value] || item.label;
        }

        for (const item of options.otherPack.materials) {
            item.label = tl.section3[item.value] || item.label;

            if (!item.materialTypes) continue;

            for (const subItem of item.materialTypes) {
                subItem.label = tl.section3[subItem.value] || subItem.label;
            }
        }
    }

    onSkuSelect(selectedSkus = []) {
        const {form} = this.localstate;
        if (!selectedSkus) selectedSkus = [];

        this.localstate.form.outdatedSkus = form.outdatedSkus.filter(outdatedSku => {
            return selectedSkus.map(sku => sku.value).includes(outdatedSku.value);
        });

        this.localstate.form.skus = selectedSkus;
        this.setOutdatedValue();
    }

    componentDidMount() {
        this.changeLanguage();
    }

    render() {
        const {selected, loading, adminAccess, options, form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>1. {tl.section1.headline}</h1>
                <div style={styles.noteWarning}>{tl.section1.headNote}</div>
                <Columns>
                    <Columns.Column>
                        <Label>{tl.language}</Label>
                        <Select
                            placeholder={`${tl.select}...`}
                            options={options.language}
                            value={language}
                            onChange={this.changeLanguage}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <Label>{tl.section1.sku}</Label>
                        <Select
                            className={this.error(form.skus)}
                            isMulti
                            placeholder={`${tl.select}...`}
                            isDisabled={loading}
                            options={
                                SupplierItemsStore && (!adminAccess || (selected.questionnaire || selected.supplier))
                                    ? SupplierItemsStore.list.map(item => ({ label: `${item.sku} - ${item.description}`, value: item.sku }))
                                    : []
                            }
                            value={form.skus}
                            onChange={this.onSkuSelect.bind(this)}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <div style={styles.switchBox}>
                            <h2 style={styles.switchBoxText}>{tl.section1.checkboxText}:</h2>
                            <Switch
                                disabled={loading}
                                checked={form.trayAvailable}
                                onChange={e => this.localstate.form.trayAvailable = e.target.checked}
                            />
                        </div>
                    </Columns.Column>
                </Columns>
            </Section>
        );
    }
}

export default Section1;