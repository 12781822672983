/* eslint-disable no-undef */
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import moment from 'moment';
import {CsvBuilder} from 'filefy';
import {Table, TableBody, TableCell, TableHead, TableRow, Switch} from '@material-ui/core';
import {Container, Button, Heading, Modal, Form, Icon} from 'react-bulma-components';
import {FaChevronLeft, FaAngleDoubleLeft, FaChevronRight, FaAngleDoubleRight} from 'react-icons/fa';
import {toast} from 'react-toastify';
import styles from '../styles';
import translation from '../translations';
import SupplierItemsStore from 'stores/Supplier/SupplierItemsStore';
import PackagingQuestionnaireStore from 'stores/Supplier/PackagingQuestionnaireStore';
const {Input} = Form;

@observer
class ProductsOverview extends Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.defaultValue = props.parent.defaultValue;
        this.parentstate = props.parent.localstate;
        this.reset = props.parent.reset.bind(this);
    }

    @observable accessor localstate = {
        search: '',
        itemsPerPage: 50,
        activePage: 0,
        filterOptions: {
            withQuestionnaires: true,
            withoutQuestionnaires: true,
        },
    };

    async selectItem(item) {
        const {confirmed} = this.defaultValue.form;

        const form = {
            ...item,
            supplierId: item?.DynamicsSupplier?.id || '',
            confirmed,
        }

        delete form.DynamicsSupplier;
        delete form.DynamicsSupplierId;
        delete form.id;
        delete form.createdAt;
        delete form.updatedAt;
        
        this.parentstate.loading = true;

        if (this.parentstate.form.supplierId !== form.supplierId || SupplierItemsStore?.list?.length === 0) {
            try {
                await SupplierItemsStore.fetchByUserId(item?.DynamicsSupplier?.userId);
            } catch (error) {
                console.error(error);
                this.parentstate.loading = false;
                toast.error(`Error fetching supplier items: ${error.error?.message || error.error || error.message || JSON.stringify(error) || 'Error fetching data'}`);
            }
        }

        this.parentstate.files = await PackagingQuestionnaireStore.getFiles(item?.id);
        
        this.parentstate.selected.questionnaire = item;
        this.parentstate.selected.supplier = null;
        this.parentstate.form = form;
        this.parentstate.submitted = false;
        this.parentstate.loading = false;
        this.parentstate.filesToUpload = [];
        this.onClose();
    }

    filterList(item) {
        const {withQuestionnaires, withoutQuestionnaires} = this.localstate.filterOptions;

        if (withQuestionnaires && item.questionnaires.length >= 1) return true;
        if (withoutQuestionnaires && item.questionnaires.length === 0) return true;

        return false;
    }

    searchInList(item) {
        const search = this.localstate.search.toLowerCase();
        const {no_, vendorno_, questionnaires} = item;

        if (!search) return true;

        if (
            (no_ && no_.toLowerCase().includes(search)) ||
            (vendorno_ && vendorno_.toLowerCase().includes(search)) ||
            (
                questionnaires &&
                questionnaires.length > 0 &&
                questionnaires[0].DynamicsSupplier.name.toLowerCase().includes(search)
            )
        ) return true;

        return false;
    }

    sortList(a, b) {
        const key = 'questionnaires';
        const prev = a[key]?.length || 0;
        const next = b[key]?.length || 0;
        return prev === next ? 0 : prev > next ? -1 : 1;
    }

    exportList(list = []) {
        const {language} = this.parentstate;
        const tl = translation[language.value];
        this.parentstate.loading = true;

        const csvBuilder = new CsvBuilder(`product-overview_${moment().format('DDMMYYYY')}.csv`)
            .setColumns([
                tl.productsOverview.productSku,
                tl.productsOverview.activeSupplier,
                tl.productsOverview.supplierQuestionnaire,
                tl.productsOverview.reminderSentAt,
            ]);

        for (const item of list) {
            const {no_, vendorno_, questionnaires} = item;

            csvBuilder.addRow([
                no_ || '',
                vendorno_ || '',
                questionnaires
                    .map((questionnaire) => `${questionnaire.DynamicsSupplier.name} - ${moment(questionnaire.createdAt).format(tl.dateFormat)}`)
                    .join(', '),
                '',
            ]);
        }

        csvBuilder.exportFile();
        this.parentstate.loading = false;
    }

    onClose() {
        this.parentstate.modal.productsOverview = false;
    }

    render() {
        const {search, activePage, itemsPerPage, filterOptions} = this.localstate;
        const {loading, language, modal} = this.parentstate;
        const tl = translation[language.value];

        const filteredList = this.store
            ? this.store.list
                .filter(this.filterList.bind(this))
                .filter(this.searchInList.bind(this))
                .sort(this.sortList.bind(this))
            : [];
        const paginatedList = filteredList.slice(activePage * itemsPerPage, (activePage + 1) * itemsPerPage);

        const maxPages = Math.ceil(filteredList.length / itemsPerPage);
        const pageStart = activePage * itemsPerPage + 1;
        let pageEnd = (activePage + 1) * itemsPerPage;
        if (pageEnd > filteredList.length) pageEnd = filteredList.length;

        return (
            <Modal show={modal.productsOverview} onClose={() => this.onClose()}>
                <Container style={{ ...styles.modalContainer, width: '1100px' }}>
                    <Heading style={{ textAlign: 'center', margin: '30px 0' }}>{tl.productsOverview.headline}</Heading>
                    <div style={{ ...styles.note, textAlign: 'center' }}>
                        {tl.productsOverview.headNote}<br/>
                        {tl.productsOverview.outdatedInfo}
                    </div>
                    <div style={styles.row}>
                        <Input
                            onChange={(e) => {
                                this.localstate.search = e.target.value;
                                this.localstate.activePage = 0;
                            }}
                            value={search}
                            placeholder={tl.search}
                        />
                        <Button
                            color='primary'
                            disabled={loading || filteredList.length === 0}
                            onClick={() => this.exportList(filteredList)}
                        >
                            {tl.export}
                        </Button>
                    </div>
                    <div style={styles.row}>
                        <div style={{ ...styles.switchBox, margin: 0 }}>
                            <h2 style={styles.switchBoxTextSmall}>{tl.productsOverview.filterWithQuestionnaires}:</h2>
                            <Switch
                                disabled={loading}
                                checked={filterOptions.withQuestionnaires}
                                onChange={e => filterOptions.withQuestionnaires = e.target.checked}
                            />
                        </div>
                        <div style={{ ...styles.switchBox, margin: 0 }}>
                            <h2 style={styles.switchBoxTextSmall}>{tl.productsOverview.filterWithoutQuestionnaires}:</h2>
                            <Switch
                                disabled={loading}
                                checked={filterOptions.withoutQuestionnaires}
                                onChange={e => filterOptions.withoutQuestionnaires = e.target.checked}
                            />
                        </div>
                    </div>
                    <Table aria-label='simple table' style={{ marginTop: '15px', background: 'white' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{tl.productsOverview.productSku}</TableCell>
                                <TableCell>{tl.productsOverview.activeSupplier}</TableCell>
                                <TableCell>{tl.productsOverview.supplierQuestionnaire}</TableCell>
                                <TableCell>{tl.productsOverview.reminderSentAt}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        paginatedList
                            .map((item, i) => {
                                const {no_, vendorno_, questionnaires} = item;
                                return (
                                    <TableRow key={i}>
                                        <TableCell width='20%'>{no_}</TableCell>
                                        <TableCell>{vendorno_ || '/'}</TableCell>
                                        <TableCell width='35%'>
                                        {
                                        questionnaires.length > 0 ?
                                            questionnaires.map((questionnaire, i) => (
                                                <Button
                                                    key={i}
                                                    size='xs'
                                                    color={
                                                        questionnaire.outdated === 'outdated'
                                                            ? 'danger'
                                                            : questionnaire.outdated === 'partially outdated' ? 'warning' : 'primary'
                                                    }
                                                    loading={loading}
                                                    disabled={questionnaires.length === 0}
                                                    title={tl.select}
                                                    style={{ fontSize: '14px', height: '25px', padding: '0px 12px', marginBottom: '5px' }}
                                                    onClick={() => this.selectItem(questionnaire)}
                                                >
                                                    {questionnaire.DynamicsSupplier.name} - {moment(questionnaire.createdAt).format(tl.dateFormat)}
                                                </Button>
                                            ))
                                            : <p style={{ fontStyle: 'italic' }}>{tl.productsOverview.noQuestionnaires}</p>
                                        }
                                        </TableCell>
                                        <TableCell>/</TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                    <Container style={styles.paginateContainer}>
                        <Container>
                            <p style={{ fontWeight: 'bold' }}>{tl.productsOverview.products} {pageStart}-{pageEnd} {tl.of} {filteredList.length}</p>
                        </Container>
                        <Container style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                            <Button
                                size='small'
                                disabled={loading || activePage === 0}
                                onClick={() => this.localstate.activePage = 0}
                            >
                                <Icon>
                                    <FaAngleDoubleLeft color='#363636' size={18}/>
                                </Icon>
                            </Button>
                            <Button
                                disabled={loading || activePage === 0}
                                onClick={() => this.localstate.activePage --}
                            >
                                <Icon>
                                    <FaChevronLeft color='#363636' size={18}/>
                                </Icon>
                            </Button>

                            <p style={{ width: '60px', textAlign: 'center', margin: '0px 10px' }}>{activePage + 1} / {maxPages}</p>

                            <Button
                                disabled={loading || activePage + 1 >= maxPages}
                                onClick={() => this.localstate.activePage ++}
                            >
                                <Icon>
                                    <FaChevronRight color='#363636' size={18}/>
                                </Icon>
                            </Button>
                            <Button
                                size='small'
                                disabled={loading || activePage + 1 >= maxPages}
                                onClick={() => this.localstate.activePage = maxPages - 1}
                            >
                                <Icon>
                                    <FaAngleDoubleRight color='#363636' size={18}/>
                                </Icon>
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </Modal>
        );
    }
}

export default ProductsOverview;