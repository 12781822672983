/* eslint-disable no-undef */
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import moment from 'moment';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Container, Button, Heading, Modal, Form} from 'react-bulma-components';
import {toast} from 'react-toastify';
import styles from "../styles";
import translation from '../translations';
import SupplierItemsStore from 'stores/Supplier/SupplierItemsStore';
import PackagingQuestionnaireStore from 'stores/Supplier/PackagingQuestionnaireStore';
import ProductsOverviewStore from 'stores/Supplier/ProductsOverviewStore';
const {Input} = Form;

@observer
class PackagingQuestionnaires extends Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.defaultValue = props.parent.defaultValue;
        this.parentstate = props.parent.localstate;
        this.reset = props.parent.reset.bind(this);
    }

    @observable accessor localstate = {
        search: '',
    };

    async selectItem(item) {
        const {adminAccess} = this.parentstate;
        const {confirmed} = this.defaultValue.form;

        const form = {
            ...item,
            supplierId: item?.DynamicsSupplier?.id || '',
            confirmed,
        }

        delete form.DynamicsSupplier;
        delete form.DynamicsSupplierId;
        delete form.id;
        delete form.createdAt;
        delete form.updatedAt;
        
        this.parentstate.loading = true;

        if (adminAccess && (this.parentstate.form.supplierId !== form.supplierId || SupplierItemsStore?.list?.length === 0)) {
            try {
                await SupplierItemsStore.fetchByUserId(item?.DynamicsSupplier?.userId);
            } catch (error) {
                console.error(error);
                this.parentstate.loading = false;
                toast.error(`Error fetching supplier items: ${error.error?.message || error.error || error.message || JSON.stringify(error) || 'Error fetching data'}`);
            }
        }

        this.parentstate.files = await PackagingQuestionnaireStore.getFiles(item?.id);
        
        this.parentstate.selected.questionnaire = item;
        this.parentstate.selected.supplier = null;
        this.parentstate.form = JSON.parse(JSON.stringify(form));
        this.parentstate.submitted = false;
        this.parentstate.loading = false;
        this.parentstate.filesToUpload = [];
        this.onClose();
    }

    async deleteItem(item) {
        const {files, language} = this.parentstate;
        const tl = translation[language.value];
        
        const input = window.prompt(tl.questionnaires.confirmDelete);
        if (!input || !item) return;

        if (input.toUpperCase() !== 'DELETE') {
            toast.error(tl.questionnaires.deleteError);
            return;
        }

        this.parentstate.loading = true;

        try {
            PackagingQuestionnaireStore.setUrl('supplier/packagingquestionnaire');
            const success = await PackagingQuestionnaireStore.deleteItem(item);

            if (!success) throw 'An error occurred';

            for (const file of files) {
                const res = await PackagingQuestionnaireStore.deleteFile(file);
                if (!res.success) toast.error('A file could not be deleted');
            }

            await ProductsOverviewStore.fetchData(true);

            toast.success(tl.questionnaires.deleteSuccess);
            this.parentstate.loading = false;
            this.reset();
        } catch (error) {
            toast.error(error);
            this.parentstate.loading = false;
        }
    }

    filterList(item) {
        const search = this.localstate.search.toLowerCase();
        const {skus, createdAt, updatedAt} = item;
        const {vendorNr, name} = item.DynamicsSupplier;
        if (!search) return true;
        
        if (
            (vendorNr && vendorNr.toLowerCase().includes(search)) ||
            (name && name.toLowerCase().includes(search)) ||
            (createdAt && createdAt.toLowerCase().includes(search)) ||
            (updatedAt && updatedAt.toLowerCase().includes(search)) ||
            (skus && skus.find(sku => sku.value.toLowerCase().includes(search)))
        ) return true;

        return false;
    }

    sortList(a, b) {
        const key = 'vendorNr';
        const prev = Number(a.DynamicsSupplier[key]);
        const next = Number(b.DynamicsSupplier[key]);
        return prev === next ? 0 : prev > next ? 1 : -1;
    }

    onClose() {
        this.parentstate.modal.questionnaires = false;
    }

    render() {
        const {search} = this.localstate;
        const {loading, language, adminAccess, modal} = this.parentstate;
        const tl = translation[language.value];

        return (
            <Modal show={modal.questionnaires} onClose={() => this.onClose()}>
                <Container style={styles.modalContainer}>
                    <Heading style={{ textAlign: 'center', margin: '30px 0' }}>{tl.questionnaires.headline}</Heading>
                    <div style={{ ...styles.note, textAlign: 'center' }}>{tl.questionnaires.headNote}</div>
                    <Input
                        onChange={(e) => this.localstate.search = e.target.value}
                        value={search}
                        placeholder={tl.search}
                    />
                    <Table aria-label='simple table' stickyHeader style={{marginTop: '15px'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{tl.vendorNr}</TableCell>
                                <TableCell>{tl.name}</TableCell>
                                <TableCell>{tl.section1.sku}</TableCell>
                                <TableCell>{tl.createdAt}</TableCell>
                                <TableCell>{tl.updatedAt}</TableCell>
                                <TableCell></TableCell>
                                { adminAccess && <TableCell></TableCell> }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        this.store &&
                            this.store.list
                                .filter(this.filterList.bind(this))
                                .sort(this.sortList.bind(this))
                                .map((item, i) => {
                                    const {selected} = this.parentstate;
                                    const {skus, createdAt, updatedAt} = item;
                                    const {vendorNr, name} = item.DynamicsSupplier;

                                    return (
                                        <TableRow
                                            key={i}
                                            style={{
                                                ...(item.id === selected.questionnaire?.id && {
                                                    backgroundColor: 'rgba(0, 209, 178, 0.2)',
                                                    border: '1px solid #00d1b2',
                                                })
                                            }}
                                        >
                                            <TableCell>{vendorNr}</TableCell>
                                            <TableCell>{name}</TableCell>
                                            <TableCell style={{ width: '10%' }}>{skus.map(sku => sku.value).join('\n')}</TableCell>
                                            <TableCell>{moment(createdAt).format(tl.dateFormat)}</TableCell>
                                            <TableCell>{moment(updatedAt).format(tl.dateFormat)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    color='primary'
                                                    loading={loading}
                                                    onClick={() => this.selectItem(item)}
                                                >
                                                    {tl.select}
                                                </Button>
                                            </TableCell>
                                            {
                                            adminAccess &&
                                                <TableCell>
                                                    <Button
                                                        color='danger'
                                                        loading={loading}
                                                        onClick={() => this.deleteItem(item)}
                                                    >
                                                        {tl.delete}
                                                    </Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                </Container>
            </Modal>
        );
    }
}

export default PackagingQuestionnaires;