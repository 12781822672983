import {Columns, Container, Section}  from 'react-bulma-components'
import React from "react";

function PageStructureNotLoggedIn ({children}) {
    return (<Section className='hero is-primary is-fullheight'>
        <div className="hero-body">
            <Container>
                <Columns centered={true}>
                    <Columns.Column size={5}>
                    {children}
                    </Columns.Column>
            </Columns>
        </Container>
        </div>
        </Section>);
}

export {PageStructureNotLoggedIn};