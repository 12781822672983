import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Button, Container, Form} from 'react-bulma-components'
import {toast} from "react-toastify";
import FetchView from "../../dumb/FetchView/FetchView";
import {Icon} from "../Login/Login";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
const {Control, Field, Input, Label, Checkbox, Textarea} = Form;

// default imports until here

@observer
class GenericForm extends Component {
    @observable accessor localstate = {
        content: {},
        status: {
            loading: false
        },
        form: "",
        formSpecific: {
            templates: {
                NameUnique: true
            }
        },
    };


    componentDidMount() {
       this.init();
    }

    async init() {
        const {store, id, form} = this.props;
        await store.fetchData();
        if (id) {
            const item = store.list.find(x => x.id === id);
            if (item) {
                let content = {...item};
                store.structure.forEach(def => {
                    const value = content[def.key];
                    if (def.type === "multi-select" || def.type === "multi-select-custom") {
                        content[def.key] = value.map(x => {
                            return {label: x, value: x}
                        });
                    } else if (def.type === "select") {
                        content[def.key] = {label: value, value};
                    }
                });

                this.localstate.content = content;
            }
        }
        this.localstate.form = form
    }

    onChange(k, v) {
        const {store} = this.props;
        const {form, formSpecific} = this.localstate
        this.localstate.content[k] = v;

        if (form === "templates" && k === "name") formSpecific.templates.NameUnique = this.checkNameUniqueness(v);

        for (let structureItem of store.structure) {
            if (structureItem.clearWhen) {
                const content = this.localstate.content;
                if (content && eval(structureItem.clearWhen)) {
                    if (structureItem.type === 'select' || structureItem.type === 'multi-select' || structureItem.type === 'multi-select-custom') this.localstate.content[structureItem.key] = [];
                    if (structureItem.type === 'checkbox') this.localstate.content[structureItem.key] = false;
                    if (structureItem.type === 'text' || structureItem.type === 'longtext') this.localstate.content[structureItem.key] = '';
                    if (structureItem.type === 'number') this.localstate.content[structureItem.key] = 0;
                }
            }
        }
    }

    checkNameUniqueness(n){
        const {store, id} = this.props;
        const tplNames = []
        store.list.map(item => {
            tplNames.push(item.name)
        })
        if (id) {
            let item = store.list.find(x => x.id === id);
            if (item.name === n){
                return true
            } else {
                return !tplNames.includes(n)
            }
        } else {
            return !tplNames.includes(n)
        }
    };

    async save() {
        const {store, id, onSave} = this.props;

        const {content} = this.localstate;

        // validate fields
        let bodyToSend = {id};
        let missingFields = [];
        store.structure.forEach(item => {
            if (item.type === "multi-select" || item.type === "multi-select-custom") {
                bodyToSend[item.key] = content[item.key] && content[item.key].map(x => x.value);
            } else if (item.type === "select"){
                bodyToSend[item.key] = content[item.key].value;
            } else {
                bodyToSend[item.key] = content[item.key];
            }
            if (item.required && !content[item.key]) {
                if (item.hideWhen && content && eval(item.hideWhen)) {
                    // skip
                } else {
                    missingFields.push(item.label);
                }
            }
        });

        if (missingFields.length > 0) {
           return toast.error('Please fill out all required fields: ' + missingFields.join(','));
        }

        this.localstate.status.loading = true;

        try {
            let result;
            if (id) {
                result = await store.updateItem(bodyToSend);
            } else {
                result = await store.createItem(bodyToSend);
            }

            if (result.success) {
                toast.success(`Successfully ${id ? 'updated' : 'created'}!`);
            } else {
                if(result.error.original){
                    toast.error(
                      `An Error occurred: ${result.error.original.code || ''} - ${
                        result.error.original.sqlMessage || ''
                      }`
                    );
                } else {
                    toast.error(
                        `An Error occurred: ${JSON.stringify(result.error)}`
                    )
                }
            }
        } catch (e) {
            toast.error(e);
        }
        this.localstate.status.loading = false;

        onSave && onSave(bodyToSend);
    }

    renderField(def) {
        const {id} = this.props;
        const {content, status, form, formSpecific} = this.localstate;
        const value = content[def.key] ? content[def.key] : "";
        if (id && def.onlyNew === true) {
            return null;
        }
        if (def.hideWhen && content && eval(def.hideWhen)) {
            return null;
        }

        if (def.type === "checkbox") {
            return (<div key={def.key}>
                <Field checked={value} style={styles.field}>
                    {def.label && <Label>{def.label}</Label>}
                    <Checkbox
                        checked={value || false}
                        onChange={(e) => this.onChange(def.key, e.target.checked)}
                    > {def.checkText}
                    </Checkbox>
                </Field>
            </div>);
        } else {
            return (
                <div key={def.key}>
                    <Field style={styles.field}>
                        <Label>{def.label}</Label>
                        <Control>
                            {def.type === "number" && <Input type="number" placeholder={def.label} disabled={status.loading} value={value} onChange={(e) => this.onChange(def.key, e.target.value)}/>}
                            {def.type === "text" && <Input placeholder={def.label} disabled={status.loading} value={value} onChange={(e) => this.onChange(def.key, e.target.value)}/>}
                            {def.type === "longtext" && <Textarea placeholder={def.label} disabled={status.loading} value={value} onChange={(e) => this.onChange(def.key, e.target.value)}/>}
                            {(def.type === "multi-select" || def.type === "select") && <Select
                                maxMenuHeight={220}
                                isMulti={def.type === "multi-select"}
                                options={def.options.map(x  => { return {label: x, value: x} })}
                                placeholder={def.label}
                                disabled={status.loading}
                                value={value}
                                onChange={(e) => this.onChange(def.key, e)}
                            />}
                            {(def.type === "multi-select-custom") && <CreatableSelect
                                maxMenuHeight={220}
                                isMulti
                                options={def.options.map(x  => { return {label: x, value: x} })}
                                placeholder={def.label}
                                disabled={status.loading}
                                value={value}
                                onChange={(e) => this.onChange(def.key, e)}
                            />}
                            {(def.key === "name" && form === "templates" && !formSpecific.templates.NameUnique) && <div style={{color: "red"}}>This template name is already being used. Please use another name.</div>}
                        </Control>
                    </Field>
                </div>
            )
        }
    }

    render() {
        const {store, id} = this.props;
        const {status} = this.localstate;
        return <div>
            <Container style={styles.container}>
                <FetchView store={store}>
                    { store.structure.map(def => {
                        return this.renderField(def)
                    })}
                    <Button
                        style={styles.button}
                        loading={status.loading} onClick={this.save.bind(this)} fullwidth className='is-primary'>{id ? 'Update' : 'Create'}</Button>
                </FetchView>
            </Container>
        </div>;
    }
}

const styles = {
    container: {
        borderRadius: 5,
        padding:20,
        background: 'white',
        minWidth: '400px'
    },
    field: {
      marginBottom: '10px'
    },
    button: {
        marginTop: 15
    }
}

export default GenericForm;