import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../../NavigationBar/NavigationBar";
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Container, Modal} from "react-bulma-components";
import {MarketStorageStore, getInventoryByStorage} from "../../../../stores";
import FetchView from "../../../dumb/FetchView/FetchView";
import GenericForm from "../../GenericForm/GenericForm";
import {Input, Table, TableCell, TableHead, TableRow} from "@material-ui/core";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import MarketInventory from "../MarketInventory/MarketInventory";

@observer
class MarketStorage extends Component {
    @observable accessor localstate = {
        edit: null,
        editInventory: null
    }
    inventoryStore  = null;

    onSave() {
        this.localstate.edit = null;
        this.localstate.editInventory = null;
    }

    editMarket(market) {
        this.localstate.edit = market;
    }

    async deleteMarket(market) {
        if (window.confirm('Are you absolutely sure, that you want to delete this market and all its data?')) {
            await MarketStorageStore.deleteItem(market);
            toast.success("Deleted the market '" + market.name + "'")
        }
    }

    editInventory(storage) {
        this.localstate.editInventory = storage;
    }

    render() {
        const {edit, editInventory} = this.localstate;
        return  (<div>
            <NavigationBar title='Storage Units'>
                <Button onClick={() => this.editMarket({})}>Add Storage Unit</Button>
            </NavigationBar>
            <Container style={styles.container}>
                <FetchView store={MarketStorageStore}>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    Name
                                </TableCell>
                                <TableCell colSpan={2}>
                                    City/Storage
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Monthly Fees
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Current Stock Level
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Maximum # of Articles
                                </TableCell>
                                <TableCell colSpan={4}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {MarketStorageStore.list.map((market) => {
                            return (<TableRow>
                                <TableCell colSpan={2}>{market.name}</TableCell>
                                <TableCell colSpan={2}>{market.city}</TableCell>
                                <TableCell colSpan={2}>{market.monthly_fee}€</TableCell>
                                <TableCell colSpan={2}>{market.sku_capacity}</TableCell>
                                <TableCell colSpan={2}>{market.sku_capacity}</TableCell>

                                <TableCell colSpan={4}>
                                    <Button>Add Incoming Order</Button>
                                    <Button onClick={() => this.editInventory(market)}>Adjust Inventory</Button>
                                    <Button onClick={() => this.editMarket(market)}>Edit</Button>
                                    <Button onClick={() => this.deleteMarket(market)}>Delete</Button>
                                </TableCell>
                            </TableRow>);
                        })}
                    </Table>
                </FetchView>
            </Container>
            <Modal show={edit ? true : false} onClose={this.onSave.bind(this)}>
                <GenericForm store={MarketStorageStore} id={edit?.id} onSave={this.onSave.bind(this)}/>
            </Modal>
            <Modal show={editInventory ? true : false} onClose={this.onSave.bind(this)} >
                <MarketInventory storage={editInventory} onSave={this.onSave.bind(this)}/>
            </Modal>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
        padding: 20
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    }
}

export default MarketStorage;