import Store from '../Store';

const localUserData = localStorage.getItem('userData') || '';
const userData = localUserData ? JSON.parse(localUserData) : {};

class SupplierItemsStoreClass extends Store {
    constructor() {
        super();
        this.url = `supplier/itemsbyuser/${userData?.user?.id || ''}`;
    }

    fetchByUserId(userId = '') {
        return new Promise(async (resolve, reject) => {
            try {
                this.url = `supplier/itemsbyuser/${userId}`;
                await this.fetchData(true);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}

const SupplierItemsStore = new SupplierItemsStoreClass();

export default SupplierItemsStore;