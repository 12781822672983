import React, {Component} from 'react';
import {Tabs, Container} from 'react-bulma-components'
import Navigation from "../../../shared/Navigation";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import ProductionOrders from "./ProductionOrders/ProductionOrders";
import PurchaseOrders from "./PurchaseOrders/PurchaseOrders";

const subpages = [{
    "label": "Production Orders",
    "url": "/productionorders",
    "component": <ProductionOrders/>
}, {
    "label": "Purchase Orders",
    "url": "/purchaseorders",
    "component": <PurchaseOrders/>
}]

@observer
class Bottler extends Component {
    @observable accessor localstate = {
        navigate: null
    };

    navigate(item) {
        Navigation.history && Navigation.history.push('/bottler' + item.url);
    }

    render() {
        const url = Navigation.location.pathname;
        let activeItem = null;
        return (<div><Container>
            <Tabs type='toggle' fullwidth={true}>
                {subpages.map(item => {
                    const cleanedUrl= '/bottler' + item.url.replace('*', '');
                    const active = (url === '/bottler' + item.url || (url.indexOf(cleanedUrl)>-1 && url.length>cleanedUrl.length));
                    if (active) {
                        activeItem = item;
                    }
                    return (<Tabs.Tab key={item.url} onClick={() => this.navigate(item)} active={url === '/bottler' + item.url}>
                        {item.label}
                    </Tabs.Tab>)
                })}
            </Tabs>
            <div></div>
        </Container>
            {activeItem && activeItem.component}
        </div>)
    }
}

export default Bottler;
