import {observable} from 'mobx';

class NavigationClass {
    @observable accessor location = '';
    history = '';

    setLocation(location) {
        this.location = location;
    }

    setHistoryObject(history) {
        this.history = history;
    }
}

const Navigation = new NavigationClass();

export default Navigation;