import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Columns, Button, Section, Form} from 'react-bulma-components';
import styles from '../styles';
import translation from '../translations';
const {Label} = Form;

@observer
class Section4 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
    }

    render() {
        const {form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>5. {tl.section5.headline}</h1>
                <div style={styles.note}>{tl.section5.headNote}</div>
                <Columns>
                    <Columns.Column>
                        <h2 style={styles.subHeadline}>{tl.indivPack}</h2>
                        <div style={styles.buttonBox}>
                            <Button
                                color='primary'
                                onClick={() => {
                                    form.recyclingCodes.packType = 'indivPack';
                                    this.localstate.modal.recyclingCodes = true;
                                }}
                            >
                                {tl.section5.buttonText}
                            </Button>
                        </div>
                        <div style={styles.inputBox}>
                            <Label>{tl.section5.selected}:</Label>
                            <p>
                            {
                            form.recyclingCodes.indivPack.length > 0
                                ? form.recyclingCodes.indivPack.map(x => `${x.code}: ${x.input}`).join(', ')
                                : <em>{tl.section5.empty}</em>
                            }
                            </p>
                        </div>
                    </Columns.Column>
                    {
                    form.trayAvailable &&
                        <Columns.Column>
                            <h2 style={styles.subHeadline}>{tl.outerPack}</h2>
                            <div style={styles.buttonBox}>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        form.recyclingCodes.packType = 'outerPack';
                                        this.localstate.modal.recyclingCodes = true;
                                    }}
                                >
                                    {tl.section5.buttonText}
                                </Button>
                            </div>
                            <div style={styles.inputBox}>
                                <Label>{tl.section5.selected}:</Label>
                                <p>
                                {
                                form.recyclingCodes.outerPack.length > 0
                                    ? form.recyclingCodes.outerPack.map(x => `${x.code}: ${x.input}`).join(', ')
                                    : <em>{tl.section5.empty}</em>
                                }
                                </p>
                            </div>
                        </Columns.Column>
                    }
                </Columns>
            </Section>
        );
    }
}

export default Section4;