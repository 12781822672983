import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Columns, Section, Form} from 'react-bulma-components';
import styles from '../styles';
import translation from '../translations';
const {Input} = Form;

@observer
class Section2 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.handleInput = props.parent.handleInput.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {loading, form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>2. {tl.section2.headline}</h1>
                <div style={styles.note}>{tl.section2.headNote}</div>
                <Columns>
                    <Columns.Column>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{tl.width} (mm)</TableCell>
                                    <TableCell>{tl.height} (mm)</TableCell>
                                    <TableCell>{tl.length} (mm)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{tl.indivPack}</TableCell>
                                    <TableCell>
                                        <Input
                                            className={this.error(form.dimensions.indiv.width)}
                                            type='number'
                                            placeholder={tl.width}
                                            disabled={loading}
                                            value={form.dimensions.indiv.width}
                                            onChange={e => this.handleInput(form.dimensions.indiv, 'width', e)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Input
                                            className={this.error(form.dimensions.indiv.height)}
                                            type='number'
                                            placeholder={tl.height}
                                            disabled={loading}
                                            value={form.dimensions.indiv.height}
                                            onChange={e => this.handleInput(form.dimensions.indiv, 'height', e)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Input
                                            className={this.error(form.dimensions.indiv.length)}
                                            type='number'
                                            placeholder={tl.length}
                                            disabled={loading}
                                            value={form.dimensions.indiv.length}
                                            onChange={e => this.handleInput(form.dimensions.indiv, 'length', e)}
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                form.trayAvailable &&
                                    <TableRow>
                                        <TableCell>{tl.outerPack}</TableCell>
                                        <TableCell>
                                            <Input
                                                className={this.error(form.dimensions.outer.width)}
                                                type='number'
                                                placeholder={tl.width}
                                                disabled={loading}
                                                value={form.dimensions.outer.width}
                                                onChange={e => this.handleInput(form.dimensions.outer, 'width', e)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                className={this.error(form.dimensions.outer.height)}
                                                type='number'
                                                placeholder={tl.height}
                                                disabled={loading}
                                                value={form.dimensions.outer.height}
                                                onChange={e => this.handleInput(form.dimensions.outer, 'height', e)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                className={this.error(form.dimensions.outer.length)}
                                                type='number'
                                                placeholder={tl.length}
                                                disabled={loading}
                                                value={form.dimensions.outer.length}
                                                onChange={e => this.handleInput(form.dimensions.outer, 'length', e)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Columns.Column>
                </Columns>
            </Section>
        );
    }
}

export default Section2;