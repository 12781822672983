import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Container, Columns, Heading, Button, Form, Loader } from "react-bulma-components";
import { FaChevronRight } from "react-icons/fa";
import FileUploadList from "../../FileUploadList";
import RestClient from "shared/Network/RestClient";
import { toast } from "react-toastify";
import Navigation from "shared/Navigation";
import TimeSelector from "./TimeSelector";
import { ChallengesStore } from "stores/HRChallenges/ChallengesStore";
import { IconWithText, BackButton } from "components/dumb";
const {Input, Textarea} = Form;

@observer
class ChallengeEditor extends Component {
  @observable accessor localstate = {
    meta: {
      time: { label: "30 Minuten", value: 30 },
      title: "",
      description: "",
    },
    files: [],
    loading: true,
  };

  componentDidMount() {
    this.fetchChallenge();
  }

  handleTimeSelect(time) {
    this.localstate.meta.time = time;
  }

  async fetchChallenge() {
    const challengeId = this.props.id;

    if (!challengeId) {
      this.localstate.loading = false;
      return;
    }

    this.fetchFiles();

    const request = RestClient.prepareRequest(
        "GET",
        "challenges/" + challengeId
    );
    try {
      const response = await RestClient.sendRequest(request);

      if (response.data) {
        const { title, description, timeLimit } = response.data;

        this.localstate.meta = {
          title,
          description,
          time: {
            label: `${timeLimit / 60 / 1000} Minuten`,
            value: timeLimit / 60 / 1000,
          },
        };
      }

      this.localstate.loading = false;
    } catch (error) {
      toast.error("Etwas ist beim Laden der Challenge schiefgelaufen.");
    }
  }

  async fetchFiles() {
    const request = RestClient.prepareRequest(
        "GET",
        "challenge-files?challengeId=" + this.props.id
    );
    try {
      const response = await RestClient.sendRequest(request);

      if (response.data) {
        this.localstate.files =
            response.data.map((file) => ({
              ...file,
              uploaded: true,
            })) || [];
      }
    } catch (e) {
      console.log(e);
      toast.error("Dateien konnten nicht geladen werden.");
    }
  }

  async onFileChange(e) {
    const newFiles = e.target.files || [];

    this.localstate.files = [...this.localstate.files, ...newFiles];

    e.target.value = null;
  }

  async removeFile(fileToDelete) {
    if (
        !window.confirm(
            "Möchtest du die Datei " +
            fileToDelete.name +
            " wirklich endgültig löschen?"
        )
    )
      return;

    this.localstate.files = this.localstate.files.filter(
        (file) => file.name !== fileToDelete.name
    );

    if (!fileToDelete.uploaded) return;

    // Delete file from database
    const request = RestClient.prepareRequest(
        "DELETE",
        "challenge-files/" + fileToDelete.id
    );
    try {
      const response = await RestClient.sendRequest(request);

      if (response.success) {
        return toast("Datei erfolgreich gelöscht!");
      }

      throw new Error(response.err);
    } catch (error) {
      toast.error(
          "Datei konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.\n" +
          error.message
      );
    }
  }

  handleMetaChange(value, key) {
    this.localstate.meta[key] = value;
  }

  async handleSave(redirectToInvite = false) {
    const challengeId = this.props.id;

    const requestParams = challengeId
        ? ["PUT", `challenges/${challengeId}`]
        : ["POST", "challenges"];
    const request = RestClient.prepareRequest(...requestParams);
    const data = new FormData();

    const { meta, files } = this.localstate;

    for (const file of files) {
      if (!file.uploaded) {
        data.append("files", file);
      }
    }
    data.append("meta", JSON.stringify(meta));

    request.setData(data);
    try {
      const response = await RestClient.sendRequest(request);
      toast("Challenge gespeichert!");

      ChallengesStore.fetchData(true);

      if (!challengeId && response.data.id && !redirectToInvite) {
        Navigation.history.replace({
          pathname: `/hrchallenges/challenge/${response.data.id}`,
        });
      }

      redirectToInvite && Navigation.history.push("/hrchallenges/invite");
    } catch (error) {
      toast.error(
          "Etwas ist beim Speichern schiefgelaufen. Bitte versuchen Sie es erneut."
      );
    }
  }

  async deleteChallenge() {
    if (!window.confirm("Bist du sicher, dass du diese Challenge löschen möchtest?"))
      return;

    const request = RestClient.prepareRequest(
        "DELETE",
        "challenges/" + this.props.id
    );

    try {
      await RestClient.sendRequest(request);

      toast("Challenge gelöscht!");
      await ChallengesStore.fetchData(true);
      Navigation.history.push("/hrchallenges/challenges");
    } catch (error) {
      toast.error(
          "Etwas ist beim löschen der Challenge schiefgelaufen. Bitte versuchen Sie es erneut."
      );
    }
  }

  render() {
    if (this.localstate.loading) {
      return (
          <div style={styles.loadingContainer}>
            <Loader style={styles.loader} />
          </div>
      );
    }

    return (
        <Container>
          <BackButton text="Zurück zum Dashboard" to="/hrchallenges" />
          <Columns>
            <Columns.Column>
              <Heading style={styles.section}>Details</Heading>
              <Input
                  style={styles.section}
                  placeholder="Position"
                  onChange={(e) => this.handleMetaChange(e.target.value, "title")}
                  value={this.localstate.meta.title}
              />
              <div style={styles.section}>
                <TimeSelector
                    onTimeSelect={(e) => this.handleTimeSelect(e)}
                    time={this.localstate.meta.time}
                />
              </div>
              <Textarea
                  placeholder="Beschreibung"
                  onChange={(e) =>
                      this.handleMetaChange(e.target.value, "description")
                  }
                  value={this.localstate.meta.description}
              />
              <Button style={styles.button} onClick={() => this.handleSave()}>
                Speichern
              </Button>
              {this.props.id ? (
                  <Button
                      style={styles.button}
                      onClick={() => this.deleteChallenge()}
                  >
                    Löschen
                  </Button>
              ) : (
                  ""
              )}
              <Button onClick={() => this.handleSave(true)} style={styles.button}>
                <IconWithText
                    icon={<FaChevronRight />}
                    text="Speichern und einladen"
                />
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Heading>Dateien</Heading>
              <FileUploadList
                  onFileSelect={(e) => this.onFileChange(e)}
                  onFileRemove={(file) => this.removeFile(file)}
                  files={this.localstate.files}
              />
            </Columns.Column>
          </Columns>
        </Container>
    );
  }
}

const styles = {
  section: {
    marginBottom: "1rem",
  },
  loadingContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    width: 150,
    height: 150,
  },
  button: {
    margin: "0.5rem 1rem 0.5rem 0",
  },
};

export default ChallengeEditor;