import React, {Component} from 'react';
import {Section, Button, Columns, Container} from 'react-bulma-components'
import {observer} from 'mobx-react';
import {observable} from 'mobx';

@observer
class Stepper extends Component {
    @observable accessor localstate = {
        status: {
            step: 0,
            component: null
        }
    };

    context = {};

    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);
        this.context = {...props.context} || {};
        this.updateComponent(0);
    }

    nextStep(data = {}) {
        this.context = {...this.context, ...data};
        this.updateComponent(this.localstate.status.step+1);
    }

    previousStep(data = {}) {
        this.context = {...this.context, ...data};
        this.updateComponent(this.localstate.status.step-1);
    }

    goTo(step, data = {}) {
        const {allowJumps} = this.props;
        if (!allowJumps) return;
        this.context = {...this.context, ...data};
        this.updateComponent(step);
    }

    updateComponent(step) {
        const newContext = {...this.context};
        console.log(newContext);
        const {steps, save} = this.props;
        this.localstate.status.step = step;
        this.localstate.status.component = React.createElement(steps[step].component, {
            next: this.nextStep.bind(this),
            previous: this.previousStep.bind(this),
            goTo: this.goTo.bind(this),
            context: newContext,
            renderButtons: this.renderDefaultButtons.bind(this),
            save: save
        })
    }

    renderDefaultButtons(next = this.nextStep.bind(this), previous = this.previousStep.bind(this)) {
        const {status} = this.localstate;
        const {step} = status;
        const {steps} = this.props;
        return (<div style={styles.buttons}>
            <Button disabled={ step === 0 } onClick={previous}>Zurück</Button>
            { step < steps.length -1 && <Button color='primary' onClick={next}>Weiter</Button> }
            { step === steps.length -1 && <Button color='primary'>Fertigstellen</Button> }
        </div>);
    }

    render() {

        const {status} = this.localstate;
        const {step, component} = status;
        const {steps, useDefaultButtons = false} = this.props;

        return (<Container>
            <Section>
                <Columns>
                    <Columns.Column size={4}>
                        { steps.map(item => {
                            return  <div key={steps.indexOf(item)} style={styles.vertialSelection} onClick={()=>this.goTo(steps.indexOf(item))}>
                                <div style={styles.icon}>{item.icon}</div>
                                {item.title} {steps.indexOf(item) === step && <div style={styles.dot} /> }
                            </div>
                        })}
                    </Columns.Column>
                    <Columns.Column size={8} >
                        {component}
                        {useDefaultButtons && this.renderDefaultButtons()}
                    </Columns.Column>
                </Columns>
            </Section>
        </Container>)
    }
}

const styles = {
    vertialSelection: {
        display:'flex',
        padding: 10,
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #eee'
    },
    dot: {
        height: 10,
        width: 10,
        backgroundColor: 'blue',
        display: 'flex',
        marginLeft: 10,
        borderRadius: 100

    },
    buttons: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        display: 'flex',
        paddingRight: 10,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default Stepper;