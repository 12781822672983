import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Checkbox} from '@material-ui/core';
import {Section, Form} from 'react-bulma-components';
import Select from 'react-select';
import styles from '../styles';
import translation from '../translations';

const {Input, Label} = Form;

@observer
class SectionIntern extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.setOutdatedValue = props.parent.setOutdatedValue.bind(this);
    }

    onOutdatedSkusSelect(selectedSkus) {
        this.localstate.form.outdatedSkus = selectedSkus || [];
        this.setOutdatedValue();
    }

    render() {
        const {loading, form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>{tl.sectionIntern.headline}</h1>
                <div style={{ ...styles.info, marginBottom: 20, ...(!form.checked && { backgroundColor: 'rgba(255, 224, 138, 0.2)', border: '1px solid #ffe08a' }) }}>
                    <div style={{ ...styles.switchBox, width: '100%', margin: 0 }}>
                        <Checkbox
                            disabled={loading}
                            checked={form.checked}
                            onChange={e => form.checked = e.target.checked}
                        />
                        <h2 style={styles.switchBoxText}>{tl.sectionIntern.checkboxIsChecked}</h2>
                    </div>
                </div>
                <div style={styles.inputBox}>
                    <Label>{tl.sectionIntern.outdatedSkus}</Label>
                    <Select
                        isMulti
                        placeholder={`${tl.select}...`}
                        isDisabled={loading}
                        options={form.skus}
                        value={form.outdatedSkus}
                        onChange={this.onOutdatedSkusSelect.bind(this)}
                    />
                </div>
                <div style={styles.inputBox}>
                    <Label>{tl.sectionIntern.comment}</Label>
                    <Input
                        type='text'
                        placeholder={tl.sectionIntern.comment}
                        disabled={loading}
                        value={form.comment || ''}
                        onChange={e => form.comment = e.target.value || null}
                    />
                </div>
            </Section>
        );
    }
}

export default SectionIntern;