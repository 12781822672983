import {Link} from "react-router-dom";
import Authentication from "../../../shared/Authentication";
import React, {Component} from "react";
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import Navigation from "../../../shared/Navigation";
import {observer} from 'mobx-react';
import { Navbar} from 'react-bulma-components'
import {observable} from 'mobx';
import logo from '../../../assets/images/logo--tablet.png';
const tabs = require('../../../config/tabs');

@observer
class Header extends Component {
    @observable accessor localstate = {
        menuVisible: false,
        tabsRestructured: []
    };

    constructor(props) {
        super(props);
        for (const tab of tabs) {
            for (const group of tab.group) {
                const index =  this.localstate.tabsRestructured.findIndex(x => x[0].group === group);
                if (index >= 0) {
                    this.localstate.tabsRestructured[index].push({...tab, group});
                } else {
                    this.localstate.tabsRestructured.push([{...tab, group}]);
                }
            }
        }
    }

    switchMenu() {
        this.localstate.menuVisible = !this.localstate.menuVisible;
    }

    hideModal() {
        this.localstate.menuVisible=false;
    }

    render() {
        const url = Navigation.location.pathname;
        const activeStyle = {...styles.menuItem, ...styles.active};
        const {menuVisible, tabsRestructured} = this.localstate;

        return (
            <Navbar active={menuVisible}>
            <Navbar.Brand>
                <Navbar.Item renderAs="a" href="#"  onClick={this.hideModal.bind(this)}>
                    <img style={styles.logo} src={logo} alt="Logo" width="44" height="40" />
                </Navbar.Item>
                <Navbar.Burger onClick={this.switchMenu.bind(this)} />
            </Navbar.Brand>
            <Navbar.Menu>
                <Navbar.Container>
                {
                    tabsRestructured.map((tabs, i) => {
                        const entry = {...tabs[0], group: [tabs[0].group]};

                        if (Authentication.hasAccess(entry)) {
                            return (
                                <Navbar.Item hoverable href="#" key={i}>
                                    <Navbar.Link>{tabs[0].group}</Navbar.Link>
                                    <Navbar.Dropdown>
                                    {
                                        tabs.map((item, i) => {
                                            const active = url && (url.startsWith(item.url));
                                            return (
                                                <Navbar.Item href={"#" + item.url} onClick={this.hideModal.bind(this)} key={i}>
                                                    <div style={active ? activeStyle : styles.menuItem}>{item.label}</div>
                                                </Navbar.Item>
                                            );
                                        })
                                    }
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                            );
                        } else {
                            return null;
                        }
                    })
                }
                </Navbar.Container>
                <Navbar.Container align="right">
                {
                    Authentication.user.canAddUsers && 
                        <Navbar.Item href="#/settings/users" onClick={this.hideModal.bind(this)}>
                            <FaCog color="black" size={22}/>
                        </Navbar.Item>
                }
                    <Navbar.Item onClick={() => Authentication.logout()}>
                        { Authentication.localstatus.loggedIn && <FaSignOutAlt color="black" size={22}>Logout</FaSignOutAlt> }
                    </Navbar.Item>
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>)

    }
}

const styles = {
    brandHeader: {
        height: 300,
        overflow: 'hidden',
        backgroundImage: 'url("https://koro.imgix.net/media/image/58/f1/f1/Mango-Bowl-2.jpg?auto=compress,format&w=1280&h=1280&dpr=2")',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    active: {
        borderRadius: 50,
        color: 'black',
        background: '#eee',
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
    },
    menuItem: {
        padding: 10,
        color: '#666',
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        borderRadius: 50,
    },
    logo: {
        height: '34px',
        width: '37px',
        maxHeight: 50,
    },
    headerLeft: {
        display: 'flex',
    },
    headerRight: {
        display: 'flex',
    },
    headerIcon: {
        cursor: 'pointer',
    },
    headerMenuLink: {
        color: 'white',
    },
    header: {
        backgroundColor: 'darkblue',
        display: 'flex',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        color: 'white',
        position: 'fixed',
        width: '100%',
        left: 0,
        zIndex: 10,

    },
    headContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}


export default Header;