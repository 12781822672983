import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Columns, Form} from 'react-bulma-components';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from '../styles';
import translation from '../translations';
import '../index.css';
const {Input, Label} = Form;

@observer
class OtherPackaging extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.handleNestedSelect = props.parent.handleNestedSelect.bind(this);
        this.createNewOption = props.parent.createNewOption.bind(this);
        this.handleInput = props.parent.handleInput.bind(this);
        this.translateLabel = props.parent.translateLabel.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {options, form, language} = this.localstate;
        const tl = translation[language.value];

        const showRecycleNote =
            form.otherPack.indiv.materials.map(x => x.materialTypes.length > 0 || !options.otherPack.materials.find(item => item.value === x.value).materialTypes).includes(true) || 
            form.otherPack.outer.materials.map(x => x.materialTypes.length > 0 || !options.otherPack.materials.find(item => item.value === x.value).materialTypes).includes(true);

        return (<>
            <h1 style={styles.headline}>3.2 {tl.section3.other}</h1>
            <Columns>
                <Columns.Column>
                    <h2 style={styles.subHeadline}>{tl.indivPack}</h2>
                    <SelectLogic packType='indiv' parent={this}/>
                </Columns.Column>
                <Columns.Column>
                {
                form.trayAvailable &&
                    <>
                        <h2 style={styles.subHeadline}>{tl.outerPack}</h2>
                        <SelectLogic packType='outer' parent={this}/>
                    </>
                }
                </Columns.Column>
            </Columns>
            {
                showRecycleNote && <div style={styles.note}>¹ {tl.section3.note1}</div>
            }
        </>);
    }
}

@observer
class SelectLogic extends Component {
    constructor(props) {
        super(props);
        this.packType = props.packType;
        this.localstate = props.parent.localstate;
        this.handleNestedSelect = props.parent.handleNestedSelect.bind(this);
        this.createNewOption = props.parent.createNewOption.bind(this);
        this.handleInput = props.parent.handleInput.bind(this);
        this.translateLabel = props.parent.translateLabel.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {loading, options, form, language} = this.localstate;
        const tl = translation[language.value];
        const materials = form.otherPack[this.packType].materials;

        return (<>
            <div style={styles.inputBox}>
                <Label>{tl.material}</Label>
                <Select
                    className={this.error(materials, !form.isPlastic)}
                    isMulti
                    placeholder={tl.select}
                    isDisabled={loading}
                    options={options.otherPack.materials}
                    value={[...this.translateLabel(materials)]}
                    onChange={selected => this.handleNestedSelect(materials, this.translateLabel(selected, 'en'), { materialTypes: [], input: '', recycledContent: '' })}
                />
            </div>
            {
                materials.map((material, i) => {
                    const optionItem = options.otherPack.materials?.find(item => item.value === material.value);
                    
                    return (
                        <Columns key={i} style={{ ...styles.partingTop, paddingBottom: 10, marginBottom: 20 }}>
                            <Columns.Column>
                            {
                            optionItem.materialTypes ?
                                <div style={styles.inputBox}>
                                    <Label>{tl.typeOf} {tl.section3[material.value] || material.label}</Label>
                                    {
                                    optionItem?.creatable ?
                                        <>
                                            <CreatableSelect
                                                className={this.error(material.materialTypes)}
                                                isMulti
                                                placeholder={tl.selectAdd}
                                                formatCreateLabel={input => `${tl.createNewOption} „${input}“`}
                                                options={optionItem.materialTypes}
                                                isDisabled={loading}
                                                value={[...this.translateLabel(material.materialTypes)]}
                                                onChange={selected => this.handleNestedSelect(material.materialTypes, this.translateLabel(selected, 'en'), { input: '', recycledContent: '', ...(optionItem.specifyName && { name: '' }) })}
                                                onCreateOption={created => this.createNewOption(material.materialTypes, created, { input: '', recycledContent: '', ...(optionItem.specifyName && { name: '' }) })}
                                            />
                                            <div style={styles.note}>{tl.section3.note2}</div>
                                        </>
                                        : 
                                        <Select
                                            className={this.error(material.materialTypes)}
                                            isMulti
                                            placeholder={tl.select}
                                            options={optionItem.materialTypes}
                                            isDisabled={loading}
                                            value={[...this.translateLabel(material.materialTypes)]}
                                            onChange={selected => this.handleNestedSelect(material.materialTypes, this.translateLabel(selected, 'en'), { input: '', recycledContent: '', ...(optionItem.specifyName && { name: '' }) })}
                                        />
                                    }
                                </div>
                                :
                                <Columns>
                                    <Columns.Column>
                                        <div style={styles.inputBox}>
                                            <Label>{tl.section3[material.value] || material.label} (in g)</Label>
                                            <Input
                                                className={this.error(material.input)}
                                                type='number'
                                                placeholder='0.00'
                                                disabled={loading}
                                                value={material.input}
                                                onChange={e => this.handleInput(material, 'input', e)}
                                            />
                                        </div>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <div style={styles.inputBox}>
                                            <Label>{tl.recycledContent}¹ (in %)</Label>
                                            <Input
                                                className={this.error(material.recycledContent)}
                                                type='number'
                                                placeholder='0.00'
                                                disabled={loading}
                                                value={material.recycledContent}
                                                onChange={e => this.handleInput(material, 'recycledContent', e)}
                                            />
                                        </div>
                                    </Columns.Column>
                                </Columns>
                            }
                                {
                                    material.materialTypes.map((materialType, j) => (
                                        <Columns key={j} style={{ alignItems: 'flex-end', marginTop: 10 }}>
                                            {
                                            optionItem.specifyName &&
                                                <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                    <Label>{tl.name} / {tl.typeOf} {tl.section3[materialType.value] || materialType.label}</Label>
                                                    <Input
                                                        className={this.error(materialType.name)}
                                                        type='text'
                                                        placeholder={`${tl.typeOf} ${materialType.label}`}
                                                        disabled={loading}
                                                        value={materialType.name}
                                                        onChange={e => this.handleInput(materialType, 'name', e)}
                                                    />
                                                </Columns.Column>
                                            }
                                            <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                <Label>
                                                {
                                                    optionItem.specifyName && materialType.name?.length > 0
                                                        ? `${materialType.name} `
                                                        : `${tl.section3[material.value] || material.label} - ${tl.section3[materialType.value] || materialType.label} `
                                                }
                                                    (in g)
                                                </Label>
                                                <Input
                                                    className={this.error(materialType.input)}
                                                    type='number'
                                                    placeholder='0.00'
                                                    disabled={loading}
                                                    value={materialType.input}
                                                    onChange={e => this.handleInput(materialType, 'input', e)}
                                                />
                                            </Columns.Column>
                                            <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                <Label>{tl.recycledContent}¹ (in %)</Label>
                                                <Input
                                                    className={this.error(materialType.recycledContent)}
                                                    type='number'
                                                    placeholder='0.00'
                                                    disabled={loading}
                                                    value={materialType.recycledContent}
                                                    onChange={e => this.handleInput(materialType, 'recycledContent', e)}
                                                />
                                            </Columns.Column>
                                        </Columns>
                                    ))
                                }
                            </Columns.Column>
                        </Columns>
                    );
                })
            }
        </>);
    }
}

export default OtherPackaging;