import React, { Component } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

class BackButton extends Component {
  render() {
    return this.props.withoutLink ? (
      <div
        style={styles.wrapper}
        onClick={(e) => this.props.onClick(e)}
        className="back-button"
      >
        <FaChevronLeft style={styles.chevron} />
        <span>{this.props.text}</span>
      </div>
    ) : (
      <Link style={styles.wrapper} to={this.props.to} className="back-button">
        <FaChevronLeft style={styles.chevron} />
        <span>{this.props.text}</span>
      </Link>
    );
  }
}

const styles = {
  wrapper: {
    color: "#363636",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "1.5rem 0 3rem 0",
  },
  chevron: {
    marginRight: "0.25rem",
  },
};

export {BackButton};
