const { REACT_APP_BACKEND_NAME } = process.env;

function getBackendApiUrl() {
  const hostName = window.location.hostname;

  if (hostName.includes('live') || hostName === 'toolbox.korodrogerie.de') {
    return 'https://toolbox-backend-live.herokuapp.com/';
  } else if (REACT_APP_BACKEND_NAME) {
    return REACT_APP_BACKEND_NAME;
  } else {
    return 'http://localhost:3003/';
  }
}

const apiConfig = {
  API: {
    ENDPOINT: getBackendApiUrl(),
  },
};

export default apiConfig;
