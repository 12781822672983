import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import FetchView from '../../dumb/FetchView/FetchView';
import {Table, TableCell, TableHead, TableBody, TableRow} from '@material-ui/core';
import {Container, Form} from 'react-bulma-components';
import NavigationBar from '../NavigationBar/NavigationBar';
import moment from 'moment';
import B2BOrderStore from 'stores/B2BOrders/B2BOrderStore';
const {Input} = Form;

@observer
class B2BOrderList extends Component {
    @observable accessor localstate = {
        search: '',
    };

    onChange(k, v) {
        this.localstate[k] = v;
    }

    filterList(order) {
        let {search} = this.localstate;
        if (!search) return true;

        search = search.toLowerCase();
        const {createdAt, ordernumber, userId, referenceNumber, internalNote} = order;
        
        if (
            (createdAt && createdAt.toLowerCase().includes(search)) ||
            (ordernumber && ordernumber.toLowerCase().includes(search)) ||
            (userId && userId.toString().includes(search)) ||
            (referenceNumber && referenceNumber.toLowerCase().includes(search)) ||
            (internalNote && internalNote.toLowerCase().includes(search))
        ) return true;

        return false;
    }

    sortList(a, b) {
        const key = 'createdAt';
        const prev = new Date(a[key]);
        const next = new Date(b[key]);
        return prev === next ? 0 : prev > next ? -1 : 1;
    }

    render() {
        const {search} = this.localstate;

        return (<>
            <NavigationBar title='B2B Orders'></NavigationBar>
            <Container style={{ marginTop: 20, marginBottom: 20 }}>
                <FetchView stores={[B2BOrderStore]}>
                    <Container style={{ overflowX: 'scroll' }}>
                        <Input
                            onChange={(e) => this.onChange('search', e.target.value)}
                            value={search}
                            placeholder='Search...'
                        />
                        <Table aria-label='simple table' stickyHeader style={{ marginTop: '15px' }} >
                            <TableHead >
                                <TableRow>
                                    <TableCell>Datum</TableCell>
                                    <TableCell>Order Nr.</TableCell>
                                    <TableCell>Customer Nr.</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Reference Number</TableCell>
                                    <TableCell>Internal Comment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            B2BOrderStore &&
                                B2BOrderStore.list
                                    .filter(this.filterList.bind(this))
                                    .sort(this.sortList.bind(this))
                                    .map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{moment(item.createdAt).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell>{item.ordernumber}</TableCell>
                                            <TableCell>{item.userId}</TableCell>
                                            <TableCell>{Number(item.total).toFixed(2)} €</TableCell>
                                            <TableCell>{item.referenceNumber}</TableCell>
                                            <TableCell>{item.internalNote}</TableCell>
                                        </TableRow>
                                    ))
                            }
                            </TableBody>
                        </Table>
                    </Container>
                </FetchView>
            </Container>
        </>);
    }

}

export default B2BOrderList;