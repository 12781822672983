import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../../NavigationBar/NavigationBar";
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Container, Modal, Heading, Form} from "react-bulma-components";
import FetchView from "../../../dumb/FetchView/FetchView";
import RestClient from "../../../../shared/Network/RestClient";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {ProductionOrderStore} from '../../../../stores/Bottler/ProductionOrderStore';
import moment from 'moment';
import {toast} from "react-toastify";
const {Control, Field, Input, Label} = Form;

@observer
class ProductionOrders extends Component {
    @observable accessor localstate = {
        details: false,
        producedQtyData: false,
        producedBatchData: false,
        journalLines: [],
        confirm: false,
        loading: false,
        formData: {
            producedQty: {
                quantity: '',
                scrapQuantity: ''
            },
            producedBatch: {
                qty: '',
                lotNo: '',
                expirationDate: ''
            }
        }
    }

    onDetails(item) {
        this.localstate.details = item;
    }

    onDetailsClose() {
        this.localstate.details = false;
    }

    onProducedQtyClose() {
        this.localstate.producedQtyData = false;
    }

    onProducedBatchClose() {
        this.localstate.producedBatchData = false;
    }

    onConfirmClose() {
        this.localstate.confirm = false;
    }

    onFormChange(name, key, value) {
        this.localstate.formData[name][key] = value.replace(/\s{2,}/g,' ');
    }

    async createProductionJournal(lineItem) {
        this.localstate.loading = true;

        const requestBody = {
            prodOrderLineID: lineItem.id
        }

        const request = RestClient.prepareRequest('POST', `production/createproductionjournal`);
        request.setData(requestBody);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                await this.getProductionJournalLines(lineItem);
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    async getProductionJournalLines(lineItem) {
        const request = RestClient.prepareRequest('GET', `production/productionJournalLines?lineNo=${lineItem.lineNo}&documentNo=${lineItem.prodOrderNo}`);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {

                if (res.data.value.length == 0) throw 'This journal line does not contain any line items.';

                this.localstate.loading = false;
                this.localstate.journalLines = res.data.value;
                this.localstate.producedQtyData = { lineItem, journalLineItem: res.data.value[0] };
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    async producedQtySubmit(data) {
        const form = this.localstate.formData.producedQty;
        const { journalLineItem } = data;
        let {quantity, scrapQuantity} = form;

        let requestBody = {
            prodJounalLineID: journalLineItem.systemId
        }

        if (journalLineItem.entryType === 'Consumption') {
            if (quantity.length == 0 || quantity.match(/\D/)) { toast.error('Please enter a valid quantity.'); return }
            quantity = Number(quantity);
            requestBody = {
                prodJounalLineID: journalLineItem.systemId,
                quantity
            }
        } else {
            if (scrapQuantity.length == 0 || scrapQuantity.match(/\D/)) { toast.error('Please enter a valid quantity.'); return }
            scrapQuantity = Number(scrapQuantity);
            requestBody = {
                prodJounalLineID: journalLineItem.systemId,
                scrapQuantity
            }
        }

        this.localstate.loading = true;

        const request = RestClient.prepareRequest('POST', `production/setproductionjournallinequantity`);
        request.setData(requestBody);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                toast.success('Quantities successfully set');
                this.localstate.loading = false;
                this.localstate.producedQtyData = false;
                this.localstate.formData.producedQty = {
                    quantity: '',
                    scrapQuantity: ''
                }
                this.localstate.producedBatchData = data;
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    async producedBatchSubmit(data) {
        const form = this.localstate.formData.producedBatch;
        const { lineItem, journalLineItem } = data;
        const {qty, lotNo, expirationDate} = form;

        let requestBody = {};

        if (journalLineItem.entryType === 'Consumption') {
            if (qty.length === 0 || qty.match(/\D/)) { toast.error('Please enter a valid quantity.'); return }
            requestBody = {
                itemNo: journalLineItem.itemNo,
                sourceRefNo: journalLineItem.lineNo,
                qty: typeof qty === 'string' ? Number(qty) : qty,
                lotNo: lotNo || '',
                journalBatchName: journalLineItem.journalBatchName,
                journalTemplateName: journalLineItem.journalTemplateName,
                locationCode: journalLineItem.locationCode
            }
        } else {
            if (!lotNo) { toast.error('Please enter a lot number.'); return }
            if (qty.length === 0 || qty.match(/\D/)) { toast.error('Please enter a valid quantity.'); return }
            if (!expirationDate.match(/^\d{4}\-\d{2}\-\d{2}$/)) { toast.error('Please enter a expiration date. (YYYY-MM-DD)'); return }
            if (!moment(expirationDate).isValid()) { toast.error('Please enter a valid expiration date. (YYYY-MM-DD)'); return }
            requestBody = {
                itemNo: journalLineItem.itemNo,
                sourceProdOrderline: lineItem.lineNo,
                qty: typeof qty === 'string' ? Number(qty) : qty,
                lotNo,
                expirationDate,
                locationCode: 'PIXI'
            }
        }

        this.localstate.loading = true;

        const request = RestClient.prepareRequest('POST', `production/addreservationentry/${lineItem.prodOrderNo}`);
        request.setData(requestBody);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                toast.success('Produced batch successfully set');
                this.localstate.loading = false;
                this.localstate.producedBatchData = false;
                this.localstate.formData.producedBatch = {
                    qty: '',
                    lotNo: '',
                    expirationDate: ''
                }
                this.localstate.confirm = data;
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    confirmSubmit(addAnother, data) {
        const { lineItem, journalLineItem } = data;
        const itemIndex = this.localstate.journalLines.map(x => x.itemNo).indexOf(journalLineItem.itemNo);

        if (addAnother) {
            this.localstate.confirm = false;
            this.localstate.producedBatchData = data;
        } else {
            if (itemIndex < this.localstate.journalLines.length - 1) {
                this.localstate.confirm = false;
                this.localstate.producedQtyData = { lineItem, journalLineItem: this.localstate.journalLines[itemIndex + 1] };
            } else {
                this.completeJournalLines(data);
            }
        }
    }

    async completeJournalLines(data) {
        const { lineItem } = data;

        this.localstate.loading = true;

        const requestBody = {
            prodOrderLineID: lineItem.id,
        }

        const request = RestClient.prepareRequest('POST', `production/postProductionJournalLines`);
        request.setData(requestBody);

        try {
            const res = await request.send();
            if (res.success && 'data' in res) {
                toast.success('Production order line completed successfully');
                this.localstate.loading = false;
                this.localstate.confirm = false;
            } else {
                throw res.error?.message || '';
            }
        } catch (error) {
            this.localstate.loading = false;
            toast.error('An error occurred! ' + error);
        }
    }

    render() {
        const {details, producedQtyData, producedBatchData, confirm, formData, loading, journalLines} = this.localstate;
        return  (<div>
            <NavigationBar title='My production orders'></NavigationBar>

            <Container style={styles.container}>
                <FetchView store={ProductionOrderStore}>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader className='table-hover'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Number</TableCell>
                                <TableCell colSpan={2}>Routing Number</TableCell>
                                <TableCell colSpan={3}>Name</TableCell>
                                <TableCell colSpan={2}>Quantity</TableCell>
                                <TableCell colSpan={2}>Status</TableCell>
                                <TableCell colSpan={2}>Start Date</TableCell>
                                <TableCell colSpan={2}>End Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            ProductionOrderStore.list?.value && ProductionOrderStore.list.value.filter(x => x.status === 'Released' && x.productionOrderLines.reduce((a, b) => a + b.finishedQuantity) < x.quantity).map((item, i) => {
                                return (
                                <TableRow key={i} style={styles.tableRow} onClick={() => this.onDetails(item)}>
                                    <TableCell colSpan={2}>{item.no}</TableCell>
                                    <TableCell colSpan={2}>{item.routingNo}</TableCell>
                                    <TableCell colSpan={3}>{item.description}</TableCell>
                                    <TableCell colSpan={2}>{item.quantity}</TableCell>
                                    <TableCell colSpan={2}>{item.status}</TableCell>
                                    <TableCell colSpan={2}>{moment(item.startingDateTime).format('DD/MM/YYYY LT')}</TableCell>
                                    <TableCell colSpan={2}>{moment(item.endingDateTime).format('DD/MM/YYYY LT')}</TableCell>
                                </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </FetchView>
            </Container>

            <Modal show={details} onClose={this.onDetailsClose.bind(this)}>
                <Container style={styles.overlay}>
                <NavigationBar title={details ? 'Details for ' + details.no : 'Details'} style={styles.overlay}></NavigationBar>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader className='table-hover'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Number</TableCell>
                                <TableCell colSpan={4}>Name</TableCell>
                                <TableCell colSpan={2}>Quantity</TableCell>
                                <TableCell colSpan={2}>Finished Quantity</TableCell>
                                <TableCell colSpan={2}>Remaining Quantity</TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            details && details.productionOrderLines && details.productionOrderLines.map((item, i) => {
                                return (
                                <TableRow key={i}>
                                    <TableCell colSpan={2}>{item.itemNo}</TableCell>
                                    <TableCell colSpan={4}>{item.description}</TableCell>
                                    <TableCell colSpan={2}>{item.quantity}</TableCell>
                                    <TableCell colSpan={2}>{item.finishedQuantity}</TableCell>
                                    <TableCell colSpan={2}>{item.remainingQuantity}</TableCell>
                                    <TableCell colSpan={2}>
                                        <Button disabled={loading} onClick={() => this.createProductionJournal(item)}>Add produced batch</Button>
                                    </TableCell>
                                </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </Container>
            </Modal>

            <Modal show={producedQtyData} onClose={this.onProducedQtyClose.bind(this)}>
                <Container style={styles.formContainer}>
                    <Heading style={styles.formHeading}>Used quantities</Heading>
                    <p style={styles.formParagraph}>{`${producedQtyData.journalLineItem?.itemNo} | To produce: ${producedQtyData.journalLineItem?.quantity}`}</p>
                    {producedQtyData.journalLineItem && producedQtyData.journalLineItem.entryType === 'Consumption' ? 
                        <div key="0">
                            <Field style={styles.field}>
                                <Control>
                                    <Label>{`
                                        Total quantity
                                        ${'quantity' in producedQtyData.journalLineItem && producedQtyData.journalLineItem.quantity > 0 ?
                                        ` (${producedQtyData.journalLineItem.quantity} already set)` :
                                        ''}
                                    `}</Label>
                                    <Input type="text" placeholder='Total quantity' value={formData.producedQty.quantity} onChange={(e) => this.onFormChange('producedQty', 'quantity', e.target.value)}/>
                                </Control>
                            </Field>
                        </div>
                        : ProductionOrderStore && ProductionOrderStore.structure.quantity.map((x, i) => {
                            return (<div key={i}>
                                <Field style={styles.field}>
                                    <Control>
                                        <Label>{`
                                            ${x.label} 
                                            ${producedQtyData.journalLineItem && x.key in producedQtyData.journalLineItem && producedQtyData.journalLineItem[x.key] > 0 ?
                                            ` (${producedQtyData.journalLineItem[x.key]} already set)` :
                                            ''}
                                        `}</Label>
                                        <Input type="text" placeholder={x.label} value={formData.producedQty[x.key]} onChange={(e) => this.onFormChange('producedQty', x.key, e.target.value)}/>
                                    </Control>
                                </Field>
                            </div>);
                        })}
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.producedQtySubmit(producedQtyData)}>Set quantities</Button>
                </Container>
            </Modal>

            <Modal show={producedBatchData} onClose={this.onProducedBatchClose.bind(this)}>
                <Container style={styles.formContainer}>
                    <Heading style={styles.formHeading}>Produced batch</Heading>
                    <p style={styles.formParagraph}>{producedBatchData.journalLineItem?.itemNo}</p>
                    {ProductionOrderStore && ProductionOrderStore.structure.batch.map((x, i) => {
                        if (producedBatchData.journalLineItem?.entryType === 'Consumption' && x.key === 'expirationDate') return false;
                        return (<div key={i}>
                            <Field style={styles.field}>
                                <Control>
                                    <Label>{x.label} {x.key === 'expirationDate' ? '(YYYY-MM-DD)' : ''}</Label>
                                    <Input type="text" placeholder={x.key === 'expirationDate' ? moment().format('YYYY-MM-DD') : x.label} value={formData.producedBatch[x.key]} onChange={(e) => this.onFormChange('producedBatch', x.key, e.target.value)}/>
                                </Control>
                            </Field>
                        </div>);
                    })}
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.producedBatchSubmit(producedBatchData)}>Add produced batch</Button>
                </Container>
            </Modal>

            <Modal show={confirm} onClose={this.onConfirmClose.bind(this)}>
                <Container style={styles.formContainer}>
                    <Heading style={styles.formHeading}>Please confirm</Heading>
                    <p style={styles.formParagraph}>Do you want to add another produced batch?</p>
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.confirmSubmit(true, confirm)}>Add another batch</Button>
                    <Button style={styles.button} disabled={loading} color={"primary"} fullwidth onClick={() => this.confirmSubmit(false, confirm)}>{
                        confirm && journalLines ?
                            journalLines.map(x => x.itemNo).indexOf(confirm.journalLineItem.itemNo) < journalLines.length - 1 ? 'Continue' : 'Complete this production line' :
                            'Continue'
                    }</Button>
                </Container>
            </Modal>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
        padding: 20
    },
    formContainer: {
        borderRadius: 5,
        padding: 20,
        background: 'white',
        minWidth: '400px',
        flexGrow: 0
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    },
    field: {
      marginBottom: '10px'
    },
    tableRow: {
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'red'
        }
    },
    overlay: {
        backgroundColor: 'white'
    },
    button: {
        marginTop: 15
    },
    formHeading: {
        marginBottom: 10
    },
    formParagraph: {
        marginBottom: 20
    }
}

export default ProductionOrders;