import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import NavigationBar from "../../NavigationBar/NavigationBar";
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Container, Modal} from "react-bulma-components";
import {MarketProductsStore} from "../../../../stores";
import FetchView from "../../../dumb/FetchView/FetchView";
import GenericForm from "../../GenericForm/GenericForm";
import {Input, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {toast} from "react-toastify";

@observer
class MarketProducts extends Component {
    @observable accessor localstate = {
        edit: null,
        editInventory: null
    }
    inventoryStore  = null;

    onSave() {
        this.localstate.edit = null;
    }

    editMarket(market) {
        this.localstate.edit = market;
    }

    async deleteMarket(product) {
        if (window.confirm('Are you absolutely sure, that you want to delete this product and all its data?')) {
            await MarketProductsStore.deleteItem(product);
            toast.success("Deleted the product '" + product.name + "'")
        }
    }

    render() {
        const {edit, editInventory} = this.localstate;
        return  (<div>
            <NavigationBar title='Market Products'>
                <Button onClick={() => this.editMarket({})}>Add Product</Button>
            </NavigationBar>
            <Container style={styles.container}>
                <FetchView store={MarketProductsStore}>
                    <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    SKU
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Name
                                </TableCell>
                                <TableCell colSpan={2}>
                                    Weight
                                </TableCell>
                                <TableCell colSpan={4}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{
                            MarketProductsStore.list.map((market, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell colSpan={2}>{market.sku}</TableCell>
                                        <TableCell colSpan={2}>{market.name}</TableCell>
                                        <TableCell colSpan={2}>{market.weight}kg</TableCell>

                                        <TableCell colSpan={4}>
                                            <Button onClick={() => this.editMarket(market)}>Edit</Button>
                                            <Button onClick={() => this.deleteMarket(market)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }</TableBody>
                    </Table>
                </FetchView>
            </Container>
            <Modal show={edit ? true : false} onClose={this.onSave.bind(this)}>
                <GenericForm store={MarketProductsStore} id={edit?.id} onSave={this.onSave.bind(this)}/>
            </Modal>
            <Modal show={editInventory ? true : false} onClose={this.onSave.bind(this)}>
                <FetchView store={this.inventoryStore}>
                    {this.inventoryStore?.list.map((item, i) => {
                        <div key={i}>{item.sku}: <Input>{item.quantity}</Input></div>
                    })}
                </FetchView>
            </Modal>
        </div>);
    }
}

const styles = {
    modal: {
        backgroundColor: 'white',
    },
    container: {
        padding: 20
    },
    languageContainer: {
        display: 'flex',
        justifyContent :'center'
    }
}

export default MarketProducts;