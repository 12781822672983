import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Columns, Form} from 'react-bulma-components';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from '../styles';
import translation from '../translations';
const {Input, Label} = Form;

@observer
class PlasticPackaging extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.handleNestedSelect = props.parent.handleNestedSelect.bind(this);
        this.createNewOption = props.parent.createNewOption.bind(this);
        this.handleInput = props.parent.handleInput.bind(this);
        this.translateLabel = props.parent.translateLabel.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {form, language} = this.localstate;
        const tl = translation[language.value];

        const showRecycleNote =
            form.plasticPack.indiv.materials.map(x => x.materialTransparencies.map(y => y.materialTypes.length > 0).includes(true)).includes(true) || 
            form.plasticPack.outer.materials.map(x => x.materialTransparencies.map(y => y.materialTypes.length > 0).includes(true)).includes(true);

        return (
            <div style={{ ...styles.parting, paddingBottom: 40, marginBottom: 40 }}>
                <h1 style={styles.headline}>3.1 {tl.section3.plastic}</h1>
                <Columns>
                    <Columns.Column>
                        <h2 style={styles.subHeadline}>{tl.indivPack}</h2>
                        <SelectLogic packType='indiv' parent={this}/>
                    </Columns.Column>
                    <Columns.Column>
                    {
                    form.trayAvailable &&
                        <>
                            <h2 style={styles.subHeadline}>{tl.outerPack}</h2>
                            <SelectLogic packType='outer' parent={this}/>
                        </>
                    }
                    </Columns.Column>
                </Columns>
                {
                    showRecycleNote && <div style={styles.note}>¹ {tl.section3.note1}</div>
                }
            </div>
        );
    }
}

@observer
class SelectLogic extends Component {
    constructor(props) {
        super(props);
        this.packType = props.packType;
        this.localstate = props.parent.localstate;
        this.handleNestedSelect = props.parent.handleNestedSelect.bind(this);
        this.createNewOption = props.parent.createNewOption.bind(this);
        this.handleInput = props.parent.handleInput.bind(this);
        this.translateLabel = props.parent.translateLabel.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {loading, options, form, language} = this.localstate;
        const tl = translation[language.value];

        const materials = form.plasticPack[this.packType].materials;

        return (<>
            <div style={{ marginBottom: 30 }}>
                <Label>{tl.material}</Label>
                <CreatableSelect
                    className={this.error(materials)}
                    isMulti
                    placeholder={tl.selectAdd}
                    formatCreateLabel={input => `${tl.createNewOption} „${input}“`}
                    options={options.plasticPack.materials}
                    isDisabled={loading}
                    value={[...this.translateLabel(materials)]}
                    onChange={selected => this.handleNestedSelect(materials, this.translateLabel(selected, 'en'), { materialTransparencies: [] })}
                    onCreateOption={created => this.createNewOption(materials, created, { materialTransparencies: [] })}
                />
            </div>
            <div style={styles.note}>{tl.section3.note2}</div>
            {
                materials.map((material, i) => {
                    return (
                        <Columns key={i} style={{ ...styles.partingTop, paddingBottom: 10, marginBottom: 20 }}>
                            <Columns.Column>
                                <div style={styles.inputBox}>
                                    <Label>{tl.section3.transparencyOf} {tl.section3[material.value] || material.label}</Label>
                                    <Select
                                        className={this.error(material.materialTransparencies)}
                                        isMulti
                                        placeholder={tl.select}
                                        isDisabled={loading}
                                        options={options.plasticPack.materialTransparencies}
                                        value={[...this.translateLabel(material.materialTransparencies)]}
                                        onChange={selected => this.handleNestedSelect(material.materialTransparencies, this.translateLabel(selected, 'en'), { materialTypes: [] })}
                                    />
                                </div>
                                {
                                    material.materialTransparencies?.map((materialTransparency, j) => {
                                        return (
                                            <Columns key={j} style={{ ...styles.partingTop, paddingBottom: 15, marginBottom: 20 }}>
                                                <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                    <div style={styles.inputBox}>
                                                        <Label>{tl.typeOf} {tl.section3[material.value] || material.label} ({tl.section3[materialTransparency.value] || materialTransparency.label})</Label>
                                                        <Select
                                                            className={this.error(materialTransparency.materialTypes)}
                                                            isMulti
                                                            placeholder={tl.select}
                                                            isDisabled={loading}
                                                            options={options.plasticPack.materialTypes}
                                                            value={[...this.translateLabel(materialTransparency.materialTypes)]}
                                                            onChange={selected => this.handleNestedSelect(materialTransparency.materialTypes, this.translateLabel(selected, 'en'), { input: '', recycledContent: '' })}
                                                        />
                                                    </div>
                                                    {
                                                        materialTransparency.materialTypes?.map((materialType, k) => (
                                                            <Columns key={k} style={{ alignItems: 'flex-end', marginTop: 10 }}>
                                                                <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                                    <Label>{tl.section3[material.value] || material.label} - {tl.section3[materialTransparency.value] || materialTransparency.label}, {tl.section3[materialType.value] || materialType.label} (in g)</Label>
                                                                    <Input
                                                                        className={this.error(materialType.input)}
                                                                        type='number'
                                                                        placeholder='0.00'
                                                                        disabled={loading}
                                                                        value={materialType.input}
                                                                        onChange={e => this.handleInput(materialType, 'input', e)}
                                                                    />
                                                                </Columns.Column>
                                                                <Columns.Column style={{ padding: '0px 0.75rem' }}>
                                                                    <Label>{tl.recycledContent}¹ (in %)</Label>
                                                                    <Input
                                                                        className={this.error(materialType.recycledContent)}
                                                                        type='number'
                                                                        placeholder='0.00'
                                                                        disabled={loading}
                                                                        value={materialType.recycledContent}
                                                                        onChange={e => this.handleInput(materialType, 'recycledContent', e)}
                                                                    />
                                                                </Columns.Column>
                                                            </Columns>
                                                        ))
                                                    }
                                                </Columns.Column>
                                            </Columns>
                                        );
                                    })
                                }
                            </Columns.Column>
                        </Columns>
                    );
                })
            }
        </>);
    }
}

export default PlasticPackaging;