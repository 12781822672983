import React, { Component } from "react";
import { Button, Container, Form } from "react-bulma-components";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Table, TableCell, TableRow } from "@material-ui/core";
import RestClient from "../../../../shared/Network/RestClient";
import { toast } from "react-toastify";
import NavigationBar from "../../NavigationBar/NavigationBar";
import Header from "../../Header/Header";
import FetchView from "../../../dumb/FetchView/FetchView";
import moment from "moment";
import {MarketEmployeeStore} from "../../../../stores/Markets/MarketEmployeeStore";
import {UploadButton, BackButton} from "../../../dumb";
import Authentication from "../../../../shared/Authentication";
import LocalCache from '../../../../shared/LocalStorage';
const {Input, Label, Textarea} = Form;

@observer
export default class StatementInput extends Component {
  @observable accessor localstate = {
    status: { // contains the current status
      saving: false
    },
    datum: moment().format('YYYY-MM-DD'),
    marketID: null,
    marketName: null,
    changed: false,
    form: { // contains all the form data submitted to the server
      name: '', // Name of the market employee submitting the statement
      cashBeginn: null,
      cashEnd: null,
      turnoverCash: null,
      turnoverCard: null,
      turnoverTotal: null,
      cashProfit: null,
      difference: undefined,
      travelCostsTo: 0,
      amountDeposited: 0,
      marketCosts: 0,
      travelCostsBack: 0,
      expense: 0,
      note: null,
      receiptTravelCostsTo: undefined,
      receiptTravelCostsBack: undefined,
      receiptExpenses: undefined,
      receiptDeposit: undefined,
      receiptMarketCosts: undefined
    },
    uploadStatus: { // keeps tracks of all the status of uploads of receipts
      receiptTravelCostsTo: false,
      receiptTravelCostsBack: false,
      receiptExpenses: false,
      receiptDeposit: false,
      receiptMarketCosts: false
    },
    belege: { // array keeping the urls of receipts per category
      receiptTravelCostsTo: [],
      receiptTravelCostsBack: [],
      receiptExpenses: [],
      receiptDeposit: [],
      receiptMarketCosts: []
    },
    availableEmployees: [], // all market employees
    employees: []
  };

  constructor(props) {
    super(props);
    this.localstate.datum = new Date(props.match.params.date);
    this.localstate.marketID = props.match.params.id;
    this.localstate.approvalMode = (props.match.params.approve);
  }

  // handles form changes to any input field
  onChange(key, value, isFloat = false) {
    this.localstate.form[key] = value;
    this.localstate.changed = true;
    const {form} = this.localstate;

    // cache current status into local storage
    this.cacheStorage('Form', JSON.stringify(this.localstate.form));

    if (form.cashBeginn!=null && form.cashEnd!=null && form.travelCostsTo!=null && form.travelCostsBack!=null && form.expense!=null && form.cashEnd!=null) {
      form.difference = parseFloat(
          parseFloat(form.cashBeginn) + parseFloat(form.turnoverCash) -
          form.travelCostsTo -
          form.travelCostsBack -
          form.expense - parseFloat(form.cashEnd) - form.marketCosts
      ).toFixed(2)
    }
  }

  cacheStorage(key, value) {
    // cache current status into local storage
    const {marketID, datum} = this.localstate;
    LocalCache.write(`MarketStatement_${marketID}_${moment(datum).format('Y-MM-DD')}_${key}`, value);
  }

  readStorage(key) {
    // get current status from local storage
    const {marketID, datum} = this.localstate;
    return LocalCache.read(`MarketStatement_${marketID}_${moment(datum).format('Y-MM-DD')}_${key}`);
  }

  // handles uploads of receipts (onChange for file-uploads)
  async uploadBelege(key, files) {
    this.localstate.uploadStatus[key] = true;
    for (const file of files) {
      const formData = new FormData();
      formData.append("image",file);
      const request = RestClient.prepareRequest(
          "POST",
          "markets/receipts",
          "multipart/form-data"
      );
      request.setData(formData);
      try {
        const response = await request.send();
        const s3Url = response.data
        this.localstate.belege[key].push(s3Url);
      } catch (error) {
        toast.error('Der Beleg konnte nicht hochgeladen werden...\n' + error)
      }
    }
    this.localstate.changed=true;
    this.localstate.uploadStatus[key] = false;

    // cache current belege into local storage
    this.cacheStorage('Belege', JSON.stringify(this.localstate.belege));
  }

  // removes receipts from the array
  removeBeleg(key, index) {
    this.localstate.belege[key].splice(index, 1);
    this.localstate.changed=true;

    // cache current belege into local storage
    this.cacheStorage('Belege', JSON.stringify(this.localstate.belege));
  }

  /* loads required data from the server:
    - existing market statement data (if it has been edited before)
    - all the sales for this specific market on this specific date
    - all employees that can then be selected as the market employee responsible
   */

  async loadInitialData() {
    const {datum, marketID} = this.localstate;

    // load current status of the market statement if existing
    const requestExistingStatement = RestClient.prepareRequest(
        "GET",
        "markets/"+ marketID +"/statements/date/" + datum
    );
    const resultExisting = await requestExistingStatement.send();
    if (resultExisting && resultExisting.data) {
      // load existing data into the form
      this.localstate.form = resultExisting.data;
      for (const beleg of Object.keys(this.localstate.belege)) {
        this.localstate.belege[beleg] = this.localstate.form[beleg] ? JSON.parse(this.localstate.form[beleg]) : [];
      }
    } else {
        // get current status from local storage
        const status = this.readStorage("Form");
        const belege = this.readStorage("Belege");

        if (status) {
          this.localstate.form = JSON.parse(status);
        }
        if (belege) {
          this.localstate.belege = JSON.parse(belege);
        }
    }

    // get all sales for this market on this specific date to calculate revenues
    const request = RestClient.prepareRequest(
      "GET",
      "markets/"+ marketID +"/sales/" + datum
    );
    const result = await RestClient.sendRequest(request);
    if (result.data.seller) {
      this.localstate.marketName =  result.data.seller.name;
    }
    if (!result.data.data || result.data.data.length === 0) {
      throw new Error("Für dieses Datum gibt es keine Umsätze. Bitte wähle ein anderes Datum!");
    } else {
      this.calcTurnover(result.data.data);
    }

    // get available market employees to be able to select them in the form
    await MarketEmployeeStore.fetchData();
    this.localstate.availableEmployees = MarketEmployeeStore.list.map(x => {
      return {
        value: x.id,
        label: x.name
      }
    });



  }

  // calculates the daily sales revenue by card and cash
  calcTurnover(data) {
    let dailyCashTurnover = 0;
    let dailyCardTurnover = 0;
    data.forEach((payment) => {
      if (payment.paymentType === "CASH") {
        dailyCashTurnover += payment.total*1.07;
      } else if (payment.paymentType === "POS") {
        dailyCardTurnover += payment.total*1.07;
      }
    });

    dailyCashTurnover = parseFloat(dailyCashTurnover.toFixed(2));
    dailyCardTurnover = parseFloat(dailyCardTurnover.toFixed(2));

    this.localstate.form.turnoverCash = dailyCashTurnover;
    this.localstate.form.turnoverCard = dailyCardTurnover;
    this.localstate.form.turnoverTotal = (dailyCashTurnover + dailyCardTurnover).toFixed(2);
  }

  // this function prepares all form data to be sent to the backend
  async pushDataToBackend() {
    if (!window.confirm('Bist du sicher, dass alles stimmt? Du bestätigst hiermit, dass du alle Angaben wahrheitsgemäß gemacht hast.')){
      return false;
    }

    // calculate the profit for the day (used for bigquery)

    console.log(this.localstate.form);

    this.localstate.form.cashProfit = parseFloat(
      this.localstate.form.cashEnd -
      this.localstate.form.travelCostsTo -
      this.localstate.form.travelCostsBack -
      this.localstate.form.expense).toFixed(2);

    if(this.localstate.form.note === null){
      this.localstate.form.note = 'Keine Notiz'
    }

    delete this.localstate.form.amountDiscount;

    // As we can have multiple receipts per item, we put them into a JSON-array
    for (const beleg of Object.keys(this.localstate.belege)) {
        this.localstate.form[beleg] = JSON.stringify(this.localstate.belege[beleg]);
    }

    // Abwärtskompatibell, da der Name bisher nie angegeben werden musste
    this.localstate.form.name = this.localstate.form.name || 'Leer';

    // Find any null-entries in form data as all fields are required
    const isNull = Object.keys(this.localstate.form).map((key) => {
      if (this.localstate.form[key] !== null) {
        return this.localstate.form[key];
      } else {
        return null;
      }
    });

    // abort if any fields have not been filled out
    if (!isNull.includes(null)) {
      try {
        this.localstate.status.saving = true;
        console.log(this.localstate.form);
        await this.postStatementToBackend();
        if (!this.localstate.approvalMode) {
          this.localstate.status.saving=false;
          this.cacheStorage('Form', '');
          this.cacheStorage('Belege', '');

          toast.success('Danke dir! Das Market Statement wurde erfolgreich gespeichert!')
          this.props.history.goBack();
        }
      } catch (error) {
        toast.error('Es ist ein Fehler aufgetreten.\n' + error)
        this.localstate.status.saving=false;

      }
    } else {
      toast.error("Bitte fülle alle Daten aus.");
    }
  }

  // This function takes all the processed form data and submits the market statement to the server
  async postStatementToBackend() {
    const {marketID, datum, form} = this.localstate;
    const request = RestClient.prepareRequest("POST", "markets/"+marketID+"/statements/" + datum);
    request.setData(form);
    try {
      const res = await request.send();
      if (res.success ) {
        return true;
      } else {
        throw new Error(res.error.name ? res.error.name : res.error);
      }
    } catch (error) {
      toast.error('Es ist ein Fehler aufgetreten.\n' + error)
    }
  }

  // post the responsible market employee to the backend
  async postEmployeeToBackend(employeeName, workingTimeEmployee, marketDate) {
    Object.keys(employeeName).map(async (key) => {
      const name = employeeName[key];
      const time = workingTimeEmployee[key];
      const request = RestClient.prepareRequest("POST", "markets/employees");
      request.setData({
        name,
        time,
        marketDate,
      });

      try {
        await request.send();
      } catch (error) {
        toast.error('Es ist ein Fehler aufgetreten.\n' + error)
      }
    });
  }

  // set market date
  setDate(datum) {
    this.localstate.datum = datum;
  }

  // handles the selection of market employees
  handleEmployeeSelect(e) {
      if (e !== null){
        let list = [];
        e.map(item => (
            list.push(item.value)
        ));
        this.localstate.employees = list;
      } else {
        this.localstate.employees = [];
      }
  }

  /*
  This function is called if you open the statement in approval mode and approve the statement
  It first saves all changes to the server and then posts the approval to another route for authentication
   */
  async updateAndApprove() {
    this.localstate.status.saving = true;

    try {
      if (this.localstate.changed) {
        // send changes to backend
        await this.pushDataToBackend()
      }

      // approve the form statement
      const {user} = Authentication;
      const {marketID} = this.localstate;
      const {id} = this.localstate.form;
      const request = RestClient.prepareRequest(
          "POST",
          "markets/"+ marketID +"/statements/approvals/"
      );
      request.setData({
        MarketStallStatementId: id,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userEmail: user.email,
        approved: true,
        note: ''
      });
      const res = await request.send();
      if (res.success) {
        toast.success("Das Market Statement wurde erfolgreich freigegeben!");
        this.props.history.goBack();
      } else {
        throw new Error(res.error.name ? res.error.name : res.error)
      }


    } catch (e) {
      toast.error('Freigabe fehlgeschlagen: ' + e);
    }

    this.localstate.status.saving = false;
  }

  render() {
    const {datum,marketName, changed, form, status, approvalMode, belege, uploadStatus} = this.localstate;
    let summeNotEingezahlt;
    let hinweisDeposit = null;
    if (form.cashEnd && form.amountDeposited) {
      summeNotEingezahlt = form.cashEnd - form.amountDeposited;
      if (summeNotEingezahlt>150) {
        hinweisDeposit = "Du hast noch mehr als 150€ Bargeld, bitte zahle das restliche Geld bis auf 20-30€ ein. "
      }
    }

    let differenceText = "";
      if (form.difference>=10) {
        differenceText = "Es fehlen " + form.difference +  "€ rechnerisch in der Kasse. Zähle bitte nochmal nach und prüfe deine Eingaben :) "
      }

      return (
          <div>
            <Header/>
            <NavigationBar title={marketName ? "Marktabschluss für " + marketName : "Marktabschluss"}/>
          <Container style={styles.modal}>
            <BackButton text="Zurück zur Übersicht" withoutLink={true} onClick={() => this.props.history.goBack()} />
            <div style={styles.fieldWrapper}>
                <FetchView call={this.loadInitialData.bind(this)} refresh={datum}>
                  <Table>
                    <TableRow>
                      <TableCell>
                        <Label>Datum</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{moment(datum).format('DD.MM.Y')}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Bar</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverCash} €`}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Karte</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverCard} €`}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Umsatz Gesamt</Label>
                      </TableCell>
                      <TableCell>
                        <Label>{`${form.turnoverTotal} €`}</Label>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Name Marktmitarbeiter*in</Label>
                      </TableCell>
                      <TableCell>
                        <Input
                            type="text"
                            style={styles.numberInput}
                            onChange={(e) =>
                                this.onChange("name", e.target.value)
                            }
                            value={form.name}
                        />
                      </TableCell>
                    </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zu Beginn</Label>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="text"
                      style={styles.numberInput}
                      pattern="[0-9]+"
                    onChange={(e) =>
                        this.onChange("cashBeginn", e.target.value, true)
                      }
                      value={form.cashBeginn}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Kassenbestand zum Ende</Label>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="text"
                      style={styles.numberInput}
                      onChange={(e) => this.onChange("cashEnd", e.target.value, true)}
                      value={form.cashEnd}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Hin</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                    <Input
                      type="text"
                      style={styles.numberInput}
                      onChange={(e) =>
                        this.onChange("travelCostsTo", e.target.value, true)
                      }
                      value={form.travelCostsTo}
                    />
                      <div style={{paddingBottom: 10}}>
                        <UploadButton
                            name="receiptTravelCostsTo"
                            onChange={(e) =>
                              this.uploadBelege("receiptTravelCostsTo", e.target.files)
                            }
                            label= {"Belege hochladen"}
                            loading = {uploadStatus.receiptTravelCostsTo}
                        />
                      </div>
                    </div>
                    <div>
                      {belege.receiptTravelCostsTo.map((x, i) =>
                        <li key={i}>
                          <a href={x} download={true} target="_blank">Beleg {i+1}</a>
                          <b style={{cursor: 'pointer'}} onClick={() => this.removeBeleg("receiptTravelCostsTo", i)}> [löschen]</b>
                        </li>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Fahrtkosten Zurück</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                    <Input
                      type="text"
                      style={styles.numberInput}
                      onChange={(e) =>
                        this.onChange("travelCostsBack", e.target.value, true)
                      }
                      value={form.travelCostsBack}
                    />
                      <div style={{paddingBottom: 10}}>
                        <UploadButton
                            name='receiptTravelCostsBack'
                            onChange={(e) =>
                                this.uploadBelege("receiptTravelCostsBack", e.target.files)
                            }
                            label= {"Belege hochladen"}
                            loading = {uploadStatus.receiptTravelCostsBack}
                        />
                      </div>
                    </div>
                    <div>
                      {belege.receiptTravelCostsBack.map((x, i) =>
                          <li key={i}>
                            <a href={x} download={true} target="_blank">Beleg {i+1}</a>
                            <b style={{cursor: 'pointer'}} onClick={() => this.removeBeleg("receiptTravelCostsBack", i)}> [löschen]</b>
                          </li>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>Spesen</Label>
                  </TableCell>
                  <TableCell>
                    <div style={styles.fileContainer}>
                      <Input
                        style={styles.numberInput}
                        type="text"
                        onChange={(e) => this.onChange("expense", e.target.value, true)}
                        value={form.expense}
                      />
                      <div style={{paddingBottom: 10}}>
                        <UploadButton
                            name='receiptExpenses'
                            onChange={(e) =>
                                this.uploadBelege("receiptExpenses", e.target.files)
                            }
                            label= {"Belege hochladen"}
                            loading = {uploadStatus.receiptExpenses}
                        />
                      </div>
                    </div>
                    {form.expense > 8 && <div  style={styles.difference}>Bitte erkläre kurz in den Notizen, warum du mehr als 8€ Spesen gebraucht hast.</div>}
                    <div>
                      {belege.receiptExpenses.map((x, i) =>
                          <li key={i}>
                            <a href={x} download={true} target="_blank">Beleg {i+1}</a>
                            <b style={{cursor: 'pointer'}} onClick={() => this.removeBeleg("receiptExpenses", i)}> [löschen]</b>
                          </li>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
                    <TableRow>
                      <TableCell>
                        <Label>Kosten für den Marktstand</Label>
                      </TableCell>
                      <TableCell>
                        <div style={styles.fileContainer}>
                          <Input
                              type="text"
                              style={styles.numberInput}
                              onChange={(e) =>
                                  this.onChange("marketCosts", e.target.value, true)
                              }
                              value={form.marketCosts}
                          />
                        <div style={{paddingBottom: 10}}>
                          <UploadButton
                              name='receiptMarketCosts'
                              onChange={(e) =>
                                  this.uploadBelege("receiptMarketCosts", e.target.files)
                              }
                              label= {"Belege hochladen"}
                              loading = {uploadStatus.receiptMarketCosts}
                          />
                        </div>
                        </div>
                        <div>
                          {belege.receiptMarketCosts.map((x, i) =>
                              <li key={i}>
                                <a href={x} download={true} target="_blank">Beleg {i+1}</a>
                                <b style={{cursor: 'pointer'}} onClick={() => this.removeBeleg("receiptMarketCosts", i)}> [löschen]</b>
                              </li>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    {differenceText && <TableRow>
                  <TableCell>
                    <Label>Differenz</Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      {differenceText ? <div style={styles.difference}>{differenceText}</div> :  ''}
                    </Label>
                  </TableCell>
                </TableRow>}

                    { /* <TableRow>
                      <TableCell>
                        <Label>MitarbeiterInnen</Label>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Select
                            isMulti
                            className = "basic-multi-select"
                            options = {availableEmployees }
                            onChange = {e => this.handleEmployeeSelect(e)}
                            placeholder = "MitarbeiterInnen auswählen"
                        />
                      </TableCell>
                    </TableRow> */ }
                <TableRow>
                  <TableCell>
                    <Label>Anmerkung</Label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Textarea
                      type="text"
                      style={styles.inputFieldGap}
                      onChange={(e) => this.onChange("note", e.target.value)}
                      value={form.note}
                    />
                  </TableCell>
                </TableRow>

                  </Table>
                  {!approvalMode && <Button
                      color="primary"
                      fullwidth={true}
                      onClick={() => this.pushDataToBackend()}
                      disabled={status.saving}
                      loading={status.saving}
                  >
                    {form.id ? "Änderungen speichern" : "Speichern"}
                  </Button>}
                  {approvalMode && <Button
                      color="primary"
                      fullwidth={true}
                      onClick={() => this.updateAndApprove()}
                      disabled={status.saving}
                      loading={status.saving}
                  >
                    {changed && "Änderungen speichern & "} Statement freigeben
                  </Button>}
                  {approvalMode && <Button
                      style={{ marginTop: 15 }}
                      color="danger"
                      fullwidth={true}
                      onClick={() => this.props.history.goBack()}
                      disabled={status.saving}
                      loading={status.saving}
                  >
                    Statement nicht freigeben
                  </Button>}
                </FetchView>

            </div>
  

          </Container>
          </div>
      );
  }
}

const styles = {
  modal: {
    paddingBottom: 40
  },
  difference: {
    color: 'orange',
    maxWidth: 300
  },
  fileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems:'center'
  },
  numberInput: {
    width: "160px",
    marginRight: 10,
    marginBottom: 10
  },
  fieldWrapper: {
    marginBlock: "2.5em",
  },
  inputFileGap: {
    maxWidth: 160
  },

  logo: {
    width: "5em",
  },
  marketName: {
    fontSize: "2rem",
    textTransform: "uppercase",
    marginLeft: "auto",
  },
};
