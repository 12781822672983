import React, {Component} from 'react';
import {Box, Button, Heading} from 'react-bulma-components'
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {PageStructureNotLoggedIn} from '../../dumb';
import {Link, withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import RestClient from '../../../shared/Network/RestClient';

@observer
class PasswordReset extends Component {
    @observable accessor localstate = {
        loading: true,
        done: false,
        error: null,
    };

    async resetPassword() {
        const match = this.props.location.search.match(/\?*user=(.*?)(&|$)/);
        const hashEmail = (match && match?.length >= 1) ? match[1] : null;

        if (!hashEmail) {
            this.error('Your password could not be reset. No email address recognized.');
            return;
        }

        try {
            const request = RestClient.prepareRequest('POST', 'auth/passwordreset');
            request.setData({ email: hashEmail });
            const res = await request.send();
    
            if (!res.success) throw res;

            localStorage.setItem('lastPasswordReset', this.props.location.search);
            toast.success('Yout password has been reset. Check your email inbox');
            this.localstate.loading = false;
            this.localstate.done = true;
        } catch (error) {
            this.error(error);
        }
    }

    error(error) {
        console.error(error);
        this.localstate.error = (typeof error === 'string') ? error : (error.error?.message || error.error || error.message || JSON.stringify(error) || 'An error occurred');
        toast.error(this.localstate.error);
        this.localstate.loading = false;
    }

    componentDidMount() {
        const lastPasswordReset = localStorage.getItem('lastPasswordReset');

        if (lastPasswordReset !== this.props.location.search) {
            this.resetPassword();
        } else {
            this.error(`Your password has already been reset. Request a new one if you haven't received an email.`);
        }
    }

    componentWillUnmount() {
        localStorage.removeItem('lastPasswordReset');
    }

    render() {
        const {loading, done, error} = this.localstate;

        return (
            <PageStructureNotLoggedIn>
                <Heading>New password</Heading>
                <Box>
                    { (loading && !error && !done) && <Heading size={4} style={{ color: 'inherit' }}>Please wait until your password is reset ...</Heading>}
                    { (done && !error) && <Heading size={4} style={{ color: 'inherit' }}>Your password has been reset.<br/>Please check your email inbox!</Heading>}
                    {
                    error &&
                        <>
                            <Heading size={4} style={{ color: 'inherit' }}>{error}</Heading>
                            <Heading size={6} subtitle={true} style={{ color: 'inherit' }}><br/>You can leave the page.</Heading>
                        </>
                    }
                    <Button
                        color='primary'
                        fullwidth={true}
                        loading={loading}
                        style={{ marginTop: 20 }}
                    >
                        <Link to='/login'>Back to login page</Link>
                    </Button>
                </Box>
            </PageStructureNotLoggedIn>
        );
    }
}

export default withRouter(PasswordReset);