import React, {Component}  from "react";
import FetchView from "../../dumb/FetchView/FetchView";
import RestClient from "../../../shared/Network/RestClient";
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {
    Container,
    Columns,
    Heading,
    Button,
} from "react-bulma-components";

@observer
class LotteryScreen extends Component {
    @observable accessor localstate =  {
        ticket: {
            winningTicket: false,
            code: "",
            voucher: ""
        },
        error: false,
        loading: true
    }
    async verifyTicket() {
        this.localstate.error = false;
        this.localstate.loading = true;
        const request = RestClient.prepareRequest('POST', 'lottery/ticket');
        const query = new URLSearchParams(this.props.location.search);
        request.setData({
            code: query.get('code'),
            name: query.get('name'),
            email: query.get('email'),
            newsletterconsent: query.get('newsletterconsent'),
            lotteryconsent: query.get('lotteryconsent'),
            salesChannelId: query.get('salesChannelId'),
            languageId: query.get('languageId'),
            storefrontUrl: query.get('storefrontUrl')
        });
        try {
            const response = await request.send();
            if (response.success) {
                this.localstate.ticket=response.data[0];
            } else {
                console.log(response);
                this.localstate.error = true;
            }
            this.localstate.loading = false;

        } catch (e) {
            this.localstate.error = e.message;
            this.localstate.loading = false;
        }
    }

    render() {
        const {error, ticket, loading} = this.localstate;
        let url ="";
        if (!loading && ticket.winningTicket) {
            url ="https://www.korodrogerie.de/promotion/" + ticket.voucher + "?articles=KORO_015&message=Der%20Gutschein%20wurde%20erfolgreich%20eingel%C3%B6st&c=frontend.checkout.cart.page";
        } else if (!loading) {
            url ="https://www.korodrogerie.de/promotion/" + ticket.voucher + "";
        }

        return (<Container>

            <div style={styles.container}>
                <FetchView call={this.verifyTicket.bind(this)}>
                    {error && <div>
                        <div>Ein Fehler ist aufgetreten. Bitte prüfe, ob Du Deinen Code korrekt eingegeben hast.</div>
                        <a href='https://www.korodrogerie.de/lotterie'><Button>Zurück</Button></a></div>}
                    {!loading && !error && <div style={{maxWidth: 600}}>
                        <div style={{paddingBottom: 50}}><img  src="https://koro.imgix.net/media/66/f7/5b/1662581399/koro-logoPXS2yC9zKwHVo.svg?w=3000&auto=format,compress&fit=max" />
                        </div>
                            <Columns>
                            <Columns>
                                <Columns.Column style={styles.leftColumn}>
                                    {ticket.winningTicket ?
                                        <img style={styles.image} src={'https://koro.imgix.net/media/48/45/d1/1700235139/KoRo_Box_7vsWild_09.jpg?w=3000&auto=format,compress&fit=max'} /> :
                                        <img style={{width: 200, height: 150}} src={'https://media.tenor.com/QwyDTN_0AfAAAAAC/the-goon-win.gif'} /> }
                                </Columns.Column>
                                <Columns.Column style={styles.rightColumn}><Heading>{ticket.winningTicket ? "Du hast gewonnen - viel Spaß mit Deinem KoRo Snack-Paket": "Du hast einen 5% Gutschein für den KoRo-Shop gewonnen!"}</Heading></Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column  style={styles.leftColumn}>
                                    Dein Gutscheincode:
                                </Columns.Column>
                                <Columns.Column style={styles.rightColumn
                                }>
                                    {ticket.voucher}
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column style={styles.leftColumn}>
                                    Wert:
                                </Columns.Column>
                                <Columns.Column style={styles.rightColumn}>
                                    {ticket.winningTicket?
                                        '1x 7 vs. Wild Survival Box im Wert von 27,50€' :
                                        'Einen 5% Gutschein einlösbar auf alle Produkte im KoRo Online-Shop für deinen nächsten Einkauf' }
                                </Columns.Column>

                            </Columns>
                            <Columns>
                                <Columns.Column style={styles.leftColumn}>
                                    Gültig bis:
                                </Columns.Column>
                                <Columns.Column style={styles.rightColumn}>
                                    31.12.2024
                                </Columns.Column>

                            </Columns>
                            <Columns>
                                <Columns.Column style={styles.leftColumn}>
                                    Bedingungen:
                                </Columns.Column>
                                <Columns.Column style={styles.rightColumn}>
                                    Einmalig einlösbar, nicht kombinierbar mit anderen Aktionen & gültig bis 31.12.2024
                                </Columns.Column>

                            </Columns>
                            <a href={url}><Button
                                style={{marginTop: 30}}
                                color="primary" fullwidth={true}>Jetzt einlösen!</Button></a>
                        </Columns>

                    </div>}
                </FetchView>

            </div>
        </Container>)
    }
}

const styles = {
    container :{
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        padding: '100px'
    },
    leftColumn: {
      width: '200px'
    },
    rightColumn: {
        width: '350px',
        fontWeight: 'bold',

    },
    image: {
        height: '200px'
    }
}

export default LotteryScreen;