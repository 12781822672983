import React from 'react';
import {Page, Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import moment from 'moment';
import font from 'assets/Roboto.ttf';
import fontBold from 'assets/Roboto-Bold.ttf';
import translation from '../translations';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: font },
        { src: fontBold, fontWeight: 'bold' },
    ],
});

function PDFPage(props) {
    const {form, language, selected} = props.parentstate;
    const {section, headline, text} = pdfStyles;
    const {table, thead, tbody, row, cell, cellSmall} = pdfStyles.table;
    const tl = translation[language.value];
    const selectedQuestionnaire = selected.questionnaire?.DynamicsSupplier;

    return (
        <Page size='A4' style={pdfStyles.page} wrap={true}>
            <View style={section} wrap={false}>
                <Text style={headline}>1. {tl.section1.headline}</Text>
                <View style={table}>
                    <View style={tbody}>
                        <View style={row}>
                            <Text style={cellSmall}>{tl.questionnaire}</Text>
                            <Text style={cell}>{
                                selected.questionnaire
                                    ? `ID: ${selected.questionnaire?.id}
                                    ${tl.createdAt} ${moment(selected.questionnaire?.createdAt).format(tl.dateFormat)}
                                    ${tl.updatedAt} ${moment(selected.questionnaire?.updatedAt).format(tl.dateFormat)}`
                                    : '-'
                            }</Text>
                        </View>
                        <View style={row}>
                            <Text style={cellSmall}>{tl.supplier}</Text>
                            <Text style={cell}>{
                                selectedQuestionnaire
                                    ? `${selectedQuestionnaire?.vendorNr} - ${selectedQuestionnaire?.name}`
                                    : '-'
                            }</Text>
                        </View>
                        <View style={row}>
                            <Text style={cellSmall}>{tl.section1.sku}</Text>
                            <Text style={cell}>{ form.skus.map(x => x.label).join(',\n') }</Text>
                        </View>
                        <View style={row}>
                            <Text style={cellSmall}>{tl.section1.checkboxText}</Text>
                            <Text style={cell}>{form.trayAvailable ? tl.yes : tl.no}</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={section} wrap={false}>
                <Text style={headline}>2. {tl.section2.headline}</Text>
                <View style={table}>
                    <View style={thead}>
                        <View style={row}>
                            <Text style={cell}></Text>
                            <Text style={cell}>{tl.width} (mm)</Text>
                            <Text style={cell}>{tl.height} (mm)</Text>
                            <Text style={cell}>{tl.length} (mm)</Text>
                        </View>
                    </View>
                    <View style={tbody}>
                        <View style={row}>
                            <Text style={cell}>{tl.indivPack}</Text>
                            <Text style={cell}>{form.dimensions.indiv.width || '-'}</Text>
                            <Text style={cell}>{form.dimensions.indiv.height || '-'}</Text>
                            <Text style={cell}>{form.dimensions.indiv.length || '-'}</Text>
                        </View>
                        {
                        form.trayAvailable &&
                            <View style={row}>
                                <Text style={cell}>{tl.outerPack}</Text>
                                <Text style={cell}>{form.dimensions.outer.width || '-'}</Text>
                                <Text style={cell}>{form.dimensions.outer.height || '-'}</Text>
                                <Text style={cell}>{form.dimensions.outer.length || '-'}</Text>
                            </View>
                        }
                    </View>
                </View>
            </View>

            <View style={section}>
                <Text style={headline}>3. {tl.section3.headline}</Text>
                {
                form.isPlastic &&
                    <>
                        <Text style={headline}>3.1 {tl.section3.plastic}</Text>
                        <View style={table}>
                            <View style={thead}>
                                <View style={row}>
                                    <Text style={cell}>{tl.indivPack}</Text>
                                </View>
                            </View>
                            <View style={tbody}>{ plasticPackSection(props.parentstate, 'indiv') }</View>
                        </View>
                        {
                        form.trayAvailable &&
                            <View style={{ ...table, marginTop: 20 }}>
                                <View style={thead}>
                                    <View style={row}>
                                        <Text style={cell}>{tl.outerPack}</Text>
                                    </View>
                                </View>
                                <View style={tbody}>{ plasticPackSection(props.parentstate, 'outer') }</View>
                            </View>
                        }
                    </>
                }
                {
                (form.otherPack.indiv.materials.length > 0 || form.otherPack.outer.materials.length > 0) &&
                    <>
                        <Text style={{ ...headline, marginTop: 20 }}>3.2 {tl.section3.other}</Text>
                        <View style={table}>
                            <View style={thead}>
                                <View style={row}>
                                    <Text style={cell}>{tl.indivPack}</Text>
                                </View>
                            </View>
                            <View style={tbody}>{ otherPackSection(props.parentstate, 'indiv') }</View>
                        </View>
                        {
                        form.trayAvailable &&
                            <View style={{ ...table, marginTop: 20 }}>
                                <View style={thead}>
                                    <View style={row}>
                                        <Text style={cell}>{tl.outerPack}</Text>
                                    </View>
                                </View>
                                <View style={tbody}>{ otherPackSection(props.parentstate, 'outer') }</View>
                            </View>
                        }
                    </>
                }
            </View>

            <View style={section} wrap={false}>
                <Text style={headline}>4. {tl.section4.headline}</Text>
                <Text style={{ ...text.l, marginBottom: 5 }}>{tl.section4.checkboxText}: {form.label.ordered ? tl.yes : tl.no}</Text>
                {
                !form.label.ordered &&
                    <View style={table}>
                        <View style={thead}>
                            <View style={row}>
                                <Text style={cell}>{tl.width} (mm)</Text>
                                <Text style={cell}>{tl.height} (mm)</Text>
                                <Text style={cell}>{tl.weight} (in g)</Text>
                                <Text style={cell}>{tl.material}</Text>
                            </View>
                        </View>
                        <View style={tbody}>
                            <View style={row}>
                                <Text style={cell}>{form.label.width || '-'}</Text>
                                <Text style={cell}>{form.label.height || '-'}</Text>
                                <Text style={cell}>{form.label.weight || '-'}</Text>
                                <Text style={cell}>{form.label.material || '-'}</Text>
                            </View>
                        </View>
                    </View>
                }
            </View>

            <View style={section} wrap={false}>
                <Text style={headline}>5. {tl.section5.headline}</Text>
                <View style={table}>
                    <View style={thead}>
                        <View style={row}>
                            <Text style={cell}>{tl.indivPack}</Text>
                            { form.trayAvailable && <Text style={cell}>{tl.outerPack}</Text> }
                        </View>
                    </View>
                    <View style={tbody}>
                        <View style={row}>
                            <Text style={cell}>
                            {
                            form.recyclingCodes.indivPack.length > 0
                                ? form.recyclingCodes.indivPack.map(x => `${x.code}: ${x.input}`).join(',\n')
                                : '-'
                            }
                            </Text>
                            {
                            form.trayAvailable &&
                                <Text style={cell}>
                                {
                                form.recyclingCodes.outerPack.length > 0
                                    ? form.recyclingCodes.outerPack.map(x => `${x.code}: ${x.input}`).join(',\n')
                                    : '-'
                                }
                                </Text>
                            }
                        </View>
                    </View>
                </View>
            </View>

            <View style={section} wrap={false}>
                <Text style={headline}>6. {tl.section6.headline}</Text>
                <View style={table}>
                    <View style={thead}>
                        <View style={row}>
                            <Text style={cell}>{tl.section6.singleItem}</Text>
                            <Text style={cell}>{tl.section6.trayPerBox}</Text>
                            <Text style={cell}>{tl.section6.boxPerPalette}</Text>
                        </View>
                    </View>
                    <View style={tbody}>
                        <View style={row}>
                            <Text style={cell}>{form.palette.singleItem || '-'}</Text>
                            <Text style={cell}>{form.palette.trayPerBox || '-'}</Text>
                            <Text style={cell}>{form.palette.boxPerPalette || '-'}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    );
}

function plasticPackSection(parentstate, packType) {
    const {form, language} = parentstate;
    const {table, thead, tbody, row, cell, cellSmall, rowClean} = pdfStyles.table;
    const tl = translation[language.value];

    return (
        form.plasticPack[packType].materials.map((material, i) => (
            <View key={i} style={row} wrap={false}>
                <Text style={{ ...cell, width: '10%' }}>{tl.section3[material.value] || material.label}:</Text>
                <View style={cell}>
                    <View style={table}>
                        <View style={tbody}>
                        {
                            material.materialTransparencies.map((transparency, j) => (
                                <View key={j} style={j + 1 === material.materialTransparencies.length ? rowClean : row}>
                                    <Text style={cellSmall}>{tl.section3[transparency.value] || transparency.label}:</Text>
                                    <View style={cell}>
                                        <View style={table}>
                                            <View style={tbody}>
                                            {
                                                transparency.materialTypes.map((type, k) => (
                                                    <View key={k} style={k + 1 === transparency.materialTypes.length ? rowClean : row}>
                                                        <Text style={cellSmall}>{tl.section3[type.value] || type.label}:</Text>
                                                        <View style={cell}>
                                                            <View style={table}>
                                                                <View style={thead}>
                                                                    <View style={row}>
                                                                        <Text style={cell}>{tl.section3.amount}</Text>
                                                                        <Text style={cell}>{tl.recycledContent}</Text>
                                                                    </View>
                                                                </View>
                                                                <View style={tbody}>
                                                                    <View style={rowClean}>
                                                                        <Text style={cell}>{type.input} g</Text>
                                                                        <Text style={cell}>{type.recycledContent} %</Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                ))
                                            }
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            ))
                        }
                        </View>
                    </View>
                </View>
            </View>
        ))
    );
}

function otherPackSection(parentstate, packType) {
    const {form, language} = parentstate;
    const {table, thead, tbody, row, cell, cellSmall, rowClean} = pdfStyles.table;
    const tl = translation[language.value];

    return (
        form.otherPack[packType].materials.map((material, i) => {
            const optionItem = parentstate.options.otherPack.materials?.find(item => item.value === material.value);

            return (
                <View key={i} style={row} wrap={false}>
                    <Text style={cellSmall}>{tl.section3[material.value] || material.label}:</Text>
                    <View style={cell}>
                        <View style={table}>
                            <View style={tbody}>
                            {
                            optionItem.materialTypes ?
                                material.materialTypes.map((type, j) => (
                                    <View key={j} style={j + 1 === material.materialTypes.length ? rowClean : row}>
                                        <Text style={cellSmall}>{tl.section3[type.value] || type.label}:</Text>
                                        <View style={cell}>
                                            <View style={table}>
                                                <View style={thead}>
                                                    <View style={row}>
                                                        { optionItem.specifyName && <Text style={cell}>{tl.name}</Text> }
                                                        <Text style={cell}>{tl.section3.amount}</Text>
                                                        <Text style={cell}>{tl.recycledContent}</Text>
                                                    </View>
                                                </View>
                                                <View style={tbody}>
                                                    <View style={rowClean}>
                                                        { optionItem.specifyName && <Text style={cell}>{type.name || '-'}</Text> }
                                                        <Text style={cell}>{type.input} g</Text>
                                                        <Text style={cell}>{type.recycledContent} %</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                ))
                                :
                                <View style={rowClean}>
                                    <View style={table}>
                                        <View style={thead}>
                                            <View style={row}>
                                                <Text style={cell}>{tl.section3.amount}</Text>
                                                <Text style={cell}>{tl.recycledContent}</Text>
                                            </View>
                                        </View>
                                        <View style={tbody}>
                                            <View style={rowClean}>
                                                <Text style={cell}>{material.input} g</Text>
                                                <Text style={cell}>{material.recycledContent} %</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            }
                            </View>
                        </View>
                    </View>
                </View>
            );
        })
    );
}

const pdfStyles = StyleSheet.create({
    page: {
        width: '100%',
        fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        padding: '10mm 20mm',
        paddingBottom: 2,
    },
    section: {
        width: '100%',
        marginBottom: 20,
    },
    headline: {
        fontSize: 14,
        marginBottom: 10,
    },
    text: {
        l: {
            fontSize: 12,
        },
    },
    table: {
        table: {
            width: '100%',
        },
        thead: {
            width: '100%',
            backgroundColor: '#eee',
        },
        tbody: {
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: '1px solid grey',
        },
        rowClean: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        cell: {
            width: '100%',
            fontSize: 10,
            textAlign: 'left',
            padding: 4,
        },
        cellSmall: {
            width: '30%',
            flexShrink: 0,
            fontSize: 10,
            textAlign: 'left',
            padding: 4,
        },
    },
  });

export default PDFPage;