import Store from '../Store'
let existingStores = [];

function getInventoryByStorage(storage) {
    if (!existingStores[storage]) {
        existingStores[storage] = new Store('markets/storages/' + storage + '/inventories');
    }
    return existingStores[storage];
}

export {getInventoryByStorage};