/* eslint-disable no-undef */
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {observable} from 'mobx';
import moment from 'moment';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Container, Button, Heading, Modal, Form} from 'react-bulma-components';
import {toast} from 'react-toastify';
import styles from "../styles";
import translation from '../translations';
import SupplierItemsStore from 'stores/Supplier/SupplierItemsStore';
const {Input} = Form;

@observer
class SupplierList extends Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.parentstate = props.parent.localstate;
        this.reset = props.parent.reset.bind(this);
    }

    @observable accessor localstate = {
        search: '',
    };

    async selectItem(item) {
        const {adminAccess} = this.parentstate;
        
        if (adminAccess && (this.parentstate.form.supplierId !== item?.id || SupplierItemsStore?.list?.length === 0)) {
            try {
                this.parentstate.loading = true;
                await SupplierItemsStore.fetchByUserId(item?.userId);
            } catch (error) {
                console.error(error);
                this.parentstate.loading = false;
                toast.error(`Error fetching supplier items: ${error.error?.message || error.error || error.message || JSON.stringify(error) || 'Error fetching data'}`);
            }
        }
        
        this.parentstate.selected.supplier = item;
        this.parentstate.selected.questionnaire = null;
        this.parentstate.form.supplierId = item.id;
        this.parentstate.submitted = false;
        this.parentstate.loading = false;
        this.onClose();
    }

    filterList(item) {
        const search = this.localstate.search.toLowerCase();
        const {vendorNr, name, createdAt, updatedAt} = item;
        if (!search) return true;
        
        if (
            (vendorNr && vendorNr.toLowerCase().includes(search)) ||
            (name && name.toLowerCase().includes(search)) ||
            (createdAt && createdAt.toLowerCase().includes(search)) ||
            (updatedAt && updatedAt.toLowerCase().includes(search))
        ) return true;

        return false;
    }

    sortList(a, b) {
        const key = 'vendorNr';
        const prev = Number(a[key]);
        const next = Number(b[key]);
        return prev === next ? 0 : prev > next ? 1 : -1;
    }

    onClose() {
        this.parentstate.modal.supplierList = false;
    }

    render() {
        const {search} = this.localstate;
        const {loading, language, adminAccess, modal} = this.parentstate;
        const tl = translation[language.value];

        return (
            <Modal show={modal.supplierList} onClose={() => this.onClose()}>
                <Container style={styles.modalContainer}>
                    <Heading style={{ textAlign: 'center', margin: '30px 0' }}>{tl.supplierList.headline}</Heading>
                    <div style={{ ...styles.note, textAlign: 'center' }}>{tl.supplierList.headNote}</div>
                    <Input
                        onChange={(e) => this.localstate.search = e.target.value}
                        value={search}
                        placeholder={tl.search}
                    />
                    <Table aria-label='simple table' stickyHeader style={{marginTop: '15px'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{tl.vendorNr}</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>{tl.createdAt}</TableCell>
                                <TableCell>{tl.updatedAt}</TableCell>
                                <TableCell></TableCell>
                                { adminAccess && <TableCell></TableCell> }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        this.store &&
                            this.store.list
                                .filter(this.filterList.bind(this))
                                .sort(this.sortList.bind(this))
                                .map((item, i) => {
                                    const {selected} = this.parentstate;
                                    const {vendorNr, name, createdAt, updatedAt} = item;

                                    return (
                                        <TableRow
                                            key={i}
                                            style={{
                                                ...(item.id === selected.supplier?.id && {
                                                    backgroundColor: 'rgba(0, 209, 178, 0.2)',
                                                    border: '1px solid #00d1b2',
                                                })
                                            }}
                                        >
                                            <TableCell>{vendorNr}</TableCell>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>{moment(createdAt).format(tl.dateFormat)}</TableCell>
                                            <TableCell>{moment(updatedAt).format(tl.dateFormat)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    color='primary'
                                                    loading={loading}
                                                    onClick={() => this.selectItem(item)}
                                                >
                                                    {tl.select}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                </Container>
            </Modal>
        );
    }
}

export default SupplierList;