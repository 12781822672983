import {observer} from "mobx-react";
import React, {Component} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Checkbox} from "@material-ui/core";
import {Container, Columns, Modal, Heading, Form} from 'react-bulma-components';
import {toast} from "react-toastify";
import styles from "../styles";
import translation from "../translations";
const {Input} = Form;

const codes = {
    head: [
        { name: '', width: 0 },
        { name: 'Recycling code', width: 10 },
        { name: 'Material designation', width: 45 },
        { name: 'Material', width: 45 },
    ],
    left: [
        {
            name: 'Plastic',
            data: [
                { code: '01 PET', name: 'PET or PETE (Polyethylene terephthalate)' },
                { code: '02 PE-HD', name: 'PE-HD or HDPE (Polyethylene High-Density)' },
                { code: '03 PVC', name: 'PVC (Polyvinyl chloride)' },
                { code: '04 PE-LD', name: 'PE-LD or LDPE (Polyethylene Low-Density)' },
                { code: '05 PP', name: 'PP (Polypropylene)' },
                { code: '06 PS', name: 'PS (Polystyrene)' },
                { code: '07 O', name: 'Other plastics such as polycarbonate (PC), polyamide (PA), polymethyl methacrylate (PMMA), polylactide (PLA), etc.' },
            ],
        },
        {
            name: 'Paper and cardboard',
            data: [
                { code: '20 PAP', name: 'Corrugated cardboard' },
                { code: '21 PAP', name: 'Other cardboard' },
                { code: '22 PAP', name: 'Paper' },
            ]
        },
        {
            name: 'Metals',
            data: [
                { code: '40 FE', name: 'Iron/steel' },
                { code: '41 ALU', name: 'Aluminum' },
            ]
        },
    ],
    right: [
        {
            name: 'Glass',
            data: [
                { code: '70 GL', name: 'Colourless glass' },
                { code: '71 GL', name: 'Green glass' }, 
                { code: '72 GL', name: 'Brown glass' },
            ],
        },
        {
            name: 'Composite materials',
            data: [
                { code: '80 C/*', name: 'Paper and cardboard/miscellaneous metals' },
                { code: '81 C/*', name: 'Paper and cardboard/plastic' },
                { code: '82 C/*', name: 'Paper and cardboard/aluminum' },
                { code: '83 C/*', name: 'Paper and cardboard/tinplate' },
                { code: '84 C/*', name: 'Paper and cardboard/plastic/aluminum' },
                { code: '85 C/*', name: 'Paper and cardboard/plastic/aluminum/tinplate' },
                { code: '87 C/*', name: 'Biodegradable plastic' },
                { code: '90 C/*', name: 'Plastic/aluminum' },
                { code: '91 C/*', name: 'Plastic/tinplate' },
                { code: '92 C/*', name: 'Plastic/various metals' },
                { code: '95 C/*', name: 'Glass/plastic' },
                { code: '96 C/*', name: 'Glass/aluminum' },
                { code: '97 C/*', name: 'Glass/tinplate' },
                { code: '98 C/*', name: 'Glass/various metals' },
            ],
        },
    ],
}

@observer
class RecyclingCodes extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
    }

    onModalClose() {
        const {form, language, modal} = this.localstate;
        const {packType} = form.recyclingCodes;
        const tl = translation[language.value];

        for (const item of form.recyclingCodes[packType]) {
            if (!item.input) {
                toast.error(tl.section5.errorMsg);
                return;
            }
        }

        modal.recyclingCodes = false;
    }

    render() {
        const {form, language, modal} = this.localstate;
        const {packType} = form.recyclingCodes;
        const tl = translation[language.value];

        return (
            <Modal show={modal.recyclingCodes} onClose={() => this.onModalClose()}>
                <Container style={styles.modalContainer}>
                    <Heading style={{ textAlign: 'center', marginBottom: 0 }}>Recycling Codes - {tl[packType]}</Heading>
                    <div style={{ ...styles.note, textAlign: 'center' }}>{tl.section5.modalNote}</div>
                    <Columns>
                        <Columns.Column>
                            <ModalSide
                                side='left'
                                localstate={this.localstate}
                                styles={styles}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <ModalSide
                                side='right'
                                localstate={this.localstate}
                                styles={styles}
                            />
                        </Columns.Column>
                    </Columns>
                </Container>
            </Modal>
        );
    }
}


@observer
class ModalSide extends Component {
    constructor(props) {
        super(props);
        this.packType = props.packType;
        this.localstate = props.localstate;
        this.styles = props.styles;
        this.side = props.side;
    }

    handleCheckbox(item, checked) {
        const {form} = this.localstate;
        const {packType} = form.recyclingCodes;
        const foundIndex = form.recyclingCodes[packType].findIndex(y => y.code === item.code);

        if (checked && foundIndex < 0) {
            form.recyclingCodes[packType].push({
                code: item.code,
                input: ''
            });
        } else if (foundIndex >= 0) {
            form.recyclingCodes[packType].splice(foundIndex, 1);
        }
    }

    render() {
        const {loading, form} = this.localstate;
        const {packType} = form.recyclingCodes;
        const styles = this.styles;

        return (
            codes[this.side].map((table, i) => {
                return (
                    <div key={i}>
                        <h2 style={styles.table.headline}>{table.name}</h2>
                        <Table size="small" style={styles.table.style}>
                            <TableHead>
                                <TableRow>
                                    { codes.head.map((x, j) => <TableCell key={j} style={{ width: `${x.width}%` }}>{x.name}</TableCell>) }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            table.name === 'Composite materials' && 
                                <TableRow>
                                    <TableCell colSpan={4} style={{ fontStyle: 'italic', color: 'blue' }}>* Select the recycling code of the main ingredient of the material</TableCell>
                                </TableRow>
                            }
                            {
                                table.data.map((item, j) => {
                                    const checkedItem = form.recyclingCodes[packType]?.find(y => y.code === item.code);

                                    return (
                                        <TableRow key={j}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disabled={loading}
                                                    checked={checkedItem !== undefined}
                                                    onChange={e => this.handleCheckbox(item, e.target.checked)}
                                                />
                                            </TableCell>
                                            {
                                                Object.keys(item).map((x, k) => <TableCell key={k}>{item[x]}</TableCell>)
                                            }
                                            <TableCell>
                                            {
                                                <Input
                                                    type='text'
                                                    placeholder='Material'
                                                    disabled={loading || !checkedItem}
                                                    value={checkedItem?.input || ''}
                                                    onChange={e => {
                                                        if (checkedItem) checkedItem.input = e.target.value;
                                                    }}
                                                />
                                            }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            </TableBody>
                        </Table>
                    </div>
                );
            })
        );
    }
}

export default RecyclingCodes;