import React, {Component} from 'react';
import {Box, Button, Heading } from 'react-bulma-components'
import {observer} from 'mobx-react';
import {
 Link
} from "react-router-dom";
import {PageStructureNotLoggedIn} from "../../dumb";

@observer
class NotFound extends Component {
    render() {
        const {loggedIn} = this.props;
        return (
           <PageStructureNotLoggedIn>
                                <Heading>Seite nicht gefunden / 404</Heading>
                                <Box>
                                    {loggedIn ?
                                        <Link to='/' ><Button color='primary'>Zurück zur Startseite</Button></Link> :
                                        <Link to='/login' ><Button color='primary'>Zurück zum Login</Button></Link>}
                                </Box>
           </PageStructureNotLoggedIn>);
    }
}

export default NotFound;