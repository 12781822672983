import Store from '../Store';
import RestClient from 'shared/Network/RestClient';
import {toast} from 'react-toastify';

class B2BStoreClass extends Store {
    fetchData() {
        return new Promise(async (resolve, reject) => {
            if (this.status.fetched) {
                return resolve(this.list);
            }

            try {
                this.status.loading = true;

                const customers = await this.fetchRoute('b2b/customers');
                const shippingAgents = await this.fetchRoute('b2b/shippingagents');
                const documentSourceTypes = await this.fetchRoute('b2b/documentsourcetypes');
                const paymentMethods = await this.fetchRoute('b2b/paymentmethods');
                const locations = await this.fetchRoute('b2b/locations');

                this.status.fetched = true;

                this.list = {
                    customers,
                    shippingAgents,
                    documentSourceTypes,
                    paymentMethods,
                    locations,
                }

                this.status.loading = false;
                resolve(this.list);
            } catch (error) {
                this.status.error = error.error?.message || error.data?.error || error.error || error.message || JSON.stringify(error) || 'An error occurred';
                this.status.loading = false;
                reject(error);
            }
        });
    }

    async fetchRoute(route = '') {
        const request = RestClient.prepareRequest('GET', route);

        try {
            const res = await request.send();

            if (res.success && 'data' in res) {
                return res.data?.value || res.data;
            } else {
                throw res.error?.message || res.data?.error || res.error || res.message || JSON.stringify(res) || 'An error occurred';
            }
        } catch (error) {
            toast.error(`Error fetching "${route}": ${error}`);
            return [];
        }
    }
}

const B2BStore = new B2BStoreClass();

export default B2BStore;