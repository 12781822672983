import React from "react";

function IconWithText ({icon, text}) {
    return (<div style={styles.container}>
        <div style={styles.icon}>{icon}</div>
        <div style={styles.text}>{text}</div>
    </div>);
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        paddingRight: 10,
        alignItems: 'center',
        display: 'flex',

    },
    text: {
        display: 'flex',

        alignItems: 'center'
    }
};

export {IconWithText};