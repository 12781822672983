import React, {Component} from 'react';
import {Heading, Container, Form, Loader} from 'react-bulma-components';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import RestClient from "../../../shared/Network/RestClient";
const {Input} = Form;

@observer
class ReplacementOrderScreen extends Component {
    @observable accessor localstate = {       
        orderNr: "",
        loading: false,
        result: ""
    };

    updateState(k, v) {
        this.localstate[k] = v;
    }

    checkIfEnter (keyCode) {
        if(keyCode === 13) {
            this.localstate.loading = true;
            this.localstate.result = "";
            this.checkForReplacementOrder(this.localstate.orderNr);
        }
    }

    async checkForReplacementOrder (orderNr) {
        try {
            if(!orderNr) {
                throw "No ordernumber given";
            }
            const request = RestClient.prepareRequest('GET', 'replacementOrders/'+orderNr);
            const response = await RestClient.sendRequest(request);
            let result = "No replacement order found!";
            if(response.success) {
                if (response.data.data?.length > 0) {
                    if (response.data.data[0]?.customFields?.koro_replacement_orders) {
                        result = response.data.data[0].customFields.koro_replacement_orders;
                    }
    
                    //sort transactions
                    let transactions = response.data.data[0]?.transactions;
                    transactions.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
    
                    //if latest transaction paymentmethod is Ersatzlieferung its an replacement order
                    if (response.data.data[0]?.transactions[0]?.paymentMethodId === "74f7ba35585843ae803f9da6ff2345ce") {
                        result = "This is a replacement order!";
                    }
                } 
            } else {
                result = "ERROR";
            }
            this.localstate.result = result;
            this.localstate.loading = false;
            this.localstate.orderNr = "";
        } catch (e) {
            console.log(e);
            this.localstate.loading = false;
            this.localstate.orderNr = "";
        }
    }

    render() {
        const loading = this.localstate.status;
        const orderNr = this.localstate.orderNr;
        const result = this.localstate.result;
        return (
            <Container style={styles.container}>
                <Heading>Check for replacement orders</Heading>
                <Input
                    onChange={(e) => this.updateState('orderNr', e.target.value)}
                    onKeyDown={(e) => this.checkIfEnter(e.keyCode)}
                    value={orderNr}
                    disabled={loading}
                    placeholder= "Shop order number or KOR number"
                    style={styles.input}
                    autoFocus
                />
                {this.localstate.loading ? 
                <div style={styles.loaderContainer}>
                    <Loader style={styles.loader} />
                </div>
                :
                <div style={styles.resultContainer}><span style={result.includes("replacement") ? {color: "red"}:{color: "green"}}>{result}</span></div>
                }
            </Container>
       )
    }
}

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px'
    },
    input: {
        textAlign: 'center'
    },
    loader: {
        width: 150,
        height: 150
    },
    loaderContainer: {
        padding: '6em',
        display: 'flex',
        justifyContent: 'center'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '2rem',
        marginTop: '5%'
    }
}


export default ReplacementOrderScreen;