import { observable, reaction } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Heading } from "react-bulma-components";
import { FaPause, FaPlay } from "react-icons/fa";

@observer
class Countdown extends Component {
  @observable accessor time = {};
  @observable accessor localstate = {
    timer: null,
    seconds: 0,
    started: false,
    totalTime: 0,
  };

  componentDidMount() {
    this.time = this.secondsToTime(this.props.totalTime || 0);

    reaction(
      () => this.props.started,
      (started) => {
        if (started) {
          this.localstate.seconds = this.props.secondsLeft;
          this.time = this.secondsToTime(
            this.props.secondsLeft > 0 ? this.props.secondsLeft : 0
          );

          this.props.secondsLeft > 0 && this.startTimer();
        }
      }
    );
  }

  secondsToTime(secs) {
    console.log(secs);
    const divForMinutes = secs % (60);
    const minutes = Math.floor(secs / 60);

    const divForSeconds = divForMinutes % 60;
    const seconds = Math.ceil(divForSeconds);

    return {
      m: minutes.toString(),
      s: seconds.toString(),
    };
  }

  startTimer() {
    if (!this.localstate.timer && this.localstate.seconds > 0) {
      this.localstate.timer = setInterval(this.countdown.bind(this), 1000);
      this.localstate.started = true;
    }
  }

  countdown() {
    const seconds = this.localstate.seconds - 1;
    this.time = this.secondsToTime(seconds);
    this.localstate.seconds = seconds;

    if (seconds === 0) {
      clearInterval(this.localstate.timer);
    }
  }

  render() {
    return (
      <div className="countdown" style={styles.countdown}>
        {this.localstate.started ? (
          <FaPlay size={50} style={styles.icon} />
        ) : (
          <FaPause size={50} style={styles.icon} />
        )}
        <Heading style={styles.time}>{`${this.time.m?.padStart(
          2,
          "0"
        )}:${this.time.s?.padStart(2, "0")}`}</Heading>
      </div>
    );
  }
}

const styles = {
  countdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2rem",
  },
  icon: {
    marginRight: "1rem",
  },
  time: {
    fontSize: "3.25rem",
  },
};

export default Countdown;
