import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { toast } from "react-toastify";
import RestClient from "shared/Network/RestClient";
import { Heading } from "react-bulma-components";
import "./index.css";
import ChallengeBody from "./ChallengeBody";
import Timer from "./Timer";
import FileDownload from "../../../../shared/FileDownload";
import logo from "../../../../assets/images/logo--tablet.png"

@observer
class Challenge extends Component {
  @observable accessor localstate = {
    loaded: false,
    started: false,
    invite: {},
    files: [],
    status: "pending",
    timeRemaining: 0,
    comment: "",
  };

  get token() {
    return this.props.match.params.token;
  }

  componentDidMount() {
    this.fetchChallenge();
  }

  async fetchChallenge() {
    const request = RestClient.prepareRequest("GET", "invites/" + this.token);

    try {
      const { data } = await RestClient.sendRequest(request);

      if (!data) {
        this.localstate.status = "not available";
        this.loaded = true;
        return;
      }
      if (data.finished) {
        this.localstate.status = "finished";
        this.loaded = true;
        return;
      }

      // If Challenge has been started
      if (!!data.startedAt) {
        // Calculate remaining time
        this.localstate.timeRemaining =
          (data.startedAt + data.timeLimit - Date.now()) / 1000;
      } else {
        this.localstate.timeRemaining = data.timeLimit / 1000;
      }

      this.localstate.invite = data;
      this.localstate.loaded = true;
      if (!!data.startedAt) {
        // Start the timer
        this.localstate.started = true;
      }
    } catch (error) {
      toast.error(
        "Etwas ist beim Laden deiner Challenge schiefgelaufen. Bitte versuche es erneut."
      );
    }
  }

  async downloadFilesAndStartTimer() {
    const request = RestClient.prepareRequest(
      "GET",
      "challenge-files/download/" + this.localstate.invite.id
    );

    try {
      const res = await RestClient.sendRequest(request, {
        responseType: "arraybuffer",
        getResponseObject: true,
      });
      this.localstate.started = true;

      const filename =
        res.headers["content-type"].match(/filename=([\s\S]+)/)[1];
      FileDownload.downloadFile(new File([res.data], filename));
    } catch (error) {
      console.log(error);
      toast.error(
        "Etwas ist beim Herunterladen deiner Challenge-Dateien schiefgelaufen. Bitte versuche es erneut."
      );
    }
  }

  async handleFileChange(e) {
    const newFiles = e.target.files || [];

    this.localstate.files = [...this.localstate.files, ...newFiles];

    e.target.value = null;
  }

  async removeFile(fileToDelete) {
    this.localstate.files = this.localstate.files.filter(
      (file) => file.name !== fileToDelete.name
    );
  }

  async finishChallenge() {
    if (
      !window.confirm(
        "Bist du sicher, dass du die Challenge offiziell abschließen möchtest?"
      )
    )
      return;

    const request = RestClient.prepareRequest(
      "POST",
      "challenge-files/upload-results/" + this.localstate.invite.id
    );

    const data = new FormData();
    for (const file of this.localstate.files) {
      data.append("files", file);
    }
    data.append("comment", this.localstate.comment);
    request.setData(data);

    try {
      const success = await RestClient.sendRequest(request);

      if (!success) {
        throw new Error();
      }

      this.localstate.status = "finished";
    } catch (error) {
      toast.error(
        "Etwas ist beim hochladen deiner Dateien schiefgelaufen, bitte versuche es erneut."
      );
    }
  }

  render() {
    return (
      <div className="challenge">
        <div className="challenge__header">
          <div className="challenge__header-content">
            <div className="challenge__logo-wrapper">
              <img
                src={logo}
                alt="KoRo Logo"
              />
            </div>
            <Heading className="challenge__title">
              Deine persönliche KoRo-Challenge
            </Heading>
            <Heading className="challenge__position">
              {this.localstate.invite.title}
            </Heading>
          </div>
        </div>
        {this.localstate.loaded ? (
          this.localstate.status === "finished" ? (
            <Heading style={styles.finishedText}>
              Deine Lösung wurde erfolgreich übermittelt. Vielen Dank für deine
              Teilnahme!
            </Heading>
          ) : (
            <ChallengeBody description={this.localstate.invite.description}>
              <Timer
                onDownloadClick={() => this.downloadFilesAndStartTimer()}
                onFileChange={(e) => this.handleFileChange(e)}
                onRemoveFile={(file) => this.removeFile(file)}
                onCommentChange={(value) => (this.localstate.comment = value)}
                onFinishChallenge={() => this.finishChallenge()}
                started={this.localstate.started}
                timeRemaining={this.localstate.timeRemaining}
                files={this.localstate.files}
                totalTime={this.localstate.invite.timeLimit / 1000}
                comment={this.localstate.comment}
              />
            </ChallengeBody>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

const styles = {
  finishedText: {
    textAlign: "center",
  },
};

export default Challenge;
