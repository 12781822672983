import Authentication from "../Authentication";
import axios from 'axios';
import config from '../../config/config';
import REST_STATES from './RestStates';

class RestClientClass {
    requests = [];

    prepareRequest(method, url, header) {
        return new Request(method, url, header);
    }

    async sendRequest(request, options) {
        try {
            const { responseType, getResponseObject } = {
                responseType: "json",
                getResponseObject: false,
                ...options,
            };

            this.requests.push(request);

            const response = await axios({
                method: request.method,
                url: config.API.ENDPOINT + request.url,
                headers: {
                    'Authorization': 'Bearer ' + Authentication.token,
                    'Content-Type': request.header
                },
                data: request.body,
                responseType
            });

            console.log({
                method: request.method,
                url: config.API.ENDPOINT + request.url,
                body: request.body,
                data: response.data,
                header: request.header
            });

            return getResponseObject ? response : response.data;
        } catch (e) {
            if (e.response && e.response.status === 403) {
                Authentication.logout();
            }
            throw(e);
        }

    }
};

const RestClient = new RestClientClass();

class Request {
    method = null;
    url = null;
    body = null;
    header = null;
    status = {
        status: REST_STATES.PREPARED,
        repeatOnFailure: true,
        error: null,
        trials: 0
    };

    constructor(method, url, header) {
        this.method = method;
        this.url = url;
        this.header = header
    }

    getStatus() {
       return this.status;
    }

    setRepeatOnFailure(bool) {
        this.status.repeatOnFailure = bool;
    }


    setData(body) {
        this.body = body;
    }

    async send() {
        return await RestClient.sendRequest(this);
    }
}

export default RestClient;