import React, { Component } from "react";
import Select from "react-select";

class TimeSelector extends Component {
  timeOptions = Array(50)
    .fill(0)
    .map((_, index) => ({
      label: `${(index + 1) * 5} Minuten`,
      value: (index + 1) * 5,
    }));

  render() {
    return (
      <Select
        options={this.timeOptions}
        placeholder="Timer"
        onChange={(e) => this.props.onTimeSelect(e)}
        value={this.props.time}
      />
    );
  }
}

export default TimeSelector;
