import ChallengeCardList from "components/dumb/ChallengeCardList";
import FetchView from "components/dumb/FetchView/FetchView";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { ChallengesStore } from "stores/HRChallenges/ChallengesStore";
import {
  Heading,
  Container,

} from "react-bulma-components";
import {BackButton} from "components/dumb/";
import Navigation from "shared/Navigation";

@observer
class ManageChallenges extends Component {
  openChallenge({ id }) {
    Navigation.history.push("/hrchallenges/challenge/" + id);
  }

  render() {
    return (
      <FetchView store={ChallengesStore}>
        <Container>
          <BackButton text="Zurück zum Dashboard" to="/hrchallenges" />
          <Heading>Wähle eine Challenge aus, um sie zu bearbeiten.</Heading>
          <ChallengeCardList
            challenges={ChallengesStore.list}
            onChallengeClick={(challenge) => this.openChallenge(challenge)}
          />
        </Container>
      </FetchView>
    );
  }
}

export default ManageChallenges;
