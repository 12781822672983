import React from "react";
import {Loader} from 'react-bulma-components';

class UploadButton extends React.Component {
    render() {
        const {name, onChange, loading, label} = this.props;
        return (
            <div>
                <label htmlFor={name} >
                    {loading ? <div><Loader/></div> :
                        <div style={styles.uploadButton}>{label}</div>}
                </label>
                <input
                    style={{display: "none"}}
                    type="file"
                    id={name}
                    multiple={true}
                    name={name}
                    onChange={onChange}
                />
            </div>);
    }
}

const styles = {
    uploadButton: {
        padding: 8,
        cursor:'pointer',
        border: '1px solid grey',
        borderRadius: 5
    }
};

export {UploadButton};