import Store from '../Store';
import RestClient from '../../shared/Network/RestClient';

const localUserData = localStorage.getItem('userData') || '';
const userData = localUserData ? JSON.parse(localUserData) : {};
const userGroups = userData?.user?.usergroups ? userData.user.usergroups.map(x => x.group) : [];
const adminAccess = userData?.user?.isAdmin || userGroups.includes('Sourcing') || false;
const supplierId = userData?.user?.DynamicsSupplier?.id || '';

class PackagingQuestionnaireStoreClass extends Store {
    constructor() {
        super();
        this.url =
            !adminAccess
                ? `supplier/packagingquestionnairesbysupplier/${supplierId}`
                : 'supplier/packagingquestionnaires';
    }

    setUrl(url = this.url) {
        this.url = url;
    }

    async getFiles(id) {
        if (!id) return [];

        const request = RestClient.prepareRequest('GET', `supplier/packagingquestionnaire/${id}/files`);

        try {
            const res = await RestClient.sendRequest(request);
            if (!res?.success) return [];
            return res.data;
        } catch (error) {
            return [];
        }
    }

    async uploadFiles(id, files) {
        if (!id || !files) return { error: 'Error uploading file: Missing id or files' };

        const formData = new FormData();
        for (const file of files) formData.append('files', file);

        const request = RestClient.prepareRequest('POST', `supplier/packagingquestionnaire/${id}/files`);
        request.setData(formData);

        try {
            const res = await RestClient.sendRequest(request);
            return res;
        } catch (error) {
            return error;
        }
    }

    async downloadFile(file) {
        if (!file) return { error: 'Error downloading file: Missing file' };

        const request = RestClient.prepareRequest('GET', `supplier/packagingquestionnaire/download-file/${file?.id}`);

        try {
            const res = await RestClient.sendRequest(request, {
                responseType: 'arraybuffer',
                getResponseObject: true,
            });
            
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async deleteFile(file) {
        if (!file) return { error: 'Error deleting file: Missing file' };

        const request = RestClient.prepareRequest('DELETE', `supplier/packagingquestionnaire/file/${file?.id}`);

        try {
            const res = await RestClient.sendRequest(request);
            return res;
        } catch (error) {
            return error;
        }
    }
}

const PackagingQuestionnaireStore = new PackagingQuestionnaireStoreClass();

export default PackagingQuestionnaireStore;