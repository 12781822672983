import {observable} from 'mobx';
import axios from 'axios';
import {toast} from 'react-toastify';
import config from '../config/config';

class Authentication {
    @observable accessor localstatus = {
        loading: false,
        error: false,
        loggedIn: false,
    };

    @observable accessor user = {
        firstName: null,
        lastName: null,
        canAddUsers: null,
        email: null,
    };

    token = null;

    constructor() {
       this.init();
    }

    async init() {
        const storage = localStorage.getItem('userData');
        const userData = storage ? JSON.parse(storage) : {};

        if (storage) {
            this.setUserData(userData);
            await this.refreshUserData();
        }

        this.logout = this.logout.bind(this);
    }

    async refreshUserData() {
        try {
            const user = await this.request('GET', 'me');
            this.setUserData({ token: this.token, user });
        } catch (error) {
            toast.error(error);
        }
    }

    setUserData(userData = {}) {
        this.token = userData?.token || null;
        localStorage.setItem('userData', JSON.stringify(userData));
        this.user = userData.user;
        this.localstatus.loggedIn = true;
    }

    hasAccess(routeItem) {
        if (this.user.isAdmin) {
            return true;
        }
        if (routeItem) {
            if(routeItem.group.includes('General')) {
                return true
            }
            for (const group of routeItem.group) {
                if (this.user.usergroups && (this.user.usergroups.find(x => x.group === group))) {
                    return true;
                }
            }

            return false;
        } else {
            return true;
        }
    }

    async login(email, password) {
        try {
            this.localstatus.loading = true;

            const userData = await this.request('POST', 'auth/login', { email, password });
            this.setUserData(userData);
            
            // Reload required, because user-dependent features will not be updated (probably because the store is not updating)
            // ToDo: Fix the authentification und login logic

            window.location.reload();

            // Required if the page does not reload
            return {
                success: true,
                data: userData,
            }
        } catch (error) {
            this.localstatus.loading = false;
            this.localstatus.error = {
                success: false,
                message: error,
            }
            return this.localstatus.error;
        }
    }

    isLoggedIn() {
        return this.localstatus.loggedIn;
    }

    logout() {
        localStorage.removeItem('userData');
        this.localstatus.loggedIn = false;
    }

    request(method = 'GET', url = '', body = null, token = this.token) {
        return new Promise(async (resolve, reject) => {
            try {
                this.localstatus.loading = true;

                const res = await axios({
                    method,
                    url: config.API.ENDPOINT + url,
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                    ...({ data: body }),
                });

                console.log({
                    method,
                    url: config.API.ENDPOINT + url,
                    data: body,
                });

                if (res.data?.success) {
                    resolve(res?.data?.data || res?.data || res);
                } else {
                    throw res?.data?.error || res?.data?.error?.message || res?.data?.message || JSON.stringify(res) || 'An error occurred';
                }

                this.localstatus.loading = false;
            } catch (error) {
                this.localstatus.loading = false;
                reject(error?.message || error);
            }
        });
    }
}

export default new Authentication();