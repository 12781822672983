import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {Switch} from '@material-ui/core';
import {Columns, Section, Form} from 'react-bulma-components';
import styles from '../styles';
import translation from '../translations';
const {Input, Label} = Form;

@observer
class Section4 extends Component {
    constructor(props) {
        super(props);
        this.localstate = props.parent.localstate;
        this.handleInput = props.parent.handleInput.bind(this);
        this.error = props.parent.error.bind(this);
    }

    render() {
        const {loading, form, language} = this.localstate;
        const tl = translation[language.value];

        return (
            <Section style={styles.section}>
                <h1 style={styles.headline}>4. {tl.section4.headline}</h1>
                <div style={styles.switchBox}>
                    <h2 style={styles.switchBoxText}>{tl.section4.checkboxText}:</h2>
                    <Switch
                        disabled={loading}
                        checked={form.label.ordered}
                        onChange={e => form.label.ordered = e.target.checked}
                    />
                </div>
                {
                    !form.label.ordered &&
                        <Columns>
                            <Columns.Column>
                                <div style={styles.inputBox}>
                                    <Label>{tl.width} (in mm)</Label>
                                    <Input
                                        className={this.error(form.label.width)}
                                        type='number'
                                        placeholder='0.00'
                                        disabled={loading}
                                        value={form.label.width}
                                        onChange={e => this.handleInput(form.label, 'width', e)}
                                    />
                                </div>
                            </Columns.Column>
                            <Columns.Column>
                                <div style={styles.inputBox}>
                                    <Label>{tl.height} (in mm)</Label>
                                    <Input
                                        className={this.error(form.label.height)}
                                        type='number'
                                        placeholder='0.00'
                                        disabled={loading}
                                        value={form.label.height}
                                        onChange={e => this.handleInput(form.label, 'height', e)}
                                    />
                                </div>
                            </Columns.Column>
                            <Columns.Column>
                                <div style={styles.inputBox}>
                                    <Label>{tl.weight} (in g)</Label>
                                    <Input
                                        className={this.error(form.label.weight)}
                                        type='number'
                                        placeholder='0.00'
                                        disabled={loading}
                                        value={form.label.weight}
                                        onChange={e => this.handleInput(form.label, 'weight', e)}
                                    />
                                </div>
                            </Columns.Column>
                            <Columns.Column>
                                <div style={styles.inputBox}>
                                    <Label>{tl.material}</Label>
                                    <Input
                                        type='text'
                                        placeholder={tl.material}
                                        disabled={loading}
                                        value={form.label.material}
                                        onChange={e => this.handleInput(form.label, 'material', e)}
                                    />
                                </div>
                            </Columns.Column>
                        </Columns>
                }
            </Section>
        );
    }
}

export default Section4;