import { IconWithText } from "components/dumb";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FaDownload, FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import FileDownload from "shared/FileDownload";
import RestClient from "shared/Network/RestClient";

@observer
class FileUploadList extends Component {
  downloadFile(file) {
    if (file.id) {
      return this.downloadUploadedFile(file);
    }
    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);

    const el = document.createElement("a");
    el.setAttribute("download", file.name);
    el.setAttribute("href", url);
    el.click();

    URL.revokeObjectURL(url);
  }

  async downloadUploadedFile(file) {
    const request = RestClient.prepareRequest(
      "GET",
      "challenge-files/download-file/" + file.id
    );

    try {
      const data = await RestClient.sendRequest(request, {
        responseType: "arraybuffer",
      });
      FileDownload.downloadFile(new File([data], file.name));
    } catch (error) {
      console.log(error);
      toast.error(
        "Etwas ist beim Herunterladen deiner Challenge-Dateien schiefgelaufen. Bitte versuche es erneut."
      );
    }
  }

  render() {
    return (
      <div>
        {this.props.files.length ? (
          this.props.files.map((file, index) => (
            <div
              key={index}
              style={index === 0 ? styles.firstFile : styles.file}
            >
              <span>{file.name}</span>
              <FaDownload
                style={styles.icon}
                onClick={() => this.downloadFile(file)}
              />
              <FaTrash
                style={styles.icon}
                onClick={() => this.props.onFileRemove(file)}
              />
              <hr />
            </div>
          ))
        ) : (
          <p style={styles.placeholder}>Noch keine Datei hochgeladen</p>
        )}
        {this.props.files.length === 0 ? (
          <div>
            <label htmlFor="file-upload" className="button">
              <IconWithText icon={<FaPlus />} text="Datei hinzufügen" />
            </label>
            <input
              style={styles.fileInput}
              type="file"
              id="file-upload"
              onInput={(e) => this.props.onFileSelect(e)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const styles = {
  fileInput: {
    display: "none",
  },
  file: {
    marginBottom: "1.5rem",
  },
  firstFile: {
    paddingTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  icon: {
    marginLeft: "0.5rem",
    cursor: "pointer",
  },
  placeholder: {
    marginBottom: "1rem",
  },
};

export default FileUploadList;
